<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <template v-for="availablePlatform in platforms">
            <template v-for="platform in selectedEnvironments">
                <div v-if="platform === availablePlatform.value" class="col">
                    <component
                        :is="singleEnvironment ? 'slot' : 'p-fieldset'"
                        :legend="availablePlatform.label"
                    >
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label>{{ $t('labels.manufacturer') }} </label>
                                <p-dropdown
                                    :filter="true"
                                    v-model="v$['manufacturer' + '-' + platform].$model"
                                    :options="serverData[platform] ? serverData[platform]['manufacturer'] : []"
                                    optionLabel="name"
                                    optionValue="id"
                                    @change="setDropdownValue(('manufacturer' + '-' + platform), $event)"
                                    :class="{'p-invalid':v$['manufacturer' + '-' + platform].$invalid && submitted}"
                                    :showClear="true"
                                >
                                </p-dropdown>
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['manufacturer' + '-' + platform].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productHome.countryOfOrigin')}}</label
                                >

                                <p-dropdown
                                    :filter="true"
                                    v-model="v$['countryOfOrigin' + '-' + platform].$model"
                                    :options="countryOptions"
                                    optionLabel="label"
                                    optionValue="value"
                                    @change="setDropdownValue(('countryOfOrigin' + '-' + platform), $event)"
                                    :class="{'p-invalid':v$['countryOfOrigin' + '-' + platform].$invalid && submitted}"
                                    :showClear="true"
                                >
                                </p-dropdown>
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['countryOfOrigin' + '-' + platform].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productHome.customsTariffNumber')
                                    }}
                                </label>

                                <p-dropdown
                                    v-model="v$['customsTariffNumber' + '-' + platform].$model"
                                    :options="serverData[platform] ? serverData[platform]['customsTariffNumber'] : []"
                                    :filter="true"
                                    optionLabel="name"
                                    optionValue="id"
                                    @change="setDropdownValue(('customsTariffNumber' + '-' + platform), $event)"
                                    :class="{'p-invalid':v$['customsTariffNumber' + '-' + platform].$invalid && submitted}"
                                    :showClear="true"
                                >
                                </p-dropdown>
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['customsTariffNumber' + '-' + platform].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </div>
                        </div>

                        <div class="formgrid grid p-fluid">
                            <template
                                v-if="availablePlatform?.threeLetterId === 'ttd' || availablePlatform?.threeLetterId === 'ttl'"
                            >
                                <div class="col field">
                                    <label
                                        >{{ $t('labels.productAdd.matchCode') }}
                                    </label>
                                    <InputText
                                        autocomplete="off"
                                        v-model="v$['matchCode' + '-' + platform].$model"
                                        :class="{'p-invalid':v$['matchCode' + '-' + platform].$invalid && submitted}"
                                    />
                                    <small
                                        v-if="submitted"
                                        style="display: block"
                                        v-for="error in v$['matchCode' + '-' + platform].$silentErrors"
                                        class="p-error"
                                        :key="error"
                                    >
                                        {{ error.$message }}
                                    </small>
                                </div>
                            </template>
                            <template
                                v-if="availablePlatform?.threeLetterId === 'vdd' || availablePlatform?.threeLetterId === 'vdl'"
                            >
                                <div class="col field">
                                    <label>
                                        {{ $t('labels.productHome.metaTagsSEO')
                                        }}
                                    </label>
                                    <Chips
                                        v-model="v$['articleMetatags' + '-' + platform].$model"
                                        :allowDuplicate="false"
                                        :placeholder="$t('labels.metatagsSeparatorInfo')"
                                        separator=","
                                    />
                                </div>
                            </template>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label> {{ $t('labels.vkPrice') }} </label>

                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :maxFractionDigits="2"
                                    autocomplete="off"
                                    v-model="v$['vkPrice' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['vkPrice' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label>
                                    {{ $t('labels.productAdd.vkStartDate') }}
                                </label>

                                <p-calendar
                                    autocomplete="off"
                                    dateFormat="dd.mm.yy"
                                    v-model="v$['vkStartDate' + '-' + platform].$model"
                                    :class="{'tt-form-input': true, 'p-invalid':v$['vkStartDate' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label>
                                    {{
                                    $t('labels.productHome.procurementLeadDays')
                                    }}
                                </label>

                                <InputNumber
                                    :locale="locale"
                                    autocomplete="off"
                                    showButtons
                                    :min="0"
                                    v-model="v$['procurementLeadDays' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['procurementLeadDays' + '-' + platform].$invalid && submitted}"
                                />
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['procurementLeadDays' + '-' + platform].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label>
                                    {{ $t('labels.minimumStockQuantity') }}
                                </label>

                                <InputNumber
                                    :locale="locale"
                                    autocomplete="off"
                                    v-model="v$['minimumStockQuantity' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['minimumStockQuantity' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label>
                                    {{ $t('labels.targetStockQuantity') }}
                                </label>

                                <InputNumber
                                    :locale="locale"
                                    autocomplete="off"
                                    v-model="v$['targetStockQuantity' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['targetStockQuantity' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>

                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productHome.length') }}
                                </label>
                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :minFractionDigits="1"
                                    :maxFractionDigits="1"
                                    autocomplete="off"
                                    v-model="v$['articleLength' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['articleLength' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productHome.width') }}
                                </label>
                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :minFractionDigits="1"
                                    :maxFractionDigits="1"
                                    autocomplete="off"
                                    v-model="v$['articleWidth' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['articleWidth' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productHome.height') }}
                                </label>
                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :minFractionDigits="1"
                                    :maxFractionDigits="1"
                                    autocomplete="off"
                                    v-model="v$['articleHeight' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['articleHeight' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productHome.grossWeight') }}
                                </label>
                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :minFractionDigits="1"
                                    autocomplete="off"
                                    v-model="v$['articleGrossWeight' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['articleGrossWeight' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productHome.netWeight') }}
                                </label>
                                <InputNumber
                                    mode="decimal"
                                    :locale="locale"
                                    :minFractionDigits="1"
                                    autocomplete="off"
                                    v-model="v$['articleNetWeight' + '-' + platform].$model"
                                    :class="{'p-invalid':v$['articleNetWeight' + '-' + platform].$invalid && submitted}"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid mt-3">
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productHome.serialNumberExtern')
                                    }}
                                </label>
                                <p-dropdown
                                    v-if="availablePlatform?.threeLetterId === 'ttd' || availablePlatform?.threeLetterId === 'ttl'"
                                    v-model="v$['serialNumberRequired' + '-' + platform].$model"
                                    :options="[{id: true, name: 'ja'}, {id: false, name: 'nein'}]"
                                    optionLabel="name"
                                    optionValue="id"
                                    @change="setDropdownValue(('serialNumberRequired' + '-' + platform), $event)"
                                    :class="{'p-invalid':v$['serialNumberRequired' + '-' + platform].$invalid && submitted}"
                                    showClear
                                >
                                </p-dropdown>
                                <p-checkbox
                                    v-else
                                    v-model="v$['serialNumberRequired' + '-' + platform].$model"
                                    binary
                                    class="ml-2 mb-1"
                                />
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['serialNumberRequired' + '-' + platform].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </div>
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productHome.serialNumberIntern')
                                    }}
                                </label>
                                <p-checkbox
                                    v-model="v$['serialNumberIntern' + '-' + platform].$model"
                                    :binary="true"
                                    class="ml-2 mb-1"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid mt-3">
                            <div class="col field">
                                <label
                                    >{{ $t('labels.articleWeclappCategory') }}
                                </label>

                                <p-dropdown
                                    :filter="true"
                                    v-model="v$['articleCategory' + '-' + platform].$model"
                                    :options="serverData[platform] ? serverData[platform]['articleCategory'] : []"
                                    optionLabel="name"
                                    optionValue="id"
                                    @change="setDropdownValue(['articleCategory' + '-' + platform], $event)"
                                    :showClear="true"
                                >
                                </p-dropdown>
                            </div>
                        </div>
                    </component>
                </div>
            </template>
        </template>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" type="submit" v-text="$t('buttons.next')">
        </p-button>
    </div>
</form>
