<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <template v-if="state.supplySources.length < 1">
        <div class="flex justify-content-start">
            <p-button
                class="mb-4 text-sm"
                @click="addSupplySource"
                :label="$t('buttons.addNewGeneric')"
                icon="pi pi-plus text-sm"
                severity="success"
            >
            </p-button>
        </div>
    </template>
    <div class="grid font-bold">
        <div class="col">{{ $t('labels.productAdd.sku')}}</div>
        <div class="col">{{ $t('labels.name')}}</div>
        <div class="col">{{ $t('labels.productAdd.supplier') }}</div>
        <div class="col-2">{{ $t('labels.ekPrice') }}</div>
        <div class="col-2">{{ $t('labels.productAdd.ekStartDate') }}</div>
        <div class="col-1"></div>
    </div>

    <template v-for="(item, index) in state.supplySources" :key="index">
        <div class="grid p-fluid">
            <div class="col">
                <InputText
                    autocomplete="off"
                    v-model="item.articleNumber"
                    :class="{'p-invalid':submitted && v$.supplySources.$each.$response.$data[index].articleNumber.$invalid}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    class="p-error"
                    v-for="error in v$.supplySources.$each.$response.$errors[index].articleNumber"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col">
                <InputText
                    autocomplete="off"
                    v-model="item.name"
                    :class="{'p-invalid':submitted && v$.supplySources.$each.$response.$data[index].name.$invalid}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    class="p-error"
                    v-for="error in v$.supplySources.$each.$response.$errors[index].name"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col">
                <span v-if="item.id">{{item.supplier?.name}}</span>

                <template v-else>
                    <p-dropdown
                        v-model="item.supplier"
                        :options="(allSuppliers || [])"
                        optionLabel="label"
                        optionValue="value"
                        filter
                        showClear
                        :class="{'p-invalid':submitted && v$.supplySources.$each.$response.$data[index].supplier.$invalid}"
                    >
                    </p-dropdown>
                </template>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.supplySources.$each.$response.$errors[index].supplier"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col-2">
                <span v-if="item.id">{{item.ekPrice}}</span>
                <template v-else>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :min="0"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="item.ekPrice"
                        :class="{'p-invalid':submitted && v$.supplySources.$each.$response.$data[index].ekPrice.$invalid}"
                    />
                </template>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.supplySources.$each.$response.$errors[index].ekPrice"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col-2">
                <template v-if="!item.id">
                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        v-model="item.ekStartDate"
                        :class="{'p-invalid':submitted && v$.supplySources.$each.$response.$data[index].ekStartDate.$invalid}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.supplySources.$each.$response.$errors[index].ekStartDate"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </template>
            </div>
            <div class="col-1 flex align-items-center">
                <span
                    v-if="state.supplySources.length - 1 <= index"
                    class="pi pi-plus mr-2 float-right"
                    style="color: green; cursor: pointer"
                    @click="addSupplySource"
                ></span>
                <span
                    v-if="index >= 0"
                    class="pi pi-trash float-right"
                    style="color: red"
                    @click="removeSupplySource(index);"
                ></span>
            </div>
        </div>
    </template>

    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button
            :disabled="savingInProgress"
            severity="success"
            type="submit"
            v-text="savingInProgress ? $t('labels.inProgress') : $t('labels.save')"
        >
        </p-button>
    </div>
</form>
