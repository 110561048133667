<loading v-model:active="loading" />
<DataTable
    class="p-datatable-sm text-sm"
    stripedRows
    :rowClass="rowClass"
    paginator
    :rows="10"
    lazy
    :scrollable="scrollableHeight !== null"
    :scrollHeight="scrollableHeight"
    ref="dt"
    dataKey="id"
    :totalRecords="totalRecords"
    :value="products"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50,100,200]"
    current-page-report-template="{first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="row"
    @page="onPage($event)"
    @filter="onFilter($event)"
    @sort="onSort($event)"
    v-model:expandedRows="expandedRows"
    v-model:selection="selectedProducts"
    @rowSelect="onRowSelect"
    @rowSelectAll="onRowSelectAll"
    @rowUnselect="onRowUnselect"
    @rowUnselectAll="onRowUnselectAll"
>
    <Column
        v-if="selectionMode"
        :selectionMode="selectionMode"
        headerStyle="width: 3em"
    >
        <template #header>
            <Badge :value="mergedSelection.length"></Badge>
            <i
                v-if="mergedSelection.length > 0"
                class="pi pi-times-circle text-red-600"
                style="padding-left: 7px; cursor: pointer"
                @click="clearSelection"
            ></i></template
    ></Column>
    <Column
        field="articleNumber"
        :sortable="true"
        :header="$t('labels.articleNumber')"
        style="min-width: 12rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                placeholder="#"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                ><a
                    :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                    target="_blank"
                    >{{data.articleNumber}}</a
                ></span
            >
            <span
                v-if="data.shopwareData && data.articleNumber !== data.shopwareData.productNumber"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.articleNumber')"
            ></span>
        </template>
    </Column>
    <Column
        field="name"
        :sortable="true"
        style="min-width: 12rem"
        :header="$t('labels.name')"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.name')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.name || data.shopwareData?.name }}</span
            >
            <span
                v-if="data.shopwareData && data?.name !== data?.shopwareData?.name"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.name')"
            ></span>
            <div class="mt-2">
                <Tag v-for="tag in data.tags" severity="info" class="mt-1 mr-1">
                    <span
                        >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')}}</span
                    >
                </Tag>
            </div>
        </template>
    </Column>
    <Column
        field="description"
        style="min-width: 12rem"
        :header="$t('labels.productDescription.webshopText')"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.productDescription.webshopText')"
            />
        </template>
        <template #body="{data}">
            <div>
                {{ stripTagsAndTruncate(data.shopwareData?.description, 200,
                '...') }}
            </div>
        </template>
    </Column>
    <Column
        field="productCategory"
        :header="$t('labels.articleCategory')"
        style="min-width: 12rem"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-sm"
                :placeholder="$t('labels.articleCategory')"
            />
        </template>
        <template #body="{data}">
            <ul v-if="data?.shopwareData?.categories">
                <li v-for="element in data.shopwareData.categories">
                    {{ element.name }}
                </li>
            </ul>
        </template>
    </Column>
    <Column
        field="ean"
        :sortable="true"
        :header="$t('labels.ean')"
        style="min-width: 12rem"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.ean')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.ean || data.shopwareData?.ean }}</span
            >
        </template>
    </Column>
    <Column
        field="manufacturerPartNumber"
        :sortable="true"
        :header="$t('labels.mpn')"
        style="min-width: 12rem"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.mpn')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.manufacturerPartNumber || data.shopwareData?.mpn
                }}</span
            >
        </template>
    </Column>
    <Column
        field="manufacturerName"
        :sortable="true"
        :header="$t('labels.manufacturer')"
        style="min-width: 12rem"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.manufacturer')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.manufacturerName || data.shopwareData?.manufacturer
                }}</span
            >
            <span
                v-if="data.shopwareData && (data.manufacturerName || data.shopwareData.manufacturer) && (data.manufacturerName !== data.shopwareData.manufacturer)"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.manufacturer')"
            ></span>
        </template>
    </Column>
    <Column
        :header="$t('labels.stock')"
        field="availableStock"
        style="min-width: 8rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel, filterCallback}">
            <TriStateCheckbox
                v-model="filterModel.value"
                @change="filterCallback()"
            />
        </template>
        <template #body="{data}">
            {{ data.shopwareData?.availableStock }}
        </template>
    </Column>
    <Column
        field="tags"
        :header="$t('labels.tags')"
        style="min-width: 10rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <p-multiselect
                class="text-sm"
                v-model="filterModel.value"
                :options="availableProductTags"
                :maxSelectedLabels="3"
                optionLabel="name"
                optionValue="name"
                filter
                @change="filterCallback()"
                style="min-width: 5rem; max-width: 20rem"
            >
                <template #option="{option}">
                    {{option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')}}
                </template>
                <template #value="content">
                    {{(content.value || []).map((item) =>
                    item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                    .join(', ')}}
                </template>
            </p-multiselect>
        </template>
        <template #body="{data}">
            <ul v-if="data?.tags">
                <template v-for="tag in data.tags">
                    <li>
                        <span class="font-semibold"
                            >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')}}</span
                        >
                    </li>
                </template>
            </ul>
        </template>
    </Column>
</DataTable>
