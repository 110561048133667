import Dialog from 'primevue/dialog';
import {reactive, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {required, url} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {add} from '@/services/product-videos';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        productDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const productNumber = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = reactive({
            url: ''
        });

        const rules = {
            url: {required, url}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            resetForm();
            productNumber.value =
                args.productDetails.weclapp?.articleNumber ||
                args.productDetails.shopware?.productNumber;
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            add(productNumber.value, state.url)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const resetForm = () => {
            state.url = '';
            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress
        };
    }
};
