import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import TreeSelect from 'primevue/treeselect';
import InputSwitch from 'primevue/inputswitch';
import ProgressBar from 'primevue/progressbar';
import {FilterMatchMode} from 'primevue/api';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import {onMounted, onUnmounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {getPlatformCellContent, stripTagsAndTruncate} from '@/utils/helpers';
import ImportResultOverview from '@/components/dialog/import-wizard/logs/import-result-overview.vue';
import {DateTime} from 'luxon';
import {downloadFile, getAll} from '@/services/price-import';
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import router from '@/router';
import Tag from 'primevue/tag';
import FileSaver from 'file-saver';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {runPriceImportManually} from '@/services/import';
import {useConfirm} from 'primevue/useconfirm';

export default {
    name: 'ImportLogs',
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        TreeSelect,
        InputSwitch,
        ProgressBar,
        ImportResultOverview,
        loading: LoadingPlugin,
        Panel,
        Tag,
        TriStateCheckbox,
        'p-button': Button
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('price-log-list-state-session')) {
                return;
            }

            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'id',
                sortOrder: -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();

            interval.value = setInterval(() => {
                if (needsRefresh.value) {
                    loadLazyData();
                }
            }, 5000);
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const dt = ref();
        const loading = ref(false);
        const allEnvs = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const interval = ref(null);
        const needsRefresh = ref(false);
        const toast = useToast();

        const logs = ref();
        const confirm = useConfirm();

        const filterDefintions: any = {
            supplierName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            status: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            uploadedBy: {value: null, matchMode: FilterMatchMode.CONTAINS},
            isAutomatic: {value: null, matchMode: FilterMatchMode.EQUALS},
            lastRunBy: {value: null, matchMode: FilterMatchMode.CONTAINS}
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.allEnvs = allEnvs.value;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        importedAt: obj.insertedAtTimestamp
                            ? DateTime.fromSeconds(obj.insertedAtTimestamp)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        processingStartedAt: obj.processingStartedAtTimestamp
                            ? DateTime.fromSeconds(
                                  obj.processingStartedAtTimestamp
                              )
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        processingEndedAt: obj.processingEndedAtTimestamp
                            ? DateTime.fromSeconds(
                                  obj.processingEndedAtTimestamp
                              )
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        lastRunAt: obj.lastRunAtTimestamp
                            ? DateTime.fromSeconds(obj.lastRunAtTimestamp)
                                  .setLocale(i18n.global.locale)
                                  .setZone(
                                      process.env?.VUE_APP_DEFAULT_TIME_ZONE
                                  )
                                  .toLocaleString(
                                      DateTime.DATETIME_MED_WITH_SECONDS
                                  )
                            : null,
                        isAutomatic: obj.uploadedBy === 'SYSTEM'
                    }));
                    loading.value = false;
                    const inProgress = data.data.items.find((element: any) => {
                        return (
                            element.importInProgress ||
                            (element.processingStartedAtTimestamp &&
                                !element.processingEndedAtTimestamp)
                        );
                    });

                    needsRefresh.value = !!inProgress;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onAllEnvsSwitch = () => {
            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const navigateToDetails = (data: any) => {
            router.push({
                name: 'Price Import Log Details',
                params: {id: data.id}
            });
        };

        const navigateToSummary = (data: any) => {
            router.push({
                name: 'Price Import Log Summary',
                params: {id: data.id}
            });
        };

        const runManually = async (data: any) => {
            confirm.require({
                message: i18n.global.t('messages.runPriceImportManually', {
                    number:
                        data.statistics?.regularItemsCount -
                        data.statistics?.handledItemsCount
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        await runPriceImportManually(data.id);
                        toast.success(
                            i18n.global.t('messages.cronJobExecutionScheduled')
                        );
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    }
                }
            });
        };

        const downloadAttachment = async (
            importId: string,
            fileId: string,
            transformed: boolean = false
        ) => {
            loading.value = true;
            try {
                const file = await downloadFile(importId, fileId, transformed);
                const filename = file.headers['content-disposition']
                    .split('filename=')[1]
                    .split(';')[0];

                FileSaver.saveAs(
                    new Blob([file.data], {
                        type: file.headers['content-type']
                    }),
                    filename
                );
            } finally {
                loading.value = false;
            }
        };

        return {
            logs,
            dt,
            totalRecords,
            loading,
            allEnvs,
            lazyParams,
            filters,
            matchModesNumeric,
            onPage,
            onFilter,
            onSort,
            onAllEnvsSwitch,
            getPlatformCellContent: getPlatformCellContent,
            navigateToDetails,
            navigateToSummary,
            downloadAttachment,
            stripTagsAndTruncate: stripTagsAndTruncate,
            runManually
        };
    }
};
