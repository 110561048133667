<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <div v-for="platform in selectedEnvironments" class="col">
            <p-fieldset
                :legend="platforms.find((item) => item.value === platform).label"
            >
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>{{ $t('labels.productAdd.supplier') }} </label>

                        <p-dropdown
                            v-model="v$['supplier' + '-' + platform].$model"
                            :options="suppliers[platform]['supplier'] || []"
                            optionLabel="label"
                            optionValue="value"
                            :filter="true"
                            @change="setDropdownValue(('supplier' + '-' + platform), $event)"
                            :showClear="true"
                            :class="{'p-invalid':v$['supplier' + '-' + platform].$invalid && submitted}"
                        >
                        </p-dropdown>
                        <small
                            v-if="(v$['supplier' + '-' + platform].$invalid && submitted) || v$['supplier' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['supplier' + '-' +
                            platform].required.$message.replace('Value',
                            $t('labels.productAdd.supplier'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>{{ $t('labels.productAdd.sku') }} </label>

                        <InputText
                            :placeholder="$t('labels.productAdd.sku')"
                            autocomplete="off"
                            v-model="v$['sku' + '-' + platform].$model"
                            :class="{'p-invalid':v$['sku' + '-' + platform].$invalid && submitted}"
                        />
                        <small
                            v-if="(v$['sku' + '-' + platform].$invalid && submitted) || v$['sku' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['sku' + '-' +
                            platform].required.$message.replace('Value',
                            $t('labels.productAdd.sku'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label> {{ $t('labels.ekPrice') }} </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :min="0"
                            :maxFractionDigits="2"
                            autocomplete="off"
                            v-model="v$['ekPrice' + '-' + platform].$model"
                            :class="{'p-invalid':v$['ekPrice' + '-' + platform].$invalid && submitted}"
                        />
                        <small
                            v-if="(v$['ekPrice' + '-' + platform].$invalid && submitted) || v$['ekPrice' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['ekPrice' + '-' +
                            platform].required.$message.replace('The value',
                            $t('labels.ekPrice'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>{{ $t('labels.productHome.currency') }} </label>

                        <InputText
                            disabled
                            :placeholder="$t('labels.productHome.currency')"
                            autocomplete="off"
                            v-model="v$['currency' + '-' + platform].$model"
                            :class="{'p-invalid':v$['currency' + '-' + platform].$invalid && submitted}"
                        />
                        <small
                            v-if="(v$['currency' + '-' + platform].$invalid && submitted) || v$['currency' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['currency' + '-' +
                            platform].required.$message.replace('Value',
                            $t('labels.productHome.currency'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>
                            {{ $t('labels.productAdd.ekStartDate') }}
                        </label>

                        <p-calendar
                            autocomplete="off"
                            dateFormat="dd.mm.yy"
                            v-model="v$['ekStartDate' + '-' + platform].$model"
                            :class="{'tt-form-input': true, 'p-invalid':v$['ekStartDate' + '-' + platform].$invalid && submitted}"
                        />
                        <small
                            v-if="(v$['ekStartDate' + '-' + platform].$invalid && submitted) || v$['ekStartDate' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['ekStartDate' + '-' +
                            platform].required.$message.replace('The value',
                            $t('labels.productAdd.ekStartDate'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productAdd.taxRateType') }}
                        </label>

                        <p-dropdown
                            v-model="v$['ekTaxRateType' + '-' + platform].$model"
                            :options="taxRateTypeOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('ekTaxRateType', $event)"
                            :showClear="true"
                            :class="{'p-invalid':v$['ekTaxRateType' + '-' + platform].$invalid && submitted}"
                        >
                        </p-dropdown>
                        <small
                            v-if="(v$['ekTaxRateType' + '-' + platform].$invalid && submitted) ||v$['ekTaxRateType' + '-' + platform].$pending.$response"
                            class="p-error"
                            >{{v$['ekTaxRateType' + '-' +
                            platform].required.$message.replace('The value',
                            $t('labels.productAdd.taxRateType'))}}</small
                        >
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>
                            {{ $t('labels.productAdd.discount') }} 1 [%]
                        </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :min="0"
                            :max="100"
                            :maxFractionDigits="2"
                            autocomplete="off"
                            v-model="v$['discount1' + '-' + platform].$model"
                            :class="{'p-invalid':v$['discount1' + '-' + platform].$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>
                            {{ $t('labels.productAdd.discount') }} 2 [%]
                        </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :min="0"
                            :max="100"
                            :maxFractionDigits="2"
                            autocomplete="off"
                            v-model="v$['discount2' + '-' + platform].$model"
                            :class="{'p-invalid':v$['discount2' + '-' + platform].$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label
                            >{{ $t('labels.productAdd.discountGroup') }}
                        </label>

                        <InputText
                            :placeholder="$t('labels.productAdd.discountGroup')"
                            autocomplete="off"
                            v-model="v$['discountGroup' + '-' + platform].$model"
                            :class="{'p-invalid':v$['discountGroup' + '-' + platform].$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>
                            {{ $t('labels.productAdd.trailingDiscount') }} [EUR]
                        </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :maxFractionDigits="2"
                            autocomplete="off"
                            v-model="v$['trailingDiscount' + '-' + platform].$model"
                            :class="{'p-invalid':v$['trailingDiscount' + '-' + platform].$invalid && submitted}"
                        />
                    </div>
                </div>
            </p-fieldset>
        </div>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button
            :disabled="savingInProgress"
            severity="success"
            type="submit"
            v-text="savingInProgress ? $t('labels.inProgress') : $t('labels.save')"
        >
        </p-button>
    </div>
</form>
