import {
    IAuthTokens,
    TokenRefreshRequest,
    applyAuthTokenInterceptor
} from 'axios-jwt';

import store from '@/store/index';
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

const cancelSource = axios.CancelToken.source();

const apiClient: AxiosInstance = axios.create({
    cancelToken: cancelSource.token,
    baseURL: process.env?.VUE_APP_API_ENDPOINT || '',
    headers: {
        'Content-type': 'application/json'
    }
});

const requestRefresh: TokenRefreshRequest = async (
    refreshToken: string
): Promise<IAuthTokens | string> => {
    try {
        const response = await axios.post(
            (process.env?.VUE_APP_API_ENDPOINT || '') + '/auth/refresh',
            {
                username: store.getters['auth/user'].username,
                token: refreshToken
            }
        );

        return {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token
        };
    } catch {
        store.dispatch('auth/logout');
    }
};

applyAuthTokenInterceptor(apiClient, {requestRefresh});

apiClient.interceptors.request.use(
    async (requestConfig: AxiosRequestConfig): Promise<any> => {
        requestConfig.headers['X-WECLAPP-PLATFORM'] =
            store.getters['auth/platform'];
        return requestConfig;
    }
);

export default apiClient;
