import {ref, toRefs} from 'vue';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import useVuelidate from '@vuelidate/core';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import Editor from '@tinymce/tinymce-vue';
import {updateDetails} from '@/services/tickets';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['reload-ticket-details'],
    components: {
        Panel,
        'p-textarea': Textarea,
        'p-inputtext': InputText,
        'p-button': Button,
        tiny: Editor
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const {ticketDetails} = toRefs(props);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            subject: ticketDetails.value.subject,
            description: ticketDetails.value.description,
            ticket_external_rma:
                ticketDetails.value?.customAttributes?.ticket_external_rma,
            ticket_internal_comment:
                ticketDetails.value?.customAttributes?.ticket_internal_comment,
            ticket_serialnumber:
                ticketDetails.value?.customAttributes?.ticket_serialnumber,
            ticket_inbound_tracking:
                ticketDetails.value?.customAttributes?.ticket_inbound_tracking
        });

        const rules = {
            subject: {},
            description: {},
            ticket_external_rma: {},
            ticket_internal_comment: {},
            ticket_serialnumber: {},
            ticket_inbound_tracking: {}
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateDetails(ticketDetails.value.ticketNumber, state.value)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-ticket-details');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            ticketDetails,
            state,
            v$,
            submitted,
            savingInProgress,
            handleSubmit
        };
    }
};
