import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (
    entityName: Array<string>,
    allEnvs: boolean = false,
    columns: Array<string> = []
): Promise<AxiosResponse> => {
    let query =
        '/api/v1/weclapp/metadata?entityName[]=' +
        entityName.join('&entityName[]=') +
        (allEnvs ? '&allEnvs=true' : '');

    if (columns.length > 0) {
        query += '&columns[]=' + columns.join('&columns[]=');
    }

    return apiClient.get(query);
};

export const getSome = (
    entityName: string,
    attributeName: string,
    attributeKeys: Array<string>,
    allEnvs: boolean = false,
    referencedProperties: Array<string> = []
): Promise<AxiosResponse> => {
    let query =
        '/api/v1/weclapp/metadata?entityName=' +
        entityName +
        '&attributeName=' +
        attributeName;

    attributeKeys.forEach((item) => {
        query += '&attributeValue[]=' + item;
    });

    query += allEnvs ? '&allEnvs=true' : '';

    referencedProperties.forEach((item) => {
        query += '&referencedProperty[]=' + item;
    });

    return apiClient.get(query);
};

export const getSingle = (
    entityName: string,
    attributeName: string,
    attributeValue: string
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/weclapp/metadata?entityName=' +
            entityName +
            '&attributeName=' +
            attributeName +
            '&attributeValue=' +
            attributeValue
    );
};
