import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c1aa315"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "text-gray-600"
}
const _hoisted_4 = { class: "mt-1 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm text-sm",
      scrollable: "",
      scrollHeight: 'calc(100vh - 33rem)',
      stripedRows: "",
      paginator: _ctx.badges && _ctx.badges.length > 20,
      rows: 50,
      value: _ctx.badges,
      responsiveLayout: "scroll",
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rows-per-page-options": [10,20,50,100,200],
      "current-page-report-template": "{first} to {last} of {totalRecords}"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, { headerStyle: "width: 3rem" }, {
          body: _withCtx(({data}) => [
            (data.active)
              ? (_openBlock(), _createBlock(_component_p_checkbox, {
                  key: 0,
                  value: data.id,
                  modelValue: _ctx.productBadges,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.productBadges) = $event))
                }, null, 8, ["value", "modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "name",
          header: _ctx.$t('labels.name'),
          style: {"min-width":"12rem"}
        }, {
          body: _withCtx(({data}) => [
            (data.active)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.name), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data.name), 1))
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["scrollHeight", "paginator", "value"]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_p_button, {
        severity: "danger",
        disabled: _ctx.buttonsDisabled || _ctx.buttonsHidden,
        onClick: _ctx.discardChanges,
        textContent: _toDisplayString(_ctx.$t('labels.revert')),
        class: "mr-3"
      }, null, 8, ["disabled", "onClick", "textContent"]),
      _createVNode(_component_p_button, {
        severity: "success",
        onClick: _ctx.saveChanges,
        disabled: _ctx.buttonsDisabled || _ctx.buttonsHidden,
        textContent: _toDisplayString(_ctx.buttonsDisabled ? _ctx.$t('labels.inProgress') : _ctx.$t('labels.save'))
      }, null, 8, ["onClick", "disabled", "textContent"])
    ])
  ]))
}