import {IProduct} from '@/interfaces/product/product';
import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

const getError = (error: any) => {
    const message = error.response?.data.error || 'Failed';
    return new Error(message);
};

export const getSingle = (
    id: string | string[],
    input: any
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/products/' +
            id +
            '/accessories?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            encodeURIComponent(JSON.stringify(clearEmptyFilters(input.filters)))
    );
};

export const bulkSave = async (
    mainProducts: Array<any>,
    productAccessories: Array<any>
): Promise<number> => {
    try {
        const result = await apiClient.post('/api/v1/product-accessories', {
            products: mainProducts.map(({id, articleNumber}) => {
                return {
                    id,
                    articleNumber
                };
            }),
            accessories: productAccessories.map(({id, articleNumber}) => {
                return {
                    id,
                    articleNumber
                };
            })
        });

        return result.status;
    } catch (error: any) {
        throw getError(error);
    }
};

export const bulkReplace = async (
    mainProducts: Array<IProduct>,
    existingAccessory: IProduct,
    newAccessory: IProduct | null
): Promise<number> => {
    try {
        const payload = Object.assign(
            {
                existingAccessory: (({id, articleNumber}) => ({
                    id,
                    articleNumber
                }))(existingAccessory),
                products: mainProducts.map(({id, articleNumber}) => {
                    return {
                        id,
                        articleNumber
                    };
                })
            },
            newAccessory
                ? {
                      newAccessory: (({id, articleNumber}) => ({
                          id,
                          articleNumber
                      }))(newAccessory)
                  }
                : {}
        );

        const result = await apiClient.put(
            '/api/v1/product-accessories',
            payload
        );

        return result.status;
    } catch (error: any) {
        throw getError(error);
    }
};

export const bulkDelete = async (
    productId: string | string[],
    productAccessories: Array<any>
): Promise<number> => {
    try {
        const ids = productAccessories.map(({articleNumber}) => {
            return 'accessories[]=' + articleNumber;
        });
        const result = await apiClient.delete(
            '/api/v1/products/' + productId + '/accessories?' + ids.join('&')
        );

        return result.status;
    } catch (error: any) {
        throw getError(error);
    }
};
