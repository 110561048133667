import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c45a648"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "formgrid grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "formgrid grid p-fluid" }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "formgrid grid p-fluid" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = { class: "formgrid grid p-fluid" }
const _hoisted_13 = { class: "col field" }
const _hoisted_14 = {
  key: 0,
  class: "p-error"
}
const _hoisted_15 = { class: "formgrid grid p-fluid" }
const _hoisted_16 = { class: "col field" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = { class: "formgrid grid p-fluid" }
const _hoisted_19 = { class: "col field" }
const _hoisted_20 = {
  key: 0,
  class: "p-error"
}
const _hoisted_21 = { class: "formgrid grid p-fluid" }
const _hoisted_22 = { class: "col field" }
const _hoisted_23 = { class: "formgrid grid p-fluid" }
const _hoisted_24 = { class: "col field" }
const _hoisted_25 = { class: "formgrid grid p-fluid" }
const _hoisted_26 = { class: "col field" }
const _hoisted_27 = { class: "formgrid grid p-fluid" }
const _hoisted_28 = { class: "col field" }
const _hoisted_29 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedEnvironments, (platform) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_p_fieldset, {
            legend: _ctx.platforms.find((item) => item.value === platform).label
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.supplier')), 1),
                  _createVNode(_component_p_dropdown, {
                    modelValue: _ctx.v$['supplier' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['supplier' + '-' + platform].$model) = $event),
                    options: _ctx.suppliers[platform]['supplier'] || [],
                    optionLabel: "label",
                    optionValue: "value",
                    filter: true,
                    onChange: $event => (_ctx.setDropdownValue(('supplier' + '-' + platform), $event)),
                    showClear: true,
                    class: _normalizeClass({'p-invalid':_ctx.v$['supplier' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "onChange", "class"]),
                  ((_ctx.v$['supplier' + '-' + platform].$invalid && _ctx.submitted) || _ctx.v$['supplier' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$['supplier' + '-' +
                            platform].required.$message.replace('Value',
                            _ctx.$t('labels.productAdd.supplier'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.sku')), 1),
                  _createVNode(_component_InputText, {
                    placeholder: _ctx.$t('labels.productAdd.sku'),
                    autocomplete: "off",
                    modelValue: _ctx.v$['sku' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['sku' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['sku' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue", "class"]),
                  ((_ctx.v$['sku' + '-' + platform].$invalid && _ctx.submitted) || _ctx.v$['sku' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$['sku' + '-' +
                            platform].required.$message.replace('Value',
                            _ctx.$t('labels.productAdd.sku'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ekPrice')), 1),
                  _createVNode(_component_InputNumber, {
                    mode: "decimal",
                    locale: _ctx.locale,
                    min: 0,
                    maxFractionDigits: 2,
                    autocomplete: "off",
                    modelValue: _ctx.v$['ekPrice' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['ekPrice' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['ekPrice' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"]),
                  ((_ctx.v$['ekPrice' + '-' + platform].$invalid && _ctx.submitted) || _ctx.v$['ekPrice' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.v$['ekPrice' + '-' +
                            platform].required.$message.replace('The value',
                            _ctx.$t('labels.ekPrice'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.currency')), 1),
                  _createVNode(_component_InputText, {
                    disabled: "",
                    placeholder: _ctx.$t('labels.productHome.currency'),
                    autocomplete: "off",
                    modelValue: _ctx.v$['currency' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['currency' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['currency' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue", "class"]),
                  ((_ctx.v$['currency' + '-' + platform].$invalid && _ctx.submitted) || _ctx.v$['currency' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.v$['currency' + '-' +
                            platform].required.$message.replace('Value',
                            _ctx.$t('labels.productHome.currency'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.ekStartDate')), 1),
                  _createVNode(_component_p_calendar, {
                    autocomplete: "off",
                    dateFormat: "dd.mm.yy",
                    modelValue: _ctx.v$['ekStartDate' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['ekStartDate' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$['ekStartDate' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
                  ((_ctx.v$['ekStartDate' + '-' + platform].$invalid && _ctx.submitted) || _ctx.v$['ekStartDate' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_17, _toDisplayString(_ctx.v$['ekStartDate' + '-' +
                            platform].required.$message.replace('The value',
                            _ctx.$t('labels.productAdd.ekStartDate'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.taxRateType')), 1),
                  _createVNode(_component_p_dropdown, {
                    modelValue: _ctx.v$['ekTaxRateType' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['ekTaxRateType' + '-' + platform].$model) = $event),
                    options: _ctx.taxRateTypeOptions,
                    optionLabel: "label",
                    optionValue: "value",
                    onChange: _cache[0] || (_cache[0] = $event => (_ctx.setDropdownValue('ekTaxRateType', $event))),
                    showClear: true,
                    class: _normalizeClass({'p-invalid':_ctx.v$['ekTaxRateType' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "class"]),
                  ((_ctx.v$['ekTaxRateType' + '-' + platform].$invalid && _ctx.submitted) ||_ctx.v$['ekTaxRateType' + '-' + platform].$pending.$response)
                    ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.v$['ekTaxRateType' + '-' +
                            platform].required.$message.replace('The value',
                            _ctx.$t('labels.productAdd.taxRateType'))), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discount')) + " 1 [%] ", 1),
                  _createVNode(_component_InputNumber, {
                    mode: "decimal",
                    locale: _ctx.locale,
                    min: 0,
                    max: 100,
                    maxFractionDigits: 2,
                    autocomplete: "off",
                    modelValue: _ctx.v$['discount1' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['discount1' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['discount1' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discount')) + " 2 [%] ", 1),
                  _createVNode(_component_InputNumber, {
                    mode: "decimal",
                    locale: _ctx.locale,
                    min: 0,
                    max: 100,
                    maxFractionDigits: 2,
                    autocomplete: "off",
                    modelValue: _ctx.v$['discount2' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['discount2' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['discount2' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"])
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.discountGroup')), 1),
                  _createVNode(_component_InputText, {
                    placeholder: _ctx.$t('labels.productAdd.discountGroup'),
                    autocomplete: "off",
                    modelValue: _ctx.v$['discountGroup' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['discountGroup' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['discountGroup' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue", "class"])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.trailingDiscount')) + " [EUR] ", 1),
                  _createVNode(_component_InputNumber, {
                    mode: "decimal",
                    locale: _ctx.locale,
                    maxFractionDigits: 2,
                    autocomplete: "off",
                    modelValue: _ctx.v$['trailingDiscount' + '-' + platform].$model,
                    "onUpdate:modelValue": $event => ((_ctx.v$['trailingDiscount' + '-' + platform].$model) = $event),
                    class: _normalizeClass({'p-invalid':_ctx.v$['trailingDiscount' + '-' + platform].$invalid && _ctx.submitted})
                  }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "class"])
                ])
              ])
            ]),
            _: 2
          }, 1032, ["legend"])
        ]))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        disabled: _ctx.savingInProgress,
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('labels.save'))
      }, null, 8, ["disabled", "textContent"])
    ])
  ], 32))
}