import {onMounted, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {createCompensationShipment} from '@/services/tickets';
import Dropdown from 'primevue/dropdown';
import {getAll} from '@/services/metadata';
import Editor from '@tinymce/tinymce-vue';
import {decodeEscapedHTML, stripTagsAndTruncate} from '@/utils/helpers';

export default {
    emits: ['close-create-compensation-shipment-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        loading: LoadingPlugin,
        tiny: Editor
    },
    props: {
        ticketDetails: Object,
        retoure: Object,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {ticketDetails, retoure} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);
        const warehouses = ref([]);
        const loading = ref(false);

        const state = ref({
            warehouse: null,
            documentText: null
        });

        onMounted(() => {
            getAll(['warehouse'], false).then((data: any) => {
                warehouses.value = data.data['warehouse'];
            });
        });

        const rules = {
            warehouse: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            documentText: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;

            if (showDialog.value) {
                if (args.retoure?.warehouse?.id) {
                    state.value.warehouse = args.retoure.warehouse.id;
                }
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                warehouse: null,
                documentText: null
            };
            context.emit('close-create-compensation-shipment-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                await createCompensationShipment(
                    ticketDetails.value.ticketNumber,
                    retoure.value.incomingGoodsNumber,
                    {
                        warehouse: state.value?.warehouse,
                        documentText: state.value?.documentText
                            ? decodeEscapedHTML(
                                  stripTagsAndTruncate(
                                      state.value.documentText,
                                      state.value.documentText.length,
                                      ''
                                  ).trim()
                              )
                            : null
                    }
                );
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-create-compensation-shipment-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-create-compensation-shipment-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    warehouse: null,
                    documentText: null
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);
        };

        return {
            showDialog,
            loading,
            warehouses,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            tinyTemplates: [
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.documentTextTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.documentText'
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.documentTextEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.documentTextEnglish'
                    )
                }
            ]
        };
    }
};
