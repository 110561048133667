<!-- Content Header (Page header) -->
<loading v-model:active="isLoading" />
<Panel :header="$t('labels.importWizard')">
    <SupplierAutoComplete @data-entered="onSupplierChosen" />
    <div v-bind="dropzone.getRootProps()" class="import-wizard-container">
        <div style="text-align: center">
            <input v-bind="dropzone.getInputProps()" />
            <p v-if="dropzone.acceptedFiles.length > 0">
                {{ dropzone.acceptedFiles[0].name }}
            </p>
            <p v-else>{{ $t("labels.importWizardInfoText") }}</p>
        </div>
    </div>
    <div class="mt-3">
        <div class="flex justify-content-between">
            <p-button @click="downloadFileTemplate" severity="info">
                {{$t('labels.downloadImportFileTemplate')}}
            </p-button>
            <p-button v-if="showUploadButton" @click="uploadFile" class="ml-2">
                {{ $t("buttons.uploadFile") }}
            </p-button>
        </div>
        <!-- /.col -->
    </div>

    <div
        style="margin-top: 100px"
        v-if="savedFileId !== null && rowsToSkip.length < 1"
    >
        <header-mapping-grid
            :savedFileId="savedFileId"
            @column-mapping-saved="validateFile()"
        ></header-mapping-grid>
    </div>
    <div style="margin-top: 100px" v-if="rowsToSkip.length > 0">
        <validation-grid
            :savedFileId="savedFileId"
            :rowsToSkip="rowsToSkip"
            @import-result-received="importResultReceived"
        ></validation-grid>
    </div>
    <div v-show="importResult" class="mt-5">
        <span
            class="font-medium"
            ref="fileScheduledForImportTextPanel"
            v-html="$t('messages.fileScheduledForImportLong')"
        ></span>
        <i
            class="pi pi-times-circle mt-1 ml-1 text-red-600"
            style="cursor: pointer"
            @click="importResult = false;"
        ></i>
    </div>
</Panel>

<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showPlatformConfirmationDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :closable="false"
    :modal="true"
    @show="onShowConfirmationDialog"
>
    <div class="card-body">
        <div>
            <h6
                v-html="$t('messages.importFileToPlatformConfirmationWithField', {
                    platform: importPlatform?.label, 
                    confirmationValue: importPlatform?.threeLetterId  })"
            ></h6>
        </div>
        <div class="mt-2 p-fluid">
            <InputText
                :placeholder="$t('labels.targetPlatform')"
                autocomplete="off"
                v-model="confirmationInputValue"
                ref="confirmationInputField"
            />
        </div>
        <div class="mt-3 flex justify-content-between">
            <p-button
                severity="danger"
                @click="closePlatformConfirmationDialog"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="closePlatformConfirmationDialog($event, true)"
                :disabled="!confirmButtonEnabled"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </div>
</PrimeDialog>

<!-- /.content -->
