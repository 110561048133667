import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/akku_groesser.png'
import _imports_1 from '@/assets/img/akku_kleiner.jpg'
import _imports_2 from '@/assets/img/aerosole.png'


const _withScopeId = n => (_pushScopeId("data-v-151acdbf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-fluid mb-5" }
const _hoisted_2 = { class: "grid mb-3" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "col" }
const _hoisted_6 = {
  key: 0,
  class: "text-purple-600"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "grid mb-3" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = ["alt"]
const _hoisted_13 = ["alt"]
const _hoisted_14 = ["alt"]
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "grid mb-5" }
const _hoisted_18 = { class: "col" }
const _hoisted_19 = { class: "grid p-fluid" }
const _hoisted_20 = { class: "field col" }
const _hoisted_21 = {
  key: 0,
  class: "font-semibold"
}
const _hoisted_22 = {
  key: 1,
  class: "font-semibold"
}
const _hoisted_23 = { class: "font-semibold" }
const _hoisted_24 = { class: "grid p-fluid" }
const _hoisted_25 = { class: "field col" }
const _hoisted_26 = {
  key: 0,
  class: "grid p-fluid"
}
const _hoisted_27 = { class: "field col" }
const _hoisted_28 = { class: "font-semibold" }
const _hoisted_29 = { class: "grid p-fluid" }
const _hoisted_30 = { class: "field col" }
const _hoisted_31 = { class: "grid p-fluid" }
const _hoisted_32 = { class: "field col" }
const _hoisted_33 = { class: "col" }
const _hoisted_34 = {
  key: 0,
  class: "mb-5 p-3 font-semibold",
  style: {"border":"red 2px solid"}
}
const _hoisted_35 = {
  key: 0,
  class: "text-red-600"
}
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "col" }
const _hoisted_38 = { class: "flex justify-content-end" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "confirmation-content" }
const _hoisted_41 = { class: "grid mb-3" }
const _hoisted_42 = {
  key: 0,
  class: "col d-flex justify-content-center"
}
const _hoisted_43 = ["alt"]
const _hoisted_44 = {
  key: 1,
  class: "col d-flex justify-content-center"
}
const _hoisted_45 = ["alt"]
const _hoisted_46 = {
  key: 2,
  class: "col d-flex justify-content-center"
}
const _hoisted_47 = ["alt"]
const _hoisted_48 = {
  key: 3,
  class: "col d-flex justify-content-center mb-2"
}
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "w-full flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_app_keyboard = _resolveComponent("app-keyboard")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Panel = _resolveComponent("Panel")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")
  const _component_p_dialog = _resolveComponent("p-dialog")
  const _component_UpsAnnounceDangerousGoodsDialog = _resolveComponent("UpsAnnounceDangerousGoodsDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.shipment.shipmentMask')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_InputText, {
            disabled: _ctx.shipmentNumberPreSelected,
            placeholder: _ctx.$t('labels.shipment.shipmentNumber'),
            autocomplete: "off",
            modelValue: _ctx.shipmentNumber,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.shipmentNumber) = $event)),
            onPaste: _ctx.onShipmentNumberPaste,
            onInput: _ctx.onShipmentNumberChange,
            onKeyup: _withKeys(_ctx.onShipmentNumberEnter, ["enter"]),
            ref: "shipmentNumberInputField"
          }, null, 8, ["disabled", "placeholder", "modelValue", "onPaste", "onInput", "onKeyup"])
        ]),
        (_ctx.shipmentDetails)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.shipment.shipmentNumber')), 1),
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      href: _ctx.shipmentDetails.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + _ctx.shipmentDetails.id,
                      target: "_blank"
                    }, _toDisplayString(_ctx.shipmentDetails.shipmentNumber), 9, _hoisted_4),
                    _createElementVNode("span", {
                      class: _normalizeClass(["product-badge ml-2 text-white", {'bg-orange-600' : _ctx.shipmentDetails.status === 'DELIVERY_NOTE_PRINTED', 'bg-blue-600':_ctx.shipmentDetails.status !== 'DELIVERY_NOTE_PRINTED'}])
                    }, _toDisplayString(_ctx.$t('labels.shipment.statusOptions.' +
                        _ctx.shipmentDetails.status)), 3)
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.shipment.recipient')), 1),
                  (_ctx.shipmentDetails.shipmentType === 'INTERNAL')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('labels.shipment.shipmentTypeOptions.INTERNAL')), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("a", {
                          href: _ctx.shipmentDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + _ctx.shipmentDetails.recipientParty?.id,
                          target: "_blank"
                        }, _toDisplayString(_ctx.outputRecipient(_ctx.shipmentDetails)), 9, _hoisted_8)
                      ]))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("h5", null, _toDisplayString(_ctx.$t('labels.shipment.shipmentLocation')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shippingLocationOptions, (platformItem) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (_ctx.v$.shippingLocation.$model === platformItem.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.toggleDataTable && _ctx.toggleDataTable(...args))),
                            style: {"cursor":"pointer"}
                          }, [
                            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'truck'] }),
                            _createTextVNode("   " + _toDisplayString(platformItem.label), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256)),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingLocation.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_DataTable, {
                    class: "p-datatable-sm mask-positions",
                    stripedRows: "",
                    ref: "dt",
                    dataKey: "id",
                    value: _ctx.groupedShipmentItems,
                    responsiveLayout: "scroll",
                    expandedRows: _ctx.expandedRows,
                    "onUpdate:expandedRows": _cache[3] || (_cache[3] = $event => ((_ctx.expandedRows) = $event)),
                    rowClass: _ctx.rowClass
                  }, {
                    expansion: _withCtx((slotProps) => [
                      (slotProps.data.description)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: slotProps.data.description
                          }, null, 8, _hoisted_15))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, "No description available."))
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        expander: true,
                        headerStyle: "width: 3em"
                      }),
                      _createVNode(_component_Column, {
                        field: "positionNumber",
                        sortable: _ctx.groupedShipmentItems?.length > 1,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.position')
                      }, null, 8, ["sortable", "header"]),
                      (_ctx.v$.groupedShipments.$model && _ctx.v$.groupedShipments.$model.length > 0)
                        ? (_openBlock(), _createBlock(_component_Column, {
                            key: 0,
                            style: {"min-width":"9rem"},
                            header: _ctx.$t('labels.shipment.shipmentNumber')
                          }, {
                            body: _withCtx(({data}) => [
                              _createTextVNode(_toDisplayString(data.shipmentNumber), 1)
                            ]),
                            _: 1
                          }, 8, ["header"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Column, {
                        field: "title",
                        sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.article')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.title) + " ", 1),
                          (data.article_flag_akku_groesser_100wh)
                            ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _imports_0,
                                alt: _ctx.$t('labels.productHome.dangerousGoods'),
                                style: {"height":"30px","padding-left":"10px"}
                              }, null, 8, _hoisted_12)), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.productHome.dangerousGoods'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true),
                          (data.article_flag_akku_kleiner_100wh)
                            ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                key: 1,
                                src: _imports_1,
                                alt: _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                                style: {"height":"30px","padding-left":"10px"}
                              }, null, 8, _hoisted_13)), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true),
                          (data.article_flag_aerosole)
                            ? _withDirectives((_openBlock(), _createElementBlock("img", {
                                key: 2,
                                src: _imports_2,
                                alt: _ctx.$t('labels.productHome.containsAerosols'),
                                style: {"height":"30px","padding-left":"10px"}
                              }, null, 8, _hoisted_14)), [
                                [
                                  _directive_tooltip,
                                  _ctx.$t('labels.productHome.containsAerosols'),
                                  void 0,
                                  { top: true }
                                ]
                              ])
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["sortable", "header"]),
                      _createVNode(_component_Column, {
                        field: "quantity",
                        sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.shipment.shipmentQuantity')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.quantity) + " " + _toDisplayString(data.unitName), 1)
                        ]),
                        _: 1
                      }, 8, ["sortable", "header"]),
                      _createVNode(_component_Column, {
                        field: "articleNumber",
                        sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.articleNumber')
                      }, null, 8, ["sortable", "header"]),
                      _createVNode(_component_Column, {
                        sortable: _ctx.shipmentDetails.shipmentItems?.length > 1,
                        style: {"min-width":"9rem"},
                        header: _ctx.$t('labels.serialNumbers')
                      }, {
                        body: _withCtx(({data}) => [
                          _createTextVNode(_toDisplayString(data.serialNumbers?.join(', ')), 1)
                        ]),
                        _: 1
                      }, 8, ["sortable", "header"])
                    ]),
                    _: 1
                  }, 8, ["value", "expandedRows", "rowClass"])
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.shipmentAddress')), 1),
                      (_ctx.shipmentDetails.recipientAddress?.company)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.company), 1))
                        : (_ctx.shipmentDetails.recipientAddress?.lastName)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.salutation) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.firstName) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.lastName), 1))
                          : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.shipmentDetails.recipientAddress?.street1) + ", " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.zipcode) + " " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.city) + ", " + _toDisplayString(_ctx.shipmentDetails.recipientAddress?.countryCode), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.shippingCarrier')), 1),
                      _createVNode(_component_p_dropdown, {
                        modelValue: _ctx.v$.shippingCarrier.$model,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.shippingCarrier.$model) = $event)),
                        optionLabel: "label",
                        optionValue: "value",
                        options: _ctx.shippingCarrierOptions,
                        onChange: _ctx.onShippingCarrierChange,
                        disabled: _ctx.shipmentDetails.status !== 'NEW' || _ctx.shipmentDetails.isPickup,
                        class: _normalizeClass({'p-invalid':_ctx.v$.shippingCarrier.$invalid && _ctx.submitted})
                      }, null, 8, ["modelValue", "options", "onChange", "disabled", "class"]),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingCarrier.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  (_ctx.shipmentDetails?.customAttributes?.cust_ups_account_no)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsNumber')), 1),
                          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.shipmentDetails?.customAttributes?.cust_ups_account_no), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.v$.groupedShipments.$model &&
                            _ctx.v$.groupedShipments.$model.length > 0 ?
                            'labels.shipment.groupedWeight' :
                            'labels.shipment.weight')), 1),
                      _createVNode(_component_InputText, {
                        name: "packageWeight",
                        pattern: "^\\d*(,\\d{0,3})?$",
                        placeholder: _ctx.$t('labels.shipment.weight'),
                        autocomplete: "off",
                        onFocus: _ctx.onElementFocused,
                        onKeydown: _ctx.onElementKeyDown,
                        modelValue: _ctx.v$.packageWeight.$model,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.packageWeight.$model) = $event)),
                        class: _normalizeClass({'p-invalid':_ctx.v$.packageWeight.$invalid && _ctx.submitted}),
                        ref: "packageWeightInputField"
                      }, null, 8, ["placeholder", "onFocus", "onKeydown", "modelValue", "class"]),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageWeight.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.v$.groupedShipments.$model &&
                            _ctx.v$.groupedShipments.$model.length > 0 ?
                            'labels.shipment.groupedNumberOfLabels' :
                            'labels.shipment.numberOfLabels')), 1),
                      _createVNode(_component_InputText, {
                        pattern: "^\\d*$",
                        name: "shippingLabelsCount",
                        onKeydown: _ctx.onElementKeyDown,
                        placeholder: _ctx.$t('labels.shipment.numberOfLabels'),
                        autocomplete: "off",
                        onFocus: _ctx.onElementFocused,
                        modelValue: _ctx.v$.shippingLabelsCount.$model,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.shippingLabelsCount.$model) = $event)),
                        class: _normalizeClass({'p-invalid':_ctx.v$.shippingLabelsCount.$invalid && _ctx.submitted}),
                        ref: "shippingLabelsCountInputField"
                      }, null, 8, ["onKeydown", "placeholder", "onFocus", "modelValue", "class"]),
                      (_ctx.submitted)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingLabelsCount.$silentErrors, (error) => {
                            return (_openBlock(), _createElementBlock("small", {
                              style: {"display":"block"},
                              class: "p-error",
                              key: error
                            }, _toDisplayString(error.$message), 1))
                          }), 128))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  (_ctx.shipmentDetails?.pickingInstructions)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(_ctx.shipmentDetails.pickingInstructions), 1))
                    : _createCommentVNode("", true),
                  ((_ctx.isUpsCarrier || _ctx.isDhlCarrier) && _ctx.groupedShipmentOptions.length > 0)
                    ? (_openBlock(), _createBlock(_component_DataTable, {
                        key: 1,
                        class: "p-datatable-sm",
                        stripedRows: "",
                        style: {"height":"90%"},
                        paginator: _ctx.groupedShipmentOptions.length > 4,
                        rows: 4,
                        filterDisplay: "row",
                        dataKey: "shipmentNumber",
                        value: _ctx.groupedShipmentOptions,
                        responsiveLayout: "scroll",
                        filters: _ctx.groupedFilters,
                        "onUpdate:filters": _cache[8] || (_cache[8] = $event => ((_ctx.groupedFilters) = $event)),
                        rowClass: _ctx.rowClass
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, { style: {"min-width":"2rem","max-width":"fit-content"} }, {
                            body: _withCtx(({data}) => [
                              (!_ctx.groupedMultiSelectOptionFilterFunction(data))
                                ? (_openBlock(), _createBlock(_component_p_checkbox, {
                                    key: 0,
                                    modelValue: _ctx.v$.groupedShipments.$model,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.groupedShipments.$model) = $event)),
                                    value: data.shipmentNumber,
                                    onChange: _ctx.onCheckboxChange
                                  }, null, 8, ["modelValue", "value", "onChange"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_Column, {
                            field: "shipmentNumber",
                            header: "Weitere Lieferungen des Kunden"
                          }, {
                            filter: _withCtx(({filterModel,filterCallback}) => [
                              _createVNode(_component_InputText, {
                                modelValue: filterModel.value,
                                "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                                onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                                placeholder: _ctx.$t('labels.shipment.shipmentNumber'),
                                onFocus: $event => {_ctx.onElementFocused($event, filterModel, filterCallback);},
                                name: "groupedShipmentFilter",
                                ref: "groupedShipmentFilterField"
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder", "onFocus"])
                            ]),
                            body: _withCtx(({data}) => [
                              (_ctx.groupedMultiSelectOptionFilterFunction(data))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_35, _toDisplayString(data.shipmentNumber), 1))
                                : (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(data.shipmentNumber), 1))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["paginator", "value", "filters", "rowClass"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createVNode(_component_app_keyboard, { onPressed: _ctx.onKeyboardPressed }, null, 8, ["onPressed"])
                ])
              ]),
              _createElementVNode("div", _hoisted_38, [
                (_ctx.showUpsDangerousGoodsButton)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 0,
                      disabled: !_ctx.upsDangerousGoodsSuitableRecipientAddress,
                      onClick: _cache[9] || (_cache[9] = $event => (_ctx.displayUPSAnnounceDangerousGoodsDialog = true))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.enterDangerousGoodsData")), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_p_button, {
                  disabled: _ctx.haveSavingButtonDisabled,
                  class: "ml-2",
                  severity: "success",
                  textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.submitButtonText),
                  onClick: _ctx.handleFinalSubmitClick
                }, null, 8, ["disabled", "textContent", "onClick"]),
                _createVNode(_component_p_button, {
                  severity: "danger",
                  onClick: _ctx.resetShipmentNumber,
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancelShipment")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_OverlayPanel, {
      ref: "opShippingLocations",
      appendTo: "body",
      id: "overlay_panel_shipping_locations"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.shippingLocationOptions,
          paginator: _ctx.shippingLocationOptions?.length > 5,
          rows: 5,
          responsiveLayout: "scroll"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { headerStyle: "min-width:12rem;display:none;" }, {
              body: _withCtx(({data}) => [
                _createElementVNode("div", {
                  style: {"cursor":"pointer"},
                  onClick: $event => {_ctx.changeShippingLocation(data.value);_ctx.toggleDataTable();}
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: ['fas', 'truck'] }),
                  _createTextVNode("   " + _toDisplayString(data.label), 1)
                ], 8, _hoisted_39)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "paginator"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_p_dialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.displayDangerousGoodsConfirmationDialog,
      "onUpdate:visible": _cache[11] || (_cache[11] = $event => ((_ctx.displayDangerousGoodsConfirmationDialog) = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '450px'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_51, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _cache[10] || (_cache[10] = $event => (_ctx.displayDangerousGoodsConfirmationDialog = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_p_button, {
            severity: "success",
            onClick: _ctx.handleDangerousGoodsConfirmClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            (_ctx.confirmationNeededForAkkuGroesser)
              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_0,
                    alt: _ctx.$t('labels.productHome.dangerousGoods'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_43), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.dangerousGoods'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForAkkuKleiner)
              ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_1,
                    alt: _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_45), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.dangerousGoodsSmall'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForAerosols)
              ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                  _withDirectives(_createElementVNode("img", {
                    class: "rounded",
                    src: _imports_2,
                    alt: _ctx.$t('labels.productHome.containsAerosols'),
                    style: {"height":"120px"}
                  }, null, 8, _hoisted_47), [
                    [
                      _directive_tooltip,
                      _ctx.$t('labels.productHome.containsAerosols'),
                      void 0,
                      { top: true }
                    ]
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.confirmationNeededForSpedition)
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', 'cart-flatbed'],
                    style: {"font-size":"4rem"}
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.confirmationNeededForSpedition)
            ? (_openBlock(), _createElementBlock("div", _hoisted_49, _toDisplayString(_ctx.$t('messages.speditionProductWarning', {product:
            _ctx.confirmationNeededForSpedition})), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_50, _toDisplayString(_ctx.$t('messages.dangerousGoodsConfirmation')), 1))
        ])
      ]),
      _: 1
    }, 8, ["header", "visible"]),
    _createVNode(_component_UpsAnnounceDangerousGoodsDialog, {
      "display-dialog": _ctx.displayUPSAnnounceDangerousGoodsDialog,
      onCloseDialog: _ctx.closeUPSAnnounceDangerousGoodsDialogListener,
      "shipment-items": _ctx.groupedShipmentItems,
      shipment: _ctx.shipmentDetails
    }, null, 8, ["display-dialog", "onCloseDialog", "shipment-items", "shipment"])
  ], 64))
}