<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="flex justify-content-between w-full">
            <div>
                <span v-if="parentData?.supplierId" class="text-lg font-bold">
                    {{parentData?.supplierConfiguration?.supplierName}} [<a
                        :href="url + 'webapp/view/party/PartyDetail.page?entityId=' + parentData.supplierId"
                        target="_blank"
                        >{{parentData?.supplierNumber}}</a
                    >]
                </span>
                <span class="spacer-dot" v-if="parentData?.environment"></span>
                <span
                    v-if="parentData?.environment"
                    class="text-lg"
                    v-html="getPlatformCellContent(parentData.environment)"
                ></span>
                <div class="mt-2">
                    <span v-if="parentData?.uploadedBy"
                        >{{$t('labels.uploadedBy')}}:
                        <span class="font-semibold">
                            {{parentData.uploadedBy}}</span
                        ></span
                    >
                    <span
                        class="spacer-dot"
                        v-if="parentData?.processingEndedAt"
                    ></span>
                    <span v-if="parentData?.processingEndedAt"
                        >{{$t('labels.importedAt')}}:
                        <span class="font-semibold">
                            {{parentData.processingEndedAt}}</span
                        >
                    </span>
                </div>
            </div>
            <div>
                <p-button
                    class="mr-3"
                    severity="success"
                    v-text="$t('labels.overview')"
                    @click="onBackToOverviewClick"
                >
                </p-button>
                <p-button
                    v-text="$t('labels.importLog')"
                    @click="onBackToListClick"
                >
                </p-button>
                <p-button
                    v-if="!isIrregularFilterActive && !parentData?.importInProgress && parentData?.processingEndedAt && parentData?.statistics?.regularItemsCount > parentData?.statistics?.handledItemsCount"
                    class="ml-3"
                    severity="danger"
                    icon="fas fa-play"
                    @click="runManually"
                />
            </div>
        </div>
    </template>
    <DataTable
        class="p-datatable-sm"
        stripedRows
        paginator
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="logs"
        :rowClass="rowClass"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200,500]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
    >
        <Column
            headerStyle="width: 3rem"
            header="Aus der Verarbeitung ausschließen"
            v-if="!isIrregularFilterActive && (logs || []).some((item) => item.status < 1000 && !item.processedAtTimestamp)"
        >
            <template #filter>
                <div class="flex justify-content-start w-full ff">
                    <Badge :value="selectedItems.length"></Badge>
                    <i
                        v-if="selectedItems.length > 0"
                        class="pi pi-times-circle text-red-600 ml-1 mt-1"
                        @click="selectedItems = [];"
                    ></i></div
            ></template>
            <template #body="{data}">
                <p-checkbox
                    v-if="!data.processedAtTimestamp"
                    :value="data.id"
                    v-model="selectedItems"
                /> </template
        ></Column>
        <Column
            field="sku"
            :header="$t('labels.productAdd.sku')"
            sortable
            style="max-width: 16rem"
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                /> </template
            ><template #body="{data}">
                <div class="font-bold">{{data.sku}}</div>

                <div v-if="data.extractedData?.ean" class="text-sm">
                    EAN: {{data.extractedData?.ean}}
                </div>
            </template></Column
        >
        <Column
            :header="$t('labels.article')"
            field="weclappArticle"
            style="min-width: 16rem"
            :filterMatchModeOptions="[{label: 'Contains', value: articleComboFilter}]"
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                /> </template
            ><template #body="{data}">
                <div v-if="data.weclappArticle?.articleNumber">
                    <a
                        :href="url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.weclappArticle?.id"
                        target="_blank"
                        >{{data.weclappArticle?.articleNumber}}</a
                    >
                    <Tag
                        v-if="!data.weclappArticle.active"
                        severity="danger"
                        class="ml-2"
                    >
                        <span>Inaktiv</span>
                    </Tag>
                </div>
                <div v-if="data.weclappArticle?.name" class="text-sm mt-1">
                    {{data.weclappArticle.name}}
                </div>
                <div v-if="data.weclappArticle?.ean" class="text-sm mt-1">
                    EAN: {{data.weclappArticle?.ean}}
                </div>
                <div class="mt-2">
                    <Tag
                        v-if="!data.isPrimarySupplySource"
                        severity="danger"
                        class="mr-1"
                    >
                        <span>Lieferant nicht primär</span>
                    </Tag>
                    <Tag
                        v-if="data.costPriceLocked"
                        class="mr-1"
                        severity="danger"
                    >
                        <span>EK Promo</span>
                    </Tag>
                    <Tag
                        v-if="data.sellPriceLocked"
                        class="mr-1"
                        severity="info"
                    >
                        <span>VK Promo</span>
                    </Tag>
                    <Tag v-if="data.listPriceLocked" severity="warning">
                        <span>UVP Lock</span>
                    </Tag>
                    <div class="mt-1">
                        <Tag
                            v-if="data.weclappArticle.systemTags.includes('TTC-PRODUCT-DEMO')"
                            severity="info"
                            class="mr-2"
                        >
                            <span>DEMO</span>
                        </Tag>
                        <Tag
                            v-if="data.weclappArticle.systemTags.includes('TTC-PRODUCT-USED')"
                            class="mr-1"
                        >
                            <span>USED</span>
                        </Tag>
                        <Tag
                            v-if="data.weclappArticle.systemTags.includes('TTC-PRODUCT-EXDEMO')"
                            severity="success"
                            class="mr-1"
                        >
                            <span>EX-DEMO</span>
                        </Tag>
                        <Tag
                            v-if="data.weclappArticle.systemTags.includes('TTC-PRODUCT-OPENBOX')"
                            severity="warning"
                            class="mr-1"
                        >
                            <span>OPENBOX</span>
                        </Tag>
                        <Tag
                            v-if="data.weclappArticle.systemTags.includes('TTC-PRODUCT-COMMISSION-GOOD')"
                            severity="danger"
                            class="mr-1"
                        >
                            <span>KOMMISSIONSWARE</span>
                        </Tag>
                        <Tag
                            v-if="data.sourceProductTag"
                            severity="primary"
                            class="mt-1"
                        >
                            <span
                                >NEUWARE: {{data.sourceProductTag.replace(
                                'TTC-PRODUCT-NEW-', '' )}}</span
                            >
                        </Tag>
                    </div>
                </div>
            </template></Column
        >
        <Column
            header="Änderungssatz"
            style="max-width: 16rem"
            field="changeStatus"
            :filterMatchModeOptions="[{label: 'Equals', value: teltecChangeStatusFilter}]"
            v-if="!isIrregularFilterActive"
        >
            <template #filter="{filterModel, filterCallback}">
                <p-multiselect
                    v-model="filterModel.value"
                    :options="[
                                    {
                                        value: -1,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 4,
                                        label: 'UVP'
                                    },
                                    {
                                        value: 8,
                                        label: 'VK'
                                    },
                                    {
                                        value: 16,
                                        label: 'EK'
                                    }
                                   
                                ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-xs"
                    :showClear="true"
                >
                </p-multiselect> </template
            ><template #body="{data}">
                <div class="text-sm">
                    <ul class="list-none changeset p-0 m-0 flex-grow-1">
                        <li
                            class="flex align-items-center mb-1"
                            v-if="data.costPriceChange"
                        >
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>EK</span>
                        </li>
                        <li
                            class="flex align-items-center mb-1"
                            v-if="data.sellPriceChange"
                        >
                            <i
                                v-if="(priceOnRequestOptions || []).some((item) => item.value.startsWith('Preis auf Anfrage') && item.id === data.weclappArticle?.customAttributes?.article_priceoff_noorder)"
                                class="pi pi-info-circle text-red-500 mr-2"
                                v-tooltip.top="$t('messages.sellPriceWillNotBeUpdatedDueToPriceOnRequestSwitch')"
                            ></i>
                            <i
                                v-else
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>VK</span>
                        </li>
                        <li
                            class="flex align-items-center mb-1"
                            v-if="data.listPriceChange"
                        >
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>UVP</span>
                        </li>
                    </ul>
                </div>
            </template></Column
        >
        <Column
            header="Änderungssatz (Daten)"
            style="max-width: 16rem"
            field="changeStatusDates"
            :filterMatchModeOptions="[{label: 'Equals', value: teltecChangeStatusFilter}]"
            v-if="!isIrregularFilterActive"
        >
            <template #filter="{filterModel, filterCallback}">
                <p-multiselect
                    v-model="filterModel.value"
                    :options="[
                                    {
                                        value: -2,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 1,
                                        label: 'UVP Datum'
                                    },
                                    {
                                        value: 2,
                                        label: 'EK Datum'
                                    },
                                    {
                                        value: 32,
                                        label: 'Datum im letzten EK (Sekundäre BZQ)'
                                    }
                                   
                                ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-xs"
                    :showClear="true"
                >
                </p-multiselect> </template
            ><template #body="{data}">
                <div class="text-sm">
                    <ul class="list-none changeset p-0 m-0 flex-grow-1">
                        <li
                            class="flex align-items-center mb-1"
                            v-if="data.costPriceDateChange"
                        >
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>EK Datum</span>
                        </li>
                        <li
                            class="flex align-items-center"
                            v-if="data.listPriceDateChange"
                        >
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>UVP Datum</span>
                        </li>
                        <li
                            class="flex align-items-center"
                            v-if="data.status === 32"
                        >
                            <i
                                class="pi pi-check-circle text-green-500 mr-2"
                            ></i>
                            <span>Datum im letzten EK</span>
                        </li>
                    </ul>
                </div>
            </template></Column
        >
        <Column
            style="max-width: 16rem"
            field="status"
            :header="$t('labels.status')"
            sortable
            :filterMatchModeOptions="[{label: 'Equals', value: teltecStatusFilter}]"
            v-if="isIrregularFilterActive"
        >
            <template #filter="{filterModel, filterCallback}">
                <p-multiselect
                    v-model="filterModel.value"
                    :options="[
                                    {
                                        value: 10002,
                                        label: 'SKU in weclapp als \'nicht in PL\' gekennzeichnet'
                                    },
                                    {
                                        value: 10003,
                                        label: 'SKU in weclapp inaktiv, in PL vorhanden -> wieder aufgetaucht, früher EOL, jetzt offensichtlich nicht mehr'
                                    },
                                    {
                                        value: 10004,
                                        label: 'Fehler in PL'
                                    },
                                    {
                                        value: 10005,
                                        label: 'Bestehender EK in der Zukunft'
                                    },
                                    {
                                        value: 10006,
                                        label: 'Bestehender UVP in der Zukunft'
                                    },
                                    {
                                        value: 1001,
                                        label: 'In PL nicht vorhanden'
                                    },
                                    {
                                        value: 1002,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und mehrere BZQ vorhanden'
                                    },
                                    {
                                        value: 1003,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden, ohne Lagerbestand'
                                    },
                                    {
                                        value: 1004,
                                        label: 'In PL nicht vorhanden, ist primäre BZQ und nur eine BZQ vorhanden und Lagerbestand vorhanden'
                                    }
                                   
                                ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-xs"
                    :showClear="true"
                >
                    <template #option="{option}"
                        >(Status {{option.value}}) {{option.label}}
                    </template>
                </p-multiselect> </template
            ><template #body="{data}">
                <div
                    class="font-medium text-lg mb-2 text-primary"
                    v-if="getStatusTooltip(data.status)"
                >
                    {{data.status}}

                    <Tag
                        v-if="[1001, 1002, 1003, 1004].includes(data.status)"
                        severity="warning"
                    >
                        <span>EOL</span>
                    </Tag>
                </div>
                <div class="text-sm" style="width: 70%">
                    {{getStatusTooltip(data.status)}}
                </div>
            </template></Column
        >
        <Column
            header="Online"
            field="onlineStatus"
            :filterMatchModeOptions="[{label: 'Equals', value: articleOnlineStatusFilter}]"
            ><template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                /> </template
            ><template #body="{data}">
                <div class="text-sm">
                    <i
                        v-if="data.onlineStatus"
                        class="pi pi-check-circle text-green-500 mr-2"
                    ></i>
                    <i v-else class="pi pi-times-circle text-red-500 mr-2"></i>
                </div> </template
        ></Column>

        <Column header="Ek PL" v-if="!isIrregularFilterActive"
            ><template #body="{data}">
                <div v-if="data.extractedData?.costPrice" class="text-sm">
                    {{formatter.format(data.extractedData.costPrice)}}
                </div>
            </template>
        </Column>

        <Column header="Ek Wcp" sortable field="calculatedCostPrice"
            ><template #body="{data}">
                <div class="text-sm">
                    {{formatter.format(data.calculatedCostPrice)}}
                </div>
            </template></Column
        >

        <Column
            header="Ek Diff"
            v-if="!isIrregularFilterActive"
            sortable
            style="min-width: 6rem"
            field="costPriceDifferenceInPercentage"
            :filterMatchModeOptions="[{label: 'Equals', value: percentageFilter}]"
            ><template #filter="{filterModel, filterCallback}">
                <p-dropdown
                    v-model="filterModel.value"
                    :options="[
                                    {
                                        value: 0,
                                        label: 'Keine Änderung'
                                    },
                                    {
                                        value: 1,
                                        label: 'Alle günstiger'
                                    },
                                    {
                                        value: 2,
                                        label: 'Alle teurer'
                                    },
                                    {
                                        value: -15,
                                        label: '> -15%'
                                    },
                                    {
                                        value: -5,
                                        label: '> -5%'
                                    },
                                    {
                                        value: -4,
                                        label: '<= -5%'
                                    },
                                    {
                                        value: 4,
                                        label: '<= 5%'
                                    },
                                    {
                                        value: 5,
                                        label: '> 5%'
                                    },
                                    {
                                        value: 15,
                                        label: '> 15%'
                                    }
                                   
                                ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    inputClass="p-column-filter text-xs"
                >
                </p-dropdown> </template
            ><template #body="{data}">
                <Tag
                    :severity="(data.costPriceDifferenceInPercentage > 0) ? 'danger' : (data.costPriceDifferenceInPercentage < 0 ? 'success' : '')"
                >
                    <span
                        >{{Math.abs(data.costPriceDifferenceInPercentage).toFixed(2)}}
                        %</span
                    >
                </Tag>
            </template></Column
        >

        <Column
            header="UVP PL"
            v-if="!isIrregularFilterActive"
            style="min-width: 7rem"
            ><template #body="{data}">
                <div v-if="data.extractedData?.listPrice" class="text-sm">
                    <span
                        >{{formatter.format(data.extractedData.listPrice)}}</span
                    >
                    <i
                        v-if="data.extractedData.listPriceCalculationFactor"
                        class="pi pi-calculator ml-1"
                        v-tooltip.top="$t('messages.listPriceWasCalculatedFromCostPrice', {factor : liteFormatter.format(data.extractedData.listPriceCalculationFactor) })"
                    ></i>
                </div> </template
        ></Column>

        <Column header="UVP Wcp" sortable field="calculatedListPrice"
            ><template #body="{data}">
                <div class="text-sm">
                    {{formatter.format(data.calculatedListPrice)}}
                </div>
            </template></Column
        >

        <Column
            header="UVP Diff"
            v-if="!isIrregularFilterActive"
            style="min-width: 6rem"
            sortable
            field="listPriceDifferenceInPercentage"
            :filterMatchModeOptions="[{label: 'Equals', value: percentageFilter}]"
            ><template #filter="{filterModel, filterCallback}">
                <p-dropdown
                    v-model="filterModel.value"
                    :options="[
                                {
                                    value: 0,
                                    label: 'Keine Änderung'
                                },
                                {
                                    value: 1,
                                    label: 'Alle günstiger'
                                },
                                {
                                    value: 2,
                                    label: 'Alle teurer'
                                },
                                {
                                    value: -15,
                                    label: '> -15%'
                                },
                                {
                                    value: -5,
                                    label: '> -5%'
                                },
                                {
                                    value: -4,
                                    label: '<= -5%'
                                },
                                {
                                    value: 4,
                                    label: '<= 5%'
                                },
                                {
                                    value: 5,
                                    label: '> 5%'
                                },
                                {
                                    value: 15,
                                    label: '> 15%'
                                }
                               
                            ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    inputClass="p-column-filter text-xs"
                >
                </p-dropdown>
            </template>
            <template #body="{data}">
                <Tag
                    :severity="(data.listPriceDifferenceInPercentage > 0) ? 'danger' : (data.listPriceDifferenceInPercentage < 0 ? 'success' : '')"
                >
                    <span
                        >{{
                        Math.abs(data.listPriceDifferenceInPercentage).toFixed(2)
                        }} %</span
                    >
                </Tag>
            </template></Column
        >

        <Column header="VK Wcp" style="min-width: 6rem"
            ><template #body="{data}">
                <div class="text-sm">
                    {{formatter.format(data.calculatedSellPrice)}}
                </div>
                <Tag
                    v-if="data.calculatedSellPrice > data.calculatedListPrice"
                    severity="danger"
                >
                    <span>VK > UVP</span>
                </Tag>
            </template></Column
        >

        <Column
            header="WBT"
            style="min-width: 6rem"
            v-if="!isIrregularFilterActive"
            ><template #body="{data}">
                <div
                    v-if="data.extractedData?.procurementLeadDays !== data.weclappArticle?.procurementLeadDays"
                >
                    <i
                        class="pi pi-exclamation-triangle text-red-500 text-xl mb-1"
                    ></i>
                </div>
                <div
                    v-if="data.extractedData?.procurementLeadDays"
                    class="text-sm"
                >
                    PL: {{data.extractedData.procurementLeadDays}}
                </div>
                <div class="text-sm mt-1">
                    Wcp: {{data.weclappArticle?.procurementLeadDays}}
                </div>
            </template></Column
        >
        <Column
            field="trailingDiscount"
            header="NSR"
            v-if="!isIrregularFilterActive"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            ><template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                /> </template
            ><template #body="{data}">
                <div v-if="data.trailingDiscount" class="text-sm">
                    {{formatter.format(data.extractedData.nsr)}}
                </div>
            </template></Column
        >

        <Column
            v-if="!isIrregularFilterActive"
            field="isProcessed"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            :header="$t('labels.processedBy')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <div class="text-sm font-semibold">{{data.processedBy}}</div>
                <div class="text-xs">{{data.processedAt}}</div>
            </template>
        </Column>
        <Column
            v-if="!isIrregularFilterActive"
            field="hasError"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            :header="$t('labels.error')"
            style="max-width: 10rem; word-wrap: break-word"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <div class="text-sm">
                    {{stripTags(data.weclappError, 100, '...')}}
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
