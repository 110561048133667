import AutoComplete from 'primevue/autocomplete';
import {getAll} from '@/services/metadata';
import {onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';

export default {
    emits: ['data-entered'],
    components: {
        AutoComplete
    },
    setup(props: any, context: any) {
        const suppliers = ref([]);
        const filteredSuppliers = ref([]);
        const toast = useToast();
        const chosenSupplier = ref();

        onMounted(() => {
            getAll(['supplier'])
                .then((data: any) => {
                    suppliers.value = (data.data?.supplier || []).map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                        }) => {
                            return {
                                label:
                                    item.name +
                                    ' [' +
                                    item.supplierNumber +
                                    ']',
                                value: item.supplierNumber
                            };
                        }
                    );
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });
        });

        const searchSuppliers = (event: any) => {
            filteredSuppliers.value = suppliers.value.filter(
                (item) =>
                    !event.query ||
                    item.label
                        .toLowerCase()
                        .indexOf(event.query.toLowerCase()) !== -1
            );
        };

        const setDropdownValue = (event: any) => {
            context.emit(
                'data-entered',

                {
                    supplier: suppliers.value.find(
                        (item: any) => item.value === event?.value?.value
                    )
                }
            );
        };

        return {
            setDropdownValue,
            filteredSuppliers,
            chosenSupplier,
            searchSuppliers
        };
    }
};
