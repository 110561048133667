import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-060c3843"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "flex align-items-center mb-3 mt-3" }
const _hoisted_3 = { class: "flex-none flex mr-5" }
const _hoisted_4 = { class: "flex flex-grow-1" }
const _hoisted_5 = {
  key: 0,
  class: "text-center w-full"
}
const _hoisted_6 = {
  key: 1,
  class: "text-center w-full"
}
const _hoisted_7 = {
  key: 0,
  class: "flex-none flex ml-5"
}
const _hoisted_8 = {
  key: 1,
  class: "flex-none flex ml-5"
}
const _hoisted_9 = { class: "flex justify-content-end" }
const _hoisted_10 = {
  key: 2,
  class: "flex-none flex ml-5"
}
const _hoisted_11 = { class: "flex justify-content-end" }
const _hoisted_12 = { class: "product-item" }
const _hoisted_13 = { class: "image-container" }
const _hoisted_14 = { class: "product-list-detail" }
const _hoisted_15 = { class: "text-sm" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "text-xs" }
const _hoisted_18 = { class: "product-list-action" }
const _hoisted_19 = { class: "button-container" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "flex justify-content-between mt-3" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_image = _resolveComponent("p-image")
  const _component_OrderList = _resolveComponent("OrderList")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_AddDialog = _resolveComponent("AddDialog")
  const _component_UseAsCoverDialog = _resolveComponent("UseAsCoverDialog")
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")
  const _directive_badge = _resolveDirective("badge")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_p_button, {
            class: "text-sm",
            icon: "pi pi-plus",
            label: _ctx.$t('buttons.addNewGeneric'),
            severity: "success",
            onClick: _ctx.showAddDialog
          }, null, 8, ["label", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _mergeProps(_ctx.getRootProps(), { class: "import-wizard-container font-medium text-sm flex-grow-1 flex" }), [
            _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.getInputProps())), null, 16),
            (_ctx.acceptedFiles.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.dropZoneText), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
          ], 16)
        ]),
        (_ctx.showUploadConfirmButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_p_button, {
                class: "p-button-sm p-button-success",
                label: _ctx.$t('buttons.confirm'),
                onClick: _ctx.handleUpload
              }, null, 8, ["label", "onClick"]),
              _createVNode(_component_p_button, {
                style: {"margin-left":"5px"},
                class: "p-button-sm p-button-danger",
                label: _ctx.$t('buttons.cancel'),
                onClick: _cache[1] || (_cache[1] = $event => (_ctx.acceptedFiles.length = 0))
              }, null, 8, ["label"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.showMultiDeleteButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_p_button, {
                  class: "p-button-sm p-button-danger",
                  label: _ctx.$t('labels.deleteSelected'),
                  icon: "pi pi-trash",
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.showDeleteDialog(_ctx.selection)))
                }, null, 8, ["label"])
              ])
            ]))
          : (_ctx.showDeleteAllButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_p_button, {
                    class: "p-button-sm p-button-danger",
                    label: _ctx.$t('labels.deleteAll'),
                    icon: "pi pi-trash",
                    onClick: _cache[3] || (_cache[3] = $event => (_ctx.showDeleteDialog(_ctx.images)))
                  }, null, 8, ["label"])
                ])
              ]))
            : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 33.5rem)"} }, {
        default: _withCtx(() => [
          _createVNode(_component_OrderList, {
            modelValue: _ctx.images,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.images) = $event)),
            dataKey: "id",
            listStyle: 'min-height: ' + (_ctx.images?.length * 13.7) +'rem;',
            selection: _ctx.selection,
            "onUpdate:selection": _cache[5] || (_cache[5] = $event => ((_ctx.selection) = $event))
          }, {
            item: _withCtx((slotProps) => [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  (_ctx.productDetails?.shopware?.coverId === slotProps.item.productMediaId)
                    ? _withDirectives((_openBlock(), _createBlock(_component_p_image, {
                        key: 0,
                        width: "180",
                        src: slotProps.item.url,
                        alt: slotProps.item.fileName,
                        imageStyle: "box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);",
                        preview: ""
                      }, null, 8, ["src", "alt"])), [
                        [_directive_badge, 'Cover']
                      ])
                    : (_openBlock(), _createBlock(_component_p_image, {
                        key: 1,
                        width: "180",
                        src: slotProps.item.url,
                        alt: slotProps.item.fileName,
                        imageStyle: "box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);",
                        preview: ""
                      }, null, 8, ["src", "alt"]))
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createTextVNode(_toDisplayString(slotProps.item.fileName) + "." + _toDisplayString(slotProps.item.fileExtension) + " ", 1),
                    _withDirectives(_createElementVNode("i", {
                      class: "pi pi-copy text-sm ml-1",
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.copyToClipboard(slotProps.item.fileName, $event))
                    }, null, 8, _hoisted_16), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.copyFilename'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_17, _toDisplayString(slotProps.item.fileSize) + " bytes ", 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _withDirectives(_createElementVNode("i", {
                      class: "pi pi-trash text-sm mr-2 text-red-600",
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.showDeleteDialog([slotProps.item]))
                    }, null, 8, _hoisted_20), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.deleteImage'),
                        void 0,
                        { top: true }
                      ]
                    ]),
                    (_ctx.productDetails?.shopware?.coverId !== slotProps.item.productMediaId)
                      ? _withDirectives((_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: "pi pi-star text-sm mr-2",
                          style: {"color":"rgb(255, 193, 7)","cursor":"pointer"},
                          onClick: $event => (_ctx.showUseAsCoverDialog([slotProps.item]))
                        }, null, 8, _hoisted_21)), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.setAsCover'),
                            void 0,
                            { top: true }
                          ]
                        ])
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "listStyle", "selection"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_22, [
        _createVNode(_component_p_button, {
          disabled: !_ctx.imagesReordered,
          severity: "danger",
          onClick: _cache[6] || (_cache[6] = $event => (_ctx.images = [].concat(_ctx.productDetails?.shopware?.images || []))),
          textContent: _toDisplayString(_ctx.$t('labels.revert'))
        }, null, 8, ["disabled", "textContent"]),
        _createVNode(_component_p_button, {
          disabled: !_ctx.imagesReordered,
          severity: "success",
          onClick: _ctx.reorderImages,
          textContent: _toDisplayString(_ctx.$t('labels.save'))
        }, null, 8, ["disabled", "onClick", "textContent"])
      ])
    ]),
    _createVNode(_component_AddDialog, {
      displayResponsive: _ctx.displayAddDialog,
      productDetails: _ctx.productDetails,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "productDetails", "onCloseDialog"]),
    _createVNode(_component_UseAsCoverDialog, {
      displayResponsive: _ctx.displayUseAsCoverDialog,
      productDetails: _ctx.productDetails,
      selectedImage: _ctx.selectedImages.length > 0 ? _ctx.selectedImages[0] : null,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "productDetails", "selectedImage", "onCloseDialog"]),
    _createVNode(_component_DeleteDialog, {
      displayResponsive: _ctx.displayDeleteDialog,
      productDetails: _ctx.productDetails,
      selectedImages: _ctx.selectedImages,
      onCloseDialog: _ctx.closeDialogListener
    }, null, 8, ["displayResponsive", "productDetails", "selectedImages", "onCloseDialog"])
  ], 64))
}