<p-dialog
    :header="$t('labels.saveFilterAs')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '25vw'}"
    @hide="closeDialog"
    :modal="true"
    class="text-sm"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.name') }} </label>
                <InputText
                    autocomplete="off"
                    v-model="v$.name.$model"
                    :class="{'p-invalid':v$.name.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.name.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.public') }} </label>

                <app-checkbox
                    v-model="v$.isPublic.$model"
                    binary
                    class="ml-2 mb-1"
                />
            </div>
        </div>
        <div class="flex justify-content-end">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</p-dialog>
