import {computed, onMounted, ref, toRefs} from 'vue';
import {getSingle} from '@/services/product-accessories';
import {getAll as getAllMetadata} from '@/services/metadata';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Dialog from 'primevue/dialog';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';
import ProductList from '@/components/grid/products/list.vue';
import ReplaceAccessory from '@/components/dialog/products/replace-accessory.vue';
import {checkIfArraysAreEqual, stripTagsAndTruncate} from '@/utils/helpers';
import Badge from 'primevue/badge';
import TieredMenu from 'primevue/tieredmenu';
import MultiSelect from 'primevue/multiselect';

import {i18n} from '@/utils/i18n';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {bulkDelete} from '@/services/product-accessories';
import store from '@/store';
import Tag from 'primevue/tag';
import TriStateCheckbox from 'primevue/tristatecheckbox';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Toolbar,
        'p-button': Button,
        SplitButton,
        BulkAddAccessories,
        PrimeDialog: Dialog,
        ProductList,
        ReplaceAccessory,
        LoadingPlugin,
        Badge,
        TieredMenu,
        Tag,
        'p-multiselect': MultiSelect,
        TriStateCheckbox
    },
    props: {
        productDetails: Object,
        standAlone: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any) {
        const toast = useToast();

        const {productDetails, standAlone} = toRefs(props);

        const bulkMenu = ref();

        const productNumber = computed(
            () => productDetails.value?.weclapp?.articleNumber
        );

        onMounted(() => {
            getAllMetadata(['productTags']).then((data) => {
                availableProductTags.value = data.data?.productTags || [];
            });

            if (
                localStorage.getItem('product-accessories-list-state-session')
            ) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        });

        const dt = ref();
        const tableState = ref(null);
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const showTransferDialog = ref(false);
        const showDeleteDialog = ref(false);
        const showReplaceDialog = ref(false);
        const oldAccessory = ref();
        const availableProductTags = ref([]);

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);

        const loadLazyData = () => {
            loading.value = true;

            getSingle(productNumber.value, lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            productCategory: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            description: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            tags: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            availableStock: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const bulkItems = ref([
            {
                label: i18n.global.t('labels.deleteSelected') + '...',
                icon: 'pi pi-trash',
                command: () => {
                    if (
                        !mergedSelection.value ||
                        mergedSelection.value.length < 1
                    ) {
                        toast.error(
                            i18n.global.t(
                                'messages.pleaseSelectAtLeastOneProduct'
                            )
                        );
                        return;
                    }
                    showDeleteDialog.value = true;
                }
            },
            {
                label: i18n.global.t('labels.transferSelected'),
                icon: 'pi pi-reply',
                command: () => {
                    if (
                        !mergedSelection.value ||
                        mergedSelection.value.length < 1
                    ) {
                        toast.error(
                            i18n.global.t(
                                'messages.pleaseSelectAtLeastOneProduct'
                            )
                        );
                        return;
                    }
                    showTransferDialog.value = true;
                }
            }
        ]);

        const closeTransferDialog = (refreshGrid: boolean = false) => {
            showTransferDialog.value = false;
            if (refreshGrid) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const openReplaceDialog = (data: any) => {
            oldAccessory.value = data;
            showReplaceDialog.value = true;
        };

        const closeReplaceDialog = (refreshGrid: boolean = false) => {
            oldAccessory.value = null;
            showReplaceDialog.value = false;
            if (refreshGrid) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const removeArticleAccessories = async () => {
            loading.value = true;
            try {
                await bulkDelete(productNumber.value, mergedSelection.value);
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully'),
                    {
                        timeout: 500
                    }
                );
                showDeleteDialog.value = false;
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                loading.value = false;
            }
        };

        const editAccessoriesPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('product-accessories-edit') !== -1
            );
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const onStateRestore = (event: any) => {
            selectedProducts.value = [];
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            if (!checkIfArraysAreEqual(stateFilters, filtersDefinitionKeys)) {
                localStorage.removeItem(
                    'product-accessories-list-state-session'
                );
                tableState.value = null;
                filters.value = Object.assign({}, filtersDefinition);
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'articleNumber',
                sortOrder: event.sortOrder || 1,
                filters: event.filters
            };

            loadLazyData();
        };

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        const scrollableHeight = computed(() => {
            return 'calc(100vh - ' + (standAlone.value ? 23 : 29) + 'rem)';
        });

        return {
            productNumber,
            standAlone,
            scrollableHeight,
            loading,
            filters,
            expandedRows,
            dt,
            productDetails,
            onFilter,
            onPage,
            onSort,
            loadLazyData,
            closeTransferDialog,
            openReplaceDialog,
            closeReplaceDialog,
            totalRecords,
            products,
            selectedProducts,
            mergedSelection,
            bulkItems,
            showTransferDialog,
            showDeleteDialog,
            showReplaceDialog,
            oldAccessory,
            removeArticleAccessories,
            editAccessoriesPermissionAvailable,
            stripTagsAndTruncate,
            expandRow,
            onStateRestore,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            bulkMenu,
            bulkMenuToggle,
            availableProductTags
        };
    }
};
