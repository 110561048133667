<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <template v-for="availablePlatform in platforms">
            <template v-for="platform in selectedEnvironments">
                <div v-if="platform === availablePlatform.value" class="col">
                    <component
                        :is="singleEnvironment ? 'slot' : 'p-fieldset'"
                        :legend="availablePlatform.label"
                    >
                        <p-fieldset legend="Webshop">
                            <div class="formgrid grid p-fluid">
                                <div class="col field">
                                    <label
                                        >{{
                                        $t('labels.productHome.availableInShop')
                                        }}
                                    </label>
                                    <p-checkbox
                                        v-model="v$['availableInShop' + '-' + platform].$model"
                                        :disabled="v$['activeInShop' + '-' + platform].$model"
                                        :binary="true"
                                        class="ml-2 mb-1"
                                    />
                                </div>
                                <div class="col field">
                                    <label
                                        >{{
                                        $t('labels.productHome.activeInShop') }}
                                    </label>
                                    <p-checkbox
                                        v-model="v$['activeInShop' + '-' + platform].$model"
                                        :binary="true"
                                        class="ml-2 mb-1"
                                        @input="onActiveInShopChange(platform, $event)"
                                    />
                                </div>
                            </div>
                            <template
                                v-if="v$['activeInShop' + '-' + platform].$model || v$['availableInShop' + '-' + platform].$model"
                            >
                                <div
                                    class="formgrid grid p-fluid"
                                    v-if="existingShopwareData"
                                >
                                    <div class="col field">
                                        <label
                                            >{{
                                            $t('labels.productAdd.copyAccessories',
                                            {count:existingShopwareData.accessories.length
                                            }) }}</label
                                        >
                                        <p-checkbox
                                            v-model="v$['copyShopAccessories' + '-' + platform].$model"
                                            binary
                                            :disabled="existingShopwareData.accessories.length < 1"
                                            class="ml-2 mb-1"
                                        />
                                    </div>
                                    <div class="col field">
                                        <label
                                            >{{
                                            $t('labels.productAdd.copyImages',
                                            {count:existingShopwareData.images.length
                                            }) }}</label
                                        >
                                        <p-checkbox
                                            v-model="v$['copyShopImages' + '-' + platform].$model"
                                            binary
                                            :disabled="existingShopwareData.images.length < 1"
                                            class="ml-2 mb-1"
                                        />
                                    </div>
                                    <div class="col field">
                                        <label
                                            >{{
                                            $t('labels.productAdd.copyVideos',
                                            {count:existingShopwareData.videos.length
                                            }) }}</label
                                        >
                                        <p-checkbox
                                            v-model="v$['copyShopVideos' + '-' + platform].$model"
                                            binary
                                            :disabled="existingShopwareData.videos.length < 1"
                                            class="ml-2 mb-1"
                                        />
                                    </div>
                                    <div class="col field">
                                        <label
                                            >{{
                                            $t('labels.productAdd.copyLinks',
                                            {count:existingShopwareData.links.length
                                            }) }}</label
                                        >
                                        <p-checkbox
                                            v-model="v$['copyShopLinks' + '-' + platform].$model"
                                            binary
                                            :disabled="existingShopwareData.links.length < 1"
                                            class="ml-2 mb-1"
                                        />
                                    </div>
                                </div>
                                <div class="formgrid grid p-fluid">
                                    <div class="col field">
                                        <label
                                            >{{
                                            $t('labels.productDescription.webshopText')
                                            }}
                                        </label>
                                        <span class="p-input-icon-right">
                                            <tiny
                                                api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                                                v-model="v$['webshopText' + '-' + platform].$model"
                                                :init="{
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }"
                                            ></tiny>
                                            <small
                                                v-if="submitted"
                                                style="display: block"
                                                v-for="error in v$['webshopText' + '-' + platform].$silentErrors"
                                                class="p-error"
                                                :key="error"
                                            >
                                                {{ error.$message }}
                                            </small>
                                        </span>
                                    </div>
                                </div>
                            </template>
                        </p-fieldset>
                        <p-fieldset
                            :legend="$t('labels.description')"
                            class="mt-5"
                        >
                            <div class="formgrid grid p-fluid">
                                <div class="col field">
                                    <label
                                        >{{
                                        $t('labels.productDescription.shortDescription')
                                        }}
                                    </label>
                                    <span class="p-input-icon-right">
                                        <InputText
                                            autocomplete="off"
                                            :maxLength="100"
                                            v-model="v$['shortDescription' + '-' + platform].$model"
                                            :class="{'p-invalid':v$['shortDescription' + '-' + platform].$invalid && submitted}"
                                        />
                                        <small
                                            v-if="submitted"
                                            style="display: block"
                                            v-for="error in v$['shortDescription' + '-' + platform].$silentErrors"
                                            class="p-error"
                                            :key="error"
                                        >
                                            {{ error.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>

                            <div class="formgrid grid p-fluid">
                                <div class="col field">
                                    <label
                                        >{{
                                        $t('labels.productDescription.longDescription')
                                        }}
                                    </label>
                                    <span class="p-input-icon-right">
                                        <tiny
                                            api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                                            v-model="v$['longDescription' + '-' + platform].$model"
                                            :init="{
                                
                                menubar: false,
                                plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                                toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                                browser_spellcheck: true,
                                autoresize_bottom_margin: 50,
                                min_height: 500
                            }"
                                        ></tiny>
                                        <small
                                            v-if="submitted"
                                            style="display: block"
                                            v-for="error in v$['longDescription' + '-' + platform].$silentErrors"
                                            class="p-error"
                                            :key="error"
                                        >
                                            {{ error.$message }}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </p-fieldset>
                    </component>
                </div>
            </template>
        </template>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" type="submit" v-text="$t('buttons.next')">
        </p-button>
    </div>
</form>
