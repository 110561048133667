<div class="mt-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <ScrollPanel style="height: calc(100vh - 29rem)">
            <p-fieldset
                :legend="$t('labels.productHome.masterData')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label>{{ $t('labels.articleNumber') }} </label>
                        <InputText
                            disabled
                            :placeholder="$t('labels.articleNumber')"
                            autocomplete="off"
                            v-model="v$.productNumber.$model"
                            :class="{'tt-form-input': true, 'p-invalid':v$.productNumber.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.productNumber.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.manufacturer') }} </label>
                        <p-dropdown
                            :filter="true"
                            :showClear="true"
                            v-model="v$.manufacturer.$model"
                            :options="manufacturerOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('manufacturer', $event)"
                            :class="{'p-invalid':v$.manufacturer.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.manufacturer.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.ean') }}</label>
                        <InputText
                            :placeholder="$t('labels.ean')"
                            autocomplete="off"
                            v-model="v$.ean.$model"
                            :class="{'p-invalid':v$.ean.$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label>{{ $t('labels.name') }} </label>
                        <InputText
                            :placeholder="$t('labels.name')"
                            autocomplete="off"
                            v-model="v$.productName.$model"
                            :class="{'p-invalid':v$.productName.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.productName.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.customsTariffNumber') }}
                        </label>

                        <p-dropdown
                            :filter="true"
                            :showClear="true"
                            v-model="v$.customsTariffNumber.$model"
                            :options="customsTariffNumberOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('customsTariffNumber', $event)"
                            :class="{'p-invalid':v$.customsTariffNumber.$invalid && submitted}"
                        />
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.mpn') }} </label>
                        <InputText
                            :placeholder="$t('labels.mpn')"
                            autocomplete="off"
                            v-model="v$.mpn.$model"
                            :class="{'p-invalid':v$.mpn.$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label>{{ $t('labels.productAdd.matchCode') }} </label>
                        <InputText
                            icon="align-justify"
                            type="text"
                            :placeholder="$t('labels.productAdd.matchCode')"
                            autocomplete="off"
                            v-model="v$.matchCode.$model"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.countryOfOrigin') }}
                        </label>
                        <p-dropdown
                            :filter="true"
                            :showClear="true"
                            v-model="v$.countryOfOrigin.$model"
                            :options="countryOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('countryOfOrigin', $event)"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.articleWeclappCategory') }}
                        </label>
                        <p-dropdown
                            :filter="true"
                            v-model="v$.articleCategory.$model"
                            :options="articleCategoryOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('articleCategory', $event)"
                            :showClear="true"
                        >
                        </p-dropdown>
                    </div>
                </div>

                <Divider
                    v-if="tagsPermissionAvailable"
                    align="left"
                    type="dashed"
                >
                </Divider>
                <div
                    v-if="tagsPermissionAvailable"
                    class="formgrid grid p-fluid mb-2"
                >
                    <div class="field col">
                        <label class="font-semibold"
                            >{{ $t('labels.tags') }}
                        </label>

                        <Chips
                            class="w-full"
                            v-model="v$.tags.$model"
                            :allowDuplicate="false"
                            :placeholder="$t('labels.metatagsSeparatorInfo')"
                            separator=","
                        />
                    </div>
                </div>
            </p-fieldset>

            <p-fieldset
                :legend="$t('labels.productHome.ecommerceSettings')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.availableInShop') }}
                        </label>
                        <app-checkbox
                            v-model="v$.availableInShop.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                            :disabled="alreadyAvailableInShop"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.activeInShop') }}
                        </label>

                        <app-checkbox
                            v-model="v$.activeInShop.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col"></div>
                </div>
            </p-fieldset>
            <p-fieldset
                :legend="$t('labels.productHome.weightAndDimensions')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.grossWeight') }}
                        </label>
                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.articleGrossWeight.$model"
                            :class="{'p-invalid':v$.articleGrossWeight.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleGrossWeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.netWeight') }} </label>
                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.articleNetWeight.$model"
                            :class="{'p-invalid':v$.articleNetWeight.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleNetWeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.length') }} </label>
                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            :maxFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.articleLength.$model"
                            :class="{'p-invalid':v$.articleLength.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleLength.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.width') }} </label>
                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            :maxFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.articleWidth.$model"
                            :class="{'p-invalid':v$.articleWidth.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleWidth.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.height') }} </label>
                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            :maxFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.articleHeight.$model"
                            :class="{'p-invalid':v$.articleHeight.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.articleHeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.purchaseUnit') }}
                        </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            autocomplete="off"
                            v-model="v$.purchaseUnit.$model"
                            :class="{'p-invalid':v$.purchaseUnit.$invalid && submitted}"
                        />

                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.purchaseUnit.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.dimensionUnit') }}
                        </label>

                        <p-dropdown
                            :filter="true"
                            v-model="v$.dimensionUnit.$model"
                            :options="shopwareUnitOptions"
                            optionLabel="name"
                            optionValue="id"
                            @change="setDropdownValue('dimensionUnit', $event)"
                        >
                        </p-dropdown>
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.referenceUnit') }}
                        </label>

                        <InputNumber
                            mode="decimal"
                            :locale="locale"
                            :minFractionDigits="1"
                            v-model="v$.referenceUnit.$model"
                            :class="{'p-invalid':v$.referenceUnit.$invalid && submitted}"
                            autocomplete="off"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.referenceUnit.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
            </p-fieldset>
            <p-fieldset
                :legend="$t('labels.productHome.salesManagement')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.sellFromDate') }}
                        </label>

                        <p-calendar
                            :placeholder="$t('labels.productHome.sellFromDate')"
                            autocomplete="off"
                            dateFormat="dd.mm.yy"
                            v-model="v$.sellFromDate.$model"
                            :class="{'tt-form-input': true, 'p-invalid':v$.sellFromDate.$invalid && submitted}"
                        />
                    </div>
                    <div class="field col"></div>
                    <div class="field col"></div>
                </div>
            </p-fieldset>
            <p-fieldset
                :legend="$t('labels.customAttributes')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="col field">
                        <label>{{ $t('labels.productHome.memo') }} </label>
                        <p-textarea
                            rows="4"
                            autocomplete="off"
                            v-model="v$.article_memo.$model"
                            :class="{'p-invalid':v$.article_memo.$invalid && submitted}"
                        />

                        <label
                            >{{ $t('labels.productHome.commentDeliveryNote') }}
                        </label>

                        <p-textarea
                            rows="4"
                            autocomplete="off"
                            v-model="v$.article_comment_delivery_note.$model"
                            :class="{'p-invalid':v$.article_comment_delivery_note.$invalid && submitted}"
                        />
                    </div>
                    <div class="col field">
                        <label>{{ $t('labels.productHome.eol') }} </label>

                        <p-calendar
                            autocomplete="off"
                            dateFormat="dd.mm.yy"
                            v-model="v$.art_eol_datum.$model"
                            :class="{'tt-form-input': true, 'p-invalid':v$.art_eol_datum.$invalid && submitted}"
                        />
                        <label
                            >{{ $t('labels.productHome.warrantyTime') }}
                        </label>

                        <p-dropdown
                            v-model="v$.article_garantiezeit.$model"
                            :options="warrantyTimeOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('article_garantiezeit', $event)"
                            :class="{'p-invalid':v$.article_garantiezeit.$invalid && submitted}"
                        />

                        <label
                            >{{ $t('labels.productHome.priceUponRequest') }}
                        </label>

                        <p-dropdown
                            v-model="v$.priceUponRequest.$model"
                            :options="priceUponRequestOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('priceUponRequest', $event)"
                            :class="{'p-invalid':v$.priceUponRequest.$invalid && submitted}"
                        />
                    </div>
                </div>
                <div class="grid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.serialNumberIntern') }}
                        </label>

                        <app-checkbox
                            v-model="v$.SN_AUTO_GENERATE.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.battg') }} </label>

                        <app-checkbox
                            v-model="v$.article_batteriegesetz_relevant.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.oversized') }} </label>

                        <app-checkbox
                            v-model="v$.article_ueberlaenge.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.sellOut') }} </label>

                        <app-checkbox
                            v-model="v$.article_sell_out.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.brandAddedValue') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_markenmehrwert.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
                <Divider align="left" type="dashed">
                    <span style="font-weight: bold"
                        >{{ $t('labels.productHome.characteristics') }}</span
                    >
                </Divider>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.weeeClasification') }}
                        </label>
                        <p-dropdown
                            v-model="v$.article_weee_relevant.$model"
                            :options="weeeOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('article_weee_relevant', $event)"
                            :class="{'p-invalid':v$.article_weee_relevant.$invalid && submitted}"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.gemaClasification') }}
                        </label>
                        <p-dropdown
                            v-model="v$.article_gema_relevant.$model"
                            :options="gemaOptions"
                            optionLabel="label"
                            optionValue="value"
                            @change="setDropdownValue('article_gema_relevant', $event)"
                            :class="{'p-invalid':v$.article_gema_relevant.$invalid && submitted}"
                        />
                    </div>
                </div>
                <Divider align="left" type="dashed">
                    <span style="font-weight: bold"
                        >{{ $t('labels.productHome.webshop') }}</span
                    >
                </Divider>
                <div class="formgrid grid p-fluid mb-2">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.metaTagsSEO') }}
                        </label>
                        <div class="flex">
                            <Chips
                                v-model="v$.article_metatags.$model"
                                :allowDuplicate="false"
                                :placeholder="$t('labels.metatagsSeparatorInfo')"
                                separator=","
                            />
                            <i
                                class="pi pi-copy ml-2"
                                style="font-size: 2.5rem; cursor: pointer"
                                @click="copyToClipboard(v$.article_metatags.$model, $event)"
                                v-tooltip.left="$t('labels.copyMetatagsToClipboard')"
                            ></i>
                        </div>
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label>{{ $t('labels.productHome.bulkGoods') }} </label>

                        <app-checkbox
                            v-model="v$.article_sperrgut.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.containsAerosols') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_flag_aerosole.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.dangerousGoods') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_flag_akku_groesser_100wh.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.dangerousGoodsSmall') }}
                        </label>

                        <app-checkbox
                            v-model="v$.article_flag_akku_kleiner_100wh.$model"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
                <Divider align="left" type="dashed">
                    <span style="font-weight: bold"
                        >{{ $t('labels.productHome.productCreation') }}</span
                    >
                </Divider>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label>{{ $t('labels.productHome.creator') }} </label>
                        <p-dropdown
                            :modelValue="productDetails?.weclapp?.customAttributes?.article_creator"
                            :options="userOptions"
                            :disabled="true"
                            optionLabel="label"
                            optionValue="value"
                        />
                    </div>
                </div>
            </p-fieldset>
            <p-fieldset
                :legend="$t('labels.salesOrder.disposition')"
                :toggleable="true"
                class="mb-2"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.procurementLeadDays') }}
                        </label>

                        <InputNumber
                            :locale="locale"
                            autocomplete="off"
                            showButtons
                            :min="0"
                            v-model="v$.procurementLeadDays.$model"
                            :class="{'p-invalid':v$.procurementLeadDays.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.procurementLeadDays.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                    <div class="col">
                        <label> {{ $t('labels.minimumStockQuantity') }} </label>
                        <InputNumber
                            :locale="locale"
                            v-model="v$.minimumStockQuantity.$model"
                            autocomplete="off"
                            class="mb-3"
                        />
                    </div>
                    <div class="field col">
                        <label> {{ $t('labels.targetStockQuantity') }} </label>
                        <InputNumber
                            :locale="locale"
                            autocomplete="off"
                            v-model="v$.targetStockQuantity.$model"
                            class="mb-3"
                        />
                    </div>
                </div>
            </p-fieldset>
            <p-fieldset
                :legend="$t('labels.productHome.preferences')"
                :toggleable="true"
            >
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.productType') }}
                        </label>
                        <p-dropdown
                            :modelValue="productDetails?.weclapp?.articleType"
                            :disabled="true"
                            :options="productTypeOptions"
                            optionLabel="label"
                            optionValue="value"
                            class="mb-3"
                        />
                    </div>
                    <div class="field col">
                        <label>{{ $t('labels.productHome.unit') }} </label>
                        <p-dropdown
                            :modelValue="productDetails?.weclapp?.unitId"
                            :disabled="true"
                            :options="unitOptions"
                            optionLabel="label"
                            optionValue="value"
                            class="mb-3"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.serialNumberExtern') }}
                        </label>
                        <app-checkbox
                            v-model="v$.serialNumberRequired.$model"
                            :disabled="true"
                            :binary="true"
                            class="ml-2 mb-1"
                        />
                    </div>
                </div>
                <div class="formgrid grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.productHome.salesChannel') }}
                        </label>

                        <p-dropdown
                            :modelValue="'NET1'"
                            :disabled="true"
                            :options="salesChannelOptions"
                            optionLabel="label"
                            optionValue="value"
                            class="mb-3"
                        />
                    </div>
                    <div class="field col-4">
                        <label>{{ $t('labels.productHome.currency') }} </label>

                        <p-dropdown
                            :modelValue="computedCurrency"
                            :disabled="true"
                            :options="currencyOptions"
                            optionLabel="label"
                            optionValue="value"
                            class="mb-3"
                        />
                    </div>
                    <div class="field col">
                        <label
                            >{{ $t('labels.productAdd.taxRateType') }}
                        </label>

                        <p-dropdown
                            :modelValue="productDetails?.weclapp?.taxRateType"
                            :disabled="true"
                            :options="taxRateTypeOptions"
                            optionLabel="label"
                            optionValue="value"
                            class="mb-3"
                        />
                    </div>
                </div>
            </p-fieldset>
        </ScrollPanel>
        <div class="mt-3 flex justify-content-end">
            <p-button
                severity="success"
                :disabled="savingInProgress"
                type="submit"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
            >
            </p-button>
        </div>
    </form>
</div>
<p-dialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showCollidingVersionDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    :closable="false"
    :modal="true"
>
    <div>
        <div>
            <h6>{{ $t('messages.collidingVersionProductEdit') }}</h6>
        </div>

        <div class="w-full mt-3 flex justify-content-between">
            <p-button @click="cancelSavingHandler" severity="danger">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="showCollidingVersionDialog = false;executeUpdate()"
                v-text="$t('buttons.saveAnyway')"
            >
            </p-button>
        </div>
    </div>
</p-dialog>
<ConfirmLeave
    :displayConfirmLeaveDialog="displayConfirmLeaveDialog"
    @choice-confirmed="onChoiceConfirmed"
></ConfirmLeave>
