import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const add = async (
    productNumber: string,
    url: string
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/products/' + productNumber + '/videos', {
        url
    });
};

export const remove = async (
    productNumber: string,
    id: string
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/products/' + productNumber + '/videos/' + id
    );
};
