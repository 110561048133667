import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-602938f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid p-fluid mb-3"
}
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { class: "grid p-fluid mb-3" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = {
  key: 0,
  class: "col field"
}
const _hoisted_7 = {
  key: 1,
  class: "grid p-fluid mb-3"
}
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "col field" }
const _hoisted_10 = { class: "grid p-fluid mb-3" }
const _hoisted_11 = { class: "col field" }
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { class: "flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.shipment.shippingCarrierAdditionalData', {carrierName: (_ctx.carrierType || 'ups').toUpperCase()}),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[11] || (_cache[11] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '960px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[10] || (_cache[10] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        (_ctx.carrierType === 'ups')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsDeliveryCosts')), 1),
                _createVNode(_component_p_dropdown, {
                  modelValue: _ctx.v$.deliveryCosts.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.deliveryCosts.$model) = $event)),
                  options: [{label: 'Versender (Teltec)', value: 0}, {label: 'Kunde/Belegadresse (Dropshipment)', value: 1}, {label: 'Lieferadresse', value: 2}],
                  optionLabel: "label",
                  optionValue: "value",
                  class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.deliveryCosts.$invalid && _ctx.submitted}]),
                  onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('deliveryCosts', $event)))
                }, null, 8, ["modelValue", "options", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.deliveryCosts.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsCustomerAccountNumber')), 1),
                _createVNode(_component_InputText, {
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsAccountNumber.$model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.upsAccountNumber.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsAccountNumber.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsAccountNumber.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsSignatureMandatory')), 1),
            _createVNode(_component_p_checkbox, {
              modelValue: _ctx.v$.upsSignature.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.upsSignature.$model) = $event)),
              binary: "",
              class: "ml-2 mb-1"
            }, null, 8, ["modelValue"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsSignature.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          (_ctx.carrierType === 'ups')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsAccessPointId')), 1),
                _createVNode(_component_InputText, {
                  icon: "align-justify",
                  type: "text",
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsAccessPointId.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.upsAccessPointId.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsAccessPointId.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsAccessPointId.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.carrierType === 'ups')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsShipmentDescription')), 1),
                _createVNode(_component_InputText, {
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsShipmentDescription.$model,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.upsShipmentDescription.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsShipmentDescription.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsShipmentDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsPackageDescription')), 1),
                _createVNode(_component_InputText, {
                  icon: "align-justify",
                  type: "text",
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsPackageDescription.$model,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.upsPackageDescription.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsPackageDescription.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsPackageDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.groupedShipments.length > 0 ?
                    'labels.shipment.groupedWeight' : 'labels.shipment.weight')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              maxFractionDigits: 3,
              autocomplete: "off",
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.packageWeight.$invalid && _ctx.submitted}]),
              modelValue: _ctx.v$.packageWeight.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$.packageWeight.$model) = $event))
            }, null, 8, ["locale", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.packageWeight.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.groupedShipments.length > 0 ?
                    'labels.shipment.groupedNumberOfLabels' :
                    'labels.shipment.numberOfLabels')), 1),
            _createVNode(_component_InputNumber, {
              locale: _ctx.locale,
              class: _normalizeClass([{'p-invalid':_ctx.v$.shippingLabelsCount.$invalid && _ctx.submitted}, "w-full"]),
              autocomplete: "off",
              modelValue: _ctx.v$.shippingLabelsCount.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.shippingLabelsCount.$model) = $event))
            }, null, 8, ["locale", "class", "modelValue"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shippingLabelsCount.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}