import {ref, onMounted} from 'vue';
import {getAll} from '@/services/tickets';
import {getAll as getAllMetadata} from '@/services/metadata';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import LoadingPlugin from 'vue-loading-overlay';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getPlatformCellContent} from '@/utils/helpers';
import Calendar from 'primevue/calendar';
import {DateTime} from 'luxon';
import {i18n} from '@/utils/i18n';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        InputSwitch,
        PrimeDialog: Dialog,
        Panel,
        loading: LoadingPlugin,
        Calendar,
        FilterMenu
    },
    setup() {
        onMounted(() => {
            getAllMetadata(['user'])
                .then((data) => {
                    if (data.data?.user) {
                        userOptions.value = data.data.user;
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });

            if (localStorage.getItem('tickets-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'ticketNumber',
                sortOrder: -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const allEnvs = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const comments: any = ref({});
        const commentsDialogVisible = ref(false);
        const toast = useToast();
        const userOptions = ref([]);

        const tickets = ref();

        const filterDefintions: any = {
            ticketNumber: {value: null, matchMode: FilterMatchMode.EQUALS},
            subject: {value: null, matchMode: FilterMatchMode.CONTAINS},
            email: {value: null, matchMode: FilterMatchMode.CONTAINS},
            assignedUser: {value: null, matchMode: FilterMatchMode.EQUALS},
            ticketStatus: {value: null, matchMode: FilterMatchMode.EQUALS},
            ticketChannel: {value: null, matchMode: FilterMatchMode.EQUALS},
            customer: {value: null, matchMode: FilterMatchMode.CONTAINS},
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        };

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.allEnvs = allEnvs.value;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    tickets.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        createdDateFormatted: DateTime.fromMillis(
                            obj.createdDate
                        )
                            .setLocale(i18n.global.locale)
                            .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                            .toLocaleString(DateTime.DATETIME_MED)
                    }));
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onAllEnvsSwitch = () => {
            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const showCommentsDialog = (data: any) => {
            comments.value = Object.assign(data, {});
            commentsDialogVisible.value = true;
        };

        const hideCommentsDialog = () => {
            comments.value = null;
            commentsDialogVisible.value = false;
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                localStorage.removeItem('tickets-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            } else {
                filters.value = Object.assign(
                    {},
                    filterDefintions,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'ticketNumber',
                sortOrder: event.sortOrder || -1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filterDefintions);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const savedFilterKeys = Object.keys(savedFilters || {}).filter(
                (e) => e !== 'view'
            );

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                savedFilterKeys.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                filters.value = Object.assign({}, filterDefintions, {
                    ...savedFilters
                });
                onFilter();
            }
        };

        const getStatusCellStyle = (value: string) => {
            if (!value) {
                return '';
            }
            switch (value) {
                case 'Noch nicht zugewiesen':
                    return 'text-pink-400';
                case 'Bestätigt':
                case 'Gerät in interner Reparatur':
                    return 'text-blue-400';
                case 'Zugewiesen':
                    return 'text-green-200';
                case 'In Bearbeitung':
                    return 'text-orange-400';
                case 'Wartend':
                case 'Warten auf Kundenreaktion':
                    return 'text-purple-400';
                case 'Gelöst':
                    return 'text-green-400';
                case 'Keine Lösung':
                case 'Kunde wartet auf Reaktion':
                    return 'text-red-400';
                case 'Kunde wartet auf Gutschrift':
                    return 'text-yellow-800';
                case 'Geschlossen':
                    return 'text-blue-200';
                case 'Warten auf Einsendung':
                    return 'text-bluegray-300';
            }

            return 'text-primary';
        };

        return {
            tickets,
            dt,
            totalRecords,
            loading,
            allEnvs,
            lazyParams,
            filters,
            expandedRows,
            matchModesNumeric,
            onPage,
            onFilter,
            onSort,
            onAllEnvsSwitch,
            getStatusCellStyle,
            getPlatformCellContent: getPlatformCellContent,
            comments,
            showCommentsDialog,
            hideCommentsDialog,
            commentsDialogVisible,
            onStateRestore,
            userOptions,
            clearFilters,
            applyFilters
        };
    }
};
