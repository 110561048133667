import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import {reactive, ref, watch} from 'vue';
import {required, url} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {add, update} from '@/services/product-links';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        selectedLink: Object,
        productDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const productNumber = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = reactive({
            title: '',
            url: '',
            id: ''
        });

        const rules = {
            title: {required},
            url: {required, url}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            resetForm();
            showDialog.value = args.displayResponsive;
            productNumber.value =
                args.productDetails.weclapp?.articleNumber ||
                args.productDetails.shopware?.productNumber;
            if (args.selectedLink) {
                state.title = args.selectedLink.title;
                state.url = args.selectedLink.url;
                state.id = args.selectedLink.id;
            }
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            if (state.id) {
                update(productNumber.value, state)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        closeDialog(true);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            } else {
                add(productNumber.value, state)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        closeDialog(true);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const resetForm = () => {
            state.url = '';
            state.title = '';
            state.id = '';
            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress
        };
    }
};
