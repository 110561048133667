import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Keyboard from '@/components/keyboard/keyboard.vue';
import {computed, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import LoadingPlugin from 'vue-loading-overlay';
import {getSingle, update} from '@/services/shipments';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {useVuelidate} from '@vuelidate/core';
import {required, numeric, helpers} from '@vuelidate/validators';
import UpsAnnounceDangerousGoodsDialog from '@/components/dialog/shipments/ups-announce-dangerous-goods.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import {getAll} from '@/services/metadata';
import store from '@/store';
import {i18n} from '@/utils/i18n';
import {
    euStates,
    upsDangerousGoodsGroundTransportStates,
    dhlStates
} from '@/utils/helpers';
import {useConfirm} from 'primevue/useconfirm';
import {getAll as getAllShipments} from '@/services/shipments';
import {FilterMatchMode} from 'primevue/api';
import Panel from 'primevue/panel';
import OverlayPanel from 'primevue/overlaypanel';

export default {
    components: {
        InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        'app-keyboard': Keyboard,
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        'p-multiselect': MultiSelect,
        'p-checkbox': Checkbox,
        UpsAnnounceDangerousGoodsDialog,
        Panel,
        OverlayPanel
    },
    setup() {
        const route = useRoute();
        const loading = ref(false);
        const toast = useToast();
        const confirm = useConfirm();
        const isPaste = ref(false);
        const shipmentNumber = ref(null);
        const shipmentDetails = ref(null);
        const shippingCarrierOptions = ref([]);
        const shippingLocationOptions = ref([]);

        const keyboardElement = ref(null);
        const shipmentNumberInputField = ref(null);
        const packageWeightInputField = ref(null);
        const shippingLabelsCountInputField = ref(null);
        const groupedShipmentFilterField = ref(null);
        const groupedFilterModel = ref(null);
        const groupedFilterCallback = ref(null);

        const inputFieldFocusSwitchTimeout = ref(null);

        const displayDangerousGoodsConfirmationDialog = ref(false);
        const displayUPSAnnounceDangerousGoodsDialog = ref(false);

        const dt = ref();
        const expandedRows = ref([]);
        const groupedShipmentOptions = ref([]);

        const groupedFilters = ref({
            shipmentNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        });

        const opShippingLocations = ref(null);

        const toggleDataTable = (event: any) => {
            if (shippingLocationOptions.value.length > 0) {
                opShippingLocations.value.toggle(event);
            }
        };

        onMounted(() => {
            if (route.params?.shipmentNumber) {
                shipmentNumber.value = route.params.shipmentNumber;
                loadShipmentDetails();
            } else {
                resetShipmentNumber();
            }

            getAll(['shippingCarrier'])
                .then((data) => {
                    if (data.data?.shippingCarrier) {
                        shippingCarrierOptions.value = data.data.shippingCarrier
                            .filter((item: {active: boolean}) => item.active)
                            .map((item: {id: string; name: string}) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                };
                            });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        watch(route, (args) => {
            if (!args.params?.shipmentNumber) {
                resetShipmentNumber();
            }
        });

        watch(shipmentDetails, () => {
            if (
                shipmentDetails.value &&
                (shipmentDetails.value.recipientParty ||
                    shipmentDetails.value.destinationWarehouse) &&
                shipmentDetails.value.warehouse?.name
            ) {
                getAllShipments({
                    first: 0,
                    rows: 50,
                    sortField: 'createdDate',
                    sortOrder: -1,
                    columns: ['shipmentItems', 'isCommissioningComplete'],
                    filters: Object.assign(
                        {
                            status: {
                                value: ['NEW', 'DELIVERY_NOTE_PRINTED'],
                                matchMode: FilterMatchMode.IN
                            },
                            warehouse: {
                                value: shipmentDetails.value.warehouse.name,
                                matchMode: FilterMatchMode.EQUALS
                            }
                        },
                        shipmentDetails.value.recipientParty
                            ? {
                                  recipientPartyId: {
                                      value: shipmentDetails.value
                                          .recipientParty.id,
                                      matchMode: FilterMatchMode.EQUALS
                                  }
                              }
                            : {
                                  destinationWarehouseId: {
                                      value: shipmentDetails.value
                                          .destinationWarehouse.id,
                                      matchMode: FilterMatchMode.EQUALS
                                  }
                              }
                    )
                })
                    .then((data) => {
                        groupedShipmentOptions.value = data.data.items.filter(
                            (item: {shipmentNumber: string}) =>
                                item.shipmentNumber !==
                                shipmentDetails.value.shipmentNumber
                        );
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        });

        const resetShipmentNumber = () => {
            shipmentNumber.value = null;
            shipmentDetails.value = null;
            const inputField: HTMLElement = shipmentNumberInputField.value
                ?.$el as HTMLElement;

            if (inputField) {
                inputField.focus();
            }
            if (state.value) {
                Object.assign(state.value, {
                    packageWeight: null,
                    shippingCarrier: null,
                    shippingLabelsCount: null,
                    shippingLocation: '1',
                    groupedShipments: [],
                    dangerousGoods: null
                });
            }
        };

        const state = ref({
            packageWeight: null,
            shippingCarrier: null,
            shippingLabelsCount: null,
            shippingLocation: '1',
            groupedShipments: [],
            dangerousGoods: null
        });

        const groupedShipmentItems = computed(() => {
            const groupedShipments = [shipmentDetails.value].concat(
                (groupedShipmentOptions.value || []).filter((item: any) => {
                    return (
                        (state.value.groupedShipments || []).findIndex(
                            (gr: any) => gr === item.shipmentNumber
                        ) !== -1
                    );
                })
            );

            let result: Array<any> = [];
            let counter = 0;

            groupedShipments.forEach((groupedShipment: any) => {
                (groupedShipment?.shipmentItems || []).forEach(
                    (shipmentItem: any) => {
                        shipmentItem.shipmentNumber =
                            groupedShipment.shipmentNumber;
                        shipmentItem.positionNumber = (++counter).toString();
                        result = result.concat(shipmentItem);
                    }
                );
            });

            return result;
        });

        const showUpsDangerousGoodsButton = computed(() => {
            return (
                isUpsCarrier.value &&
                [
                    'Wiesbaden',
                    'Hamburg',
                    'Berlin',
                    'Hamburg Obenhaupt',
                    'VDH'
                ].includes(shipmentDetails.value?.warehouse?.name) &&
                groupedShipmentItems.value.some((item): any => {
                    return item.article_flag_akku_groesser_100wh;
                })
            );
        });

        const isCustomerBlocked = computed(() => {
            return (
                shipmentDetails.value?.recipientParty &&
                (shipmentDetails.value?.recipientParty.customerBlocked ||
                    shipmentDetails.value?.recipientParty.customerDeliveryBlock)
            );
        });

        const haveSavingButtonDisabled = computed(() => {
            return (
                savingInProgress.value ||
                !shipmentDetails.value?.isCommissioningComplete ||
                !shipmentDetails.value?.picksComplete ||
                isCustomerBlocked.value ||
                (showUpsDangerousGoodsButton.value &&
                    (state.value?.dangerousGoods === null ||
                        Object.keys(state.value?.dangerousGoods).length < 1)) ||
                (isUpsCarrier.value &&
                    showUpsDangerousGoodsButton.value &&
                    'DE' ===
                        shipmentDetails.value?.recipientAddress?.countryCode) ||
                (isDhlCarrier.value &&
                    dhlStates.indexOf(
                        shipmentDetails.value?.recipientAddress?.countryCode
                    ) === -1)
            );
        });

        const rules = {
            packageWeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                totalEqualToInitial: helpers.withMessage(
                    () => {
                        const dangerousGoodsWeight = (
                            state.value.dangerousGoods.weightCombined || []
                        )
                            .reduce(
                                (accumulator: any, currentValue: any) =>
                                    accumulator +
                                    parseFloat(currentValue.weight),
                                0
                            )
                            .toString()
                            .replace('.', ',');

                        return i18n.global.t(
                            'messages.shipmentWeightLessThanDangerousGoodsWeight',
                            {
                                dangerousGoodsWeight
                            }
                        );
                    },
                    (value: any, payload: any) => {
                        if (!payload?.dangerousGoods?.weightCombined) {
                            return true;
                        }

                        const totalWeight = (
                            payload.dangerousGoods.weightCombined || []
                        ).reduce(
                            (accumulator: any, currentValue: any) =>
                                accumulator + parseFloat(currentValue.weight),
                            0
                        );

                        const packageValue = parseFloat(
                            (value || '0.0').replace(',', '.')
                        );

                        return (
                            Math.round(totalWeight * 100000) <=
                            Math.round(packageValue * 100000)
                        );
                    }
                )
            },
            shippingCarrier: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    (value: any) => {
                        return value || shipmentDetails.value?.isPickup;
                    }
                )
            },
            shippingLabelsCount: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                numeric
            },
            shippingLocation: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            groupedShipments: {},
            dangerousGoods: {}
        };

        const v$ = useVuelidate(rules, state);
        const submitted = ref(false);
        const savingInProgress = ref(false);

        const onCheckboxChange = () => {
            if (
                state.value?.groupedShipments ||
                state.value.groupedShipments.length > 0
            ) {
                const groupedShipments = [shipmentDetails.value].concat(
                    (groupedShipmentOptions.value || []).filter((item: any) => {
                        return (
                            (state.value.groupedShipments || []).findIndex(
                                (gr: any) => gr === item.shipmentNumber
                            ) !== -1
                        );
                    })
                );

                let groupedWeight = 0.0;

                groupedShipments.forEach((groupedShipment: any) => {
                    groupedWeight += parseFloat(
                        groupedShipment.packageWeight || 0.0
                    );
                });

                const numberOfDelimals =
                    groupedWeight.toString().split('.').length > 1
                        ? groupedWeight.toString().split('.')[1].length
                        : 0;

                state.value.packageWeight = groupedWeight
                    .toFixed(Math.min(numberOfDelimals, 3))
                    .toString()
                    .replace('.', ',');
            } else {
                state.value.packageWeight = shipmentDetails.value?.packageWeight
                    ? shipmentDetails.value.packageWeight.replace('.', ',')
                    : '';
            }
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            if (
                (confirmationNeededForThirdCountryAndOrderMoreThanOrEqual1000.value &&
                    shipmentDetails.value?.status ===
                        'DELIVERY_NOTE_PRINTED') ||
                confirmationNeededForThirdCountryAndSpecificShipmentType.value ||
                confirmationNeededForMissingUPSAccountNumber.value ||
                confirmationNeededForMissingMSDSDocument.value
            ) {
                let message = 'messages.shipmentMaskMissingCustomDocuments';

                if (confirmationNeededForMissingUPSAccountNumber.value) {
                    message = 'messages.shipmentMaskMissingUpsAccountNumber';
                }

                if (confirmationNeededForMissingMSDSDocument.value) {
                    message =
                        'messages.shipmentMaskMissingDangerousGoodsMSDSDocument';
                }

                confirm.require({
                    message: i18n.global.t(message),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t(
                        'buttons.completeShipmentNevertheless'
                    ),
                    rejectLabel: i18n.global.t('buttons.cancel'),
                    acceptClass: 'btn-success',
                    rejectClass: 'btn-danger',
                    defaultFocus: 'reject',
                    accept: () => {
                        savingInProgress.value = true;

                        update(shipmentNumber.value, state.value)
                            .then(() => {
                                toast.success(
                                    i18n.global.t('messages.shipmentCompleted')
                                );
                                if (route.params?.shipmentNumber) {
                                    router.push({
                                        name: 'Shipments'
                                    });
                                } else {
                                    resetShipmentNumber();
                                }
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                                loadShipmentDetails();
                            })
                            .finally(() => {
                                savingInProgress.value = false;
                            });
                    }
                });
            } else {
                savingInProgress.value = true;

                update(shipmentNumber.value, state.value)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.shipmentCompleted')
                        );
                        if (route.params?.shipmentNumber) {
                            router.push({
                                name: 'Shipments'
                            });
                        } else {
                            resetShipmentNumber();
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        loadShipmentDetails();
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const loadShipmentDetails = async () => {
            loading.value = true;
            state.value.shippingLocation = await store.dispatch(
                'shipment/getLocationForPlatform',
                store.getters['auth/platform']
            );
            getSingle(shipmentNumber.value)
                .then((data) => {
                    shipmentDetails.value = data.data;
                    if (
                        data.data?.shipmentLocationConfiguration
                            ?.locationOptions
                    ) {
                        shippingLocationOptions.value = Object.keys(
                            data.data.shipmentLocationConfiguration
                                .locationOptions
                        ).map((index: any) => {
                            return {
                                label:
                                    i18n.global.t(
                                        'labels.shipment.shipmentLocation'
                                    ) +
                                    ' ' +
                                    index,
                                value: index
                            };
                        });
                    }

                    if (!shipmentDetails.value?.isCommissioningComplete) {
                        toast.warning(
                            i18n.global.t(
                                'messages.deliveryContainsUnconfirmedWithdrawals'
                            )
                        );
                    }

                    if (!shipmentDetails.value?.picksComplete) {
                        toast.warning(
                            i18n.global.t(
                                'messages.deliveryContainsIncompleteWithdrawals'
                            )
                        );
                    }

                    if (isCustomerBlocked.value) {
                        toast.warning(
                            i18n.global.t('messages.deliveryToBlockedCustomer')
                        );
                    }

                    Object.assign(state.value, {
                        shippingCarrier: data.data.shippingCarrier?.id,
                        packageWeight: data.data.packageWeight
                            ? data.data.packageWeight.replace('.', ',')
                            : '',
                        shippingLabelsCount: data.data.shippingLabelsCount,
                        groupedShipments: [],
                        dangerousGoods: null
                    });

                    setTimeout(() => {
                        const inputField: HTMLInputElement =
                            packageWeightInputField.value
                                ?.$el as HTMLInputElement;

                        inputField.focus();
                        inputField.select();
                        inputField.setAttribute(
                            'data-highlighted',
                            true.toString()
                        );
                    }, 100);
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(i18n.global.t('messages.noSuchShipment'));
                    } else {
                        toast.error(error.message);
                    }

                    resetShipmentNumber();
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const onShipmentNumberChange = (event: any) => {
            if (!isPaste.value) {
                return;
            }
            shipmentNumber.value = event.target.value;
            loadShipmentDetails();
            isPaste.value = false;
        };

        const onShipmentNumberPaste = () => {
            isPaste.value = true;
        };

        const onShipmentNumberEnter = () => {
            loadShipmentDetails();
        };

        const onElementKeyDown = (event: KeyboardEvent) => {
            event.preventDefault();
        };

        const onKeyboardPressed = (key: string | null) => {
            if (!keyboardElement.value) {
                return;
            }

            const highlighted =
                keyboardElement.value.getAttribute('data-highlighted');

            let currentValue: string = highlighted
                ? ''
                : keyboardElement.value.value;

            keyboardElement.value.focus();

            if (highlighted) {
                keyboardElement.value.removeAttribute('data-highlighted');
            }

            if (key === null) {
                currentValue = currentValue.substring(
                    0,
                    currentValue.length - 1
                );
            } else if (key === ',') {
                if (currentValue.indexOf(',') === -1) {
                    currentValue = currentValue ? `${currentValue},` : '0,';
                }
            } else {
                currentValue = `${currentValue}${key}`;
            }

            if (
                !keyboardElement.value.pattern ||
                new RegExp(keyboardElement.value.pattern).test(currentValue)
            ) {
                keyboardElement.value.value = currentValue;
                Object.assign(state.value, {
                    [keyboardElement.value.name]: currentValue
                });
            }

            if (keyboardElement.value.name === 'packageWeight') {
                if (inputFieldFocusSwitchTimeout.value) {
                    clearTimeout(inputFieldFocusSwitchTimeout.value);
                }

                inputFieldFocusSwitchTimeout.value = setTimeout(() => {
                    const inputField: HTMLInputElement =
                        shippingLabelsCountInputField.value
                            .$el as HTMLInputElement;
                    inputField.focus();
                    inputField.select();
                    inputField.setAttribute(
                        'data-highlighted',
                        true.toString()
                    );
                }, 1500);
            } else if (keyboardElement.value.name === 'groupedShipmentFilter') {
                groupedFilterModel.value.value = currentValue;
                if (groupedFilterCallback.value) {
                    groupedFilterCallback.value();
                }
            }
        };

        const onElementFocused = (
            event: any,
            model: any,
            filterCallback: any
        ) => {
            if (model) {
                groupedFilterModel.value = model;
                groupedFilterCallback.value = filterCallback;
            }
            keyboardElement.value = event.target;
        };

        const onShippingCarrierChange = (event: any) => {
            Object.assign(state.value, {
                shippingCarrier: event?.value
            });
            if (!isUpsCarrier.value && !isDhlCarrier.value) {
                state.value.groupedShipments = [];
            }
        };

        const changeShippingLocation = (shippingLocation: string) => {
            const platform = store.getters['auth/platform'];
            store.dispatch('shipment/setShippingLocation', {
                platform,
                shippingLocation
            });
            Object.assign(state.value, {
                shippingLocation
            });
        };

        const rowClass = (data: any) => {
            return data.hasChildren ? 'bg-bluegray-200' : null;
        };

        const shipmentNumberPreSelected = computed(() => {
            return !!route.params?.shipmentNumber;
        });

        const confirmationNeededForAkkuGroesser = computed(() => {
            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {article_flag_akku_groesser_100wh: boolean}) =>
                    shipmentItem.article_flag_akku_groesser_100wh
            );
        });

        const confirmationNeededForAkkuKleiner = computed(() => {
            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {article_flag_akku_kleiner_100wh: boolean}) =>
                    shipmentItem.article_flag_akku_kleiner_100wh
            );
        });

        const confirmationNeededForAerosols = computed(() => {
            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {article_flag_aerosole: boolean}) =>
                    shipmentItem.article_flag_aerosole
            );
        });

        const confirmationNeededForSpedition = computed(() => {
            if (isSpeditionCarrier.value) {
                return null;
            }

            return shipmentDetails.value?.shipmentItems?.find(
                (shipmentItem: {
                    article_spedition: boolean;
                    article_sperrgut: boolean;
                }) =>
                    shipmentItem.article_spedition ||
                    shipmentItem.article_sperrgut
            )?.article?.name;
        });

        const thirdCountryRecipientAddress = computed(() => {
            return (
                shipmentDetails.value?.recipientAddress?.countryCode &&
                euStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) === -1
            );
        });

        const upsDangerousGoodsSuitableRecipientAddress = computed(() => {
            return (
                shipmentDetails.value?.recipientAddress?.countryCode &&
                upsDangerousGoodsGroundTransportStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) !== -1
            );
        });

        const customsDocuments = computed(() => {
            const isOfSpecificType =
                !shipmentDetails.value?.salesOrder ||
                [
                    'CONSIGNMENT',
                    'CUSTOMER_COMPENSATION',
                    'SUPPLIER_RETURN'
                ].indexOf(shipmentDetails.value?.shipmentType) !== -1;

            return isOfSpecificType
                ? shipmentDetails.value?.documents
                : shipmentDetails.value?.salesInvoice?.documents;
        });

        const isUpsCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.upsCarriers?.indexOf(shippingCarrier) !==
                    -1
            );
        });

        const isSpeditionCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.speditionCarriers
                    ?.map((item: number) => item.toString())
                    .indexOf(shippingCarrier) !== -1
            );
        });

        const isDhlCarrier = computed(() => {
            const shippingCarrier =
                state.value?.shippingCarrier ||
                shipmentDetails.value?.shippingCarrier?.id;

            return (
                shippingCarrier &&
                shipmentDetails.value?.dhlCarriers?.indexOf(shippingCarrier) !==
                    -1
            );
        });

        const shipmentNetAmount = computed(() => {
            let sum: number | null = null;
            groupedShipmentItems.value.forEach((item): any => {
                const salesOrderItem =
                    shipmentDetails.value?.salesOrder?.orderItems.find(
                        (orderItem: any) =>
                            orderItem.id === item.salesOrderItemId
                    );

                if (salesOrderItem) {
                    sum =
                        (sum || 0.0) +
                        parseFloat(salesOrderItem.netAmountInCompanyCurrency);
                }
            });
            return sum || shipmentDetails.value?.salesOrder?.netAmount;
        });

        const submitButtonText = computed(() => {
            const isOnlyDeliveryNoteCreation =
                isUpsCarrier.value &&
                thirdCountryRecipientAddress.value &&
                shipmentNetAmount.value &&
                shipmentNetAmount.value >= 1000 &&
                shipmentDetails.value?.status !== 'DELIVERY_NOTE_PRINTED';

            return isOnlyDeliveryNoteCreation
                ? i18n.global.t('buttons.createDeliveryNote')
                : i18n.global.t('buttons.completeShipment');
        });

        const confirmationNeededForThirdCountryAndOrderMoreThanOrEqual1000 =
            computed(() => {
                const generalConditions =
                    isUpsCarrier.value &&
                    thirdCountryRecipientAddress.value &&
                    shipmentNetAmount.value &&
                    shipmentNetAmount.value >= 1000;

                if (!generalConditions) {
                    return false;
                }

                if (!customsDocuments.value) {
                    return true;
                }

                let allFound = false;

                ['R-', 'I-', 'SR-', 'FI-'].forEach((item: string) => {
                    allFound =
                        allFound ||
                        customsDocuments.value.some(
                            (document: {name: string}) =>
                                document.name.startsWith(item)
                        );
                });

                allFound =
                    allFound &&
                    customsDocuments.value.some((document: {name: string}) =>
                        document.name.startsWith('ABD_')
                    );

                return !allFound;
            });

        const confirmationNeededForThirdCountryAndSpecificShipmentType =
            computed(() => {
                const generalConditions =
                    isUpsCarrier.value &&
                    thirdCountryRecipientAddress.value &&
                    [
                        'CONSIGNMENT',
                        'CUSTOMER_COMPENSATION',
                        'SUPPLIER_RETURN'
                    ].indexOf(shipmentDetails.value?.shipmentType) !== -1;

                if (!generalConditions) {
                    return false;
                }

                if (!customsDocuments.value) {
                    return true;
                }

                let allFound = false;

                ['R-', 'I-', 'SR-', 'FI-'].forEach((item: string) => {
                    allFound =
                        allFound ||
                        customsDocuments.value.some(
                            (document: {name: string}) =>
                                document.name.startsWith(item)
                        );
                });

                return !allFound;
            });

        const confirmationNeededForMissingUPSAccountNumber = computed(() => {
            return (
                (shipmentDetails.value?.customAttributes
                    ?.cust_ups_account_bill ||
                    null) &&
                !(
                    shipmentDetails.value?.customAttributes
                        ?.cust_ups_account_no || null
                )
            );
        });

        const confirmationNeededForMissingMSDSDocument = computed(() => {
            return groupedShipmentItems.value.some((item): any => {
                item.article_flag_akku_groesser_100wh &&
                    !item.article.hasMSDSDocument;
            });
        });

        const outputRecipient = (data: any) => {
            let output = '';
            if (data.recipientCustomerNumber && data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )}/${i18n.global.t('labels.shipment.recipientSupplier')} [${
                    data.recipientCustomerNumber
                }/${data.recipientSupplierNumber}]`;
            } else if (data.recipientCustomerNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )} [${data.recipientCustomerNumber}]`;
            } else if (data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientSupplier'
                )} [${data.recipientSupplierNumber}]`;
            } else {
                return '';
            }

            if (data.recipientParty?.company) {
                output += ` ${data.recipientParty.company}`;
            } else if (
                data.recipientParty?.lastName &&
                data.recipientParty?.firstName
            ) {
                output += ` ${data.recipientParty.lastName}, ${data.recipientParty.firstName}`;
            }

            return output;
        };

        const handleFinalSubmitClick = () => {
            if (
                confirmationNeededForAkkuGroesser.value ||
                confirmationNeededForAkkuKleiner.value ||
                confirmationNeededForAerosols.value ||
                confirmationNeededForSpedition.value
            ) {
                displayDangerousGoodsConfirmationDialog.value = true;
            } else {
                handleDangerousGoodsConfirmClick();
            }
        };

        const handleDangerousGoodsConfirmClick = () => {
            displayDangerousGoodsConfirmationDialog.value = false;

            handleSubmit(!v$.value.$invalid);
        };

        const shallowEqual = (object1: any, object2: any): boolean => {
            const keys1 = Object.keys(object1);
            const keys2 = Object.keys(object2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                if (object1[key] !== object2[key]) {
                    return false;
                }
            }

            return true;
        };

        const closeUPSAnnounceDangerousGoodsDialogListener = (
            dangerousGoodsData: any
        ) => {
            displayUPSAnnounceDangerousGoodsDialog.value = false;
            if (dangerousGoodsData) {
                Object.assign(state.value, {
                    dangerousGoods: dangerousGoodsData,
                    shippingLabelsCount: Math.max(
                        state.value.shippingLabelsCount,
                        dangerousGoodsData.numberOfPackages
                    )
                });
            }
        };

        const resolveSalesChannel = (shipment: any) => {
            return (
                shipment?.salesOrder?.salesChannel ||
                shipment?.recipientParty?.customerSalesChannel
            );
        };

        return {
            shipmentNumber,
            shipmentNumberPreSelected,
            loading,
            shipmentDetails,
            shippingLocationOptions,
            shippingCarrierOptions,
            onShipmentNumberChange,
            onShipmentNumberEnter,
            onShipmentNumberPaste,
            changeShippingLocation,
            outputRecipient,
            onElementKeyDown,
            onKeyboardPressed,
            onElementFocused,
            onShippingCarrierChange,
            rowClass,
            dt,
            expandedRows,
            state,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            displayDangerousGoodsConfirmationDialog,
            confirmationNeededForAkkuGroesser,
            confirmationNeededForAkkuKleiner,
            confirmationNeededForAerosols,
            shipmentNumberInputField,
            packageWeightInputField,
            shippingLabelsCountInputField,
            groupedShipmentFilterField,
            resetShipmentNumber,
            thirdCountryRecipientAddress,
            handleFinalSubmitClick,
            handleDangerousGoodsConfirmClick,
            submitButtonText,
            groupedShipmentOptions,
            groupedMultiSelectOptionFilterFunction: (item: any) =>
                !item.isCommissioningComplete ||
                !item.picksComplete ||
                !shallowEqual(
                    item.recipientAddress || {},
                    shipmentDetails.value?.recipientAddress || {}
                ) ||
                item.shippingCarrier?.id !==
                    shipmentDetails.value?.shippingCarrier?.id ||
                resolveSalesChannel(item) !==
                    resolveSalesChannel(shipmentDetails.value),
            groupedShipmentItems,
            isUpsCarrier,
            isDhlCarrier,
            groupedFilters,
            onCheckboxChange,
            displayUPSAnnounceDangerousGoodsDialog,
            showUpsDangerousGoodsButton,
            haveSavingButtonDisabled,
            closeUPSAnnounceDangerousGoodsDialogListener,
            upsDangerousGoodsSuitableRecipientAddress,
            confirmationNeededForSpedition,
            opShippingLocations,
            toggleDataTable
        };
    }
};
