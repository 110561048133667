import {computed, onMounted, ref} from 'vue';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import {useToast} from 'vue-toastification';
import GlobalFieldsStep from '@/components/steps/add-product/global-fields-step.vue';
import EnvSpecificFieldsStep from '@/components/steps/add-product/env-specific-fields-step.vue';
import EnvSpecificDescriptionsStep from '@/components/steps/add-product/env-specific-descriptions-step.vue';
import CloneSupplySourcesStep from '@/components/steps/add-product/clone-supply-sources-step.vue';
import {addNew, getSingle} from '@/services/products';
import store from '@/store';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {onBeforeRouteLeave, useRoute} from 'vue-router';
import ConfirmLeave from '@/components/dialog/general/confirm-leave.vue';
import router from '@/router';
import Panel from 'primevue/panel';
import LoadingPlugin from 'vue-loading-overlay';

import {
    ekPriceCalculation,
    uvpPriceCalculation,
    vkPriceCalculation
} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';

export default {
    components: {
        loading: LoadingPlugin,
        Stepper,
        StepperPanel,
        GlobalFieldsStep,
        EnvSpecificFieldsStep,
        EnvSpecificDescriptionsStep,
        CloneSupplySourcesStep,
        PrimeDialog: Dialog,
        ConfirmLeave: ConfirmLeave,
        Panel,
        'p-button': Button
    },
    props: {
        displayDialog: Boolean
    },
    setup() {
        const route = useRoute();
        const toast = useToast();
        const loading = ref(false);
        const activeIndex = ref(0);
        const pairValues = ref({});
        const savingInProgress = ref(false);
        const formValues = ref({
            step1: {},
            step2: {},
            step3: {}
        });

        const showResultDialog = ref(false);
        const displayConfirmLeaveDialog = ref(false);

        const nextRoute = ref(null);

        const addWizardFormDirty = ref(false);
        const saveResult = ref();

        onBeforeRouteLeave((to: any) => {
            if (addWizardFormDirty.value) {
                displayConfirmLeaveDialog.value = true;
                addWizardFormDirty.value = false;
                nextRoute.value = to;
                return false;
            }
        });

        onMounted(() => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    if (data?.data?.weclapp) {
                        pairValues.value = Object.assign(
                            {},
                            data.data.weclapp,
                            {
                                sku:
                                    data.data.weclapp.articleSupplySources &&
                                    data.data.weclapp.articleSupplySources
                                        .length > 0
                                        ? data.data.weclapp
                                              .articleSupplySources[0]
                                              .articleNumber
                                        : null,
                                supplierNumber:
                                    data.data.weclapp.articleSupplySources &&
                                    data.data.weclapp.articleSupplySources
                                        .length > 0
                                        ? data.data.weclapp
                                              .articleSupplySources[0]
                                              .supplierNumber
                                        : null,
                                supplySources:
                                    data.data.weclapp.articleSupplySources ||
                                    [],
                                ekPrice: ekPriceCalculation(
                                    data.data.weclapp,
                                    false
                                ),
                                uvpPrice: uvpPriceCalculation(
                                    data.data.weclapp,
                                    false
                                ),
                                vkPrice: vkPriceCalculation(
                                    data.data.weclapp,
                                    false
                                ),
                                productSource: route.params.id,
                                name: data.data.weclapp?.name + ' -Clone',
                                shopwareData: data.data.shopware
                            }
                        );

                        productHeader.value =
                            data.data.weclapp?.name || productHeader.value;
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        });

        const onBackButtonClicked = () => {
            switch (activeIndex.value) {
                case 1:
                    Object.assign(pairValues.value, formValues.value.step1);
                    break;
                default:
                    Object.assign(pairValues.value, formValues.value.step2);
                    break;
            }
        };

        const onChoiceConfirmed = (choice: boolean) => {
            if (choice) {
                router.push(nextRoute.value);
            } else {
                displayConfirmLeaveDialog.value = false;
                addWizardFormDirty.value = true;
            }
        };

        const hasError = computed(() => {
            return (
                saveResult.value &&
                (Object.keys(saveResult.value).length !== 1 ||
                    Object.keys(saveResult.value).some((item: any) => {
                        return saveResult.value[item]['error'];
                    }))
            );
        });

        const onNextButtonClicked = (event: {formValues: any}) => {
            switch (activeIndex.value) {
                case 0:
                    Object.assign(formValues.value, {
                        step1: Object.assign(event.formValues, {
                            productSourceProductNumber: (
                                pairValues.value as any
                            )?.productSource,
                            workflow: 'clone'
                        })
                    });
                    break;
                case 1:
                case 2:
                    Object.assign(
                        formValues.value,
                        Object.assign(formValues.value, {
                            step2: {
                                ...formValues.value.step2,
                                ...event.formValues
                            }
                        })
                    );
                    break;
                case 3:
                    Object.assign(formValues.value, {
                        step3: event.formValues
                    });
                    break;
            }

            if (activeIndex.value === 3) {
                savingInProgress.value = true;
                addNew([selectedPlatform.value], formValues.value, {
                    step2: true,
                    step3: false
                })
                    .then((data: any) => {
                        saveResult.value = data.data;
                        showResultDialog.value = true;
                        addWizardFormDirty.value = false;
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const platforms = computed(() => {
            const platforms = store.getters['auth/platforms'];
            return platforms.filter((item: any) => {
                return item.value === selectedPlatform.value;
            });
        });

        const allPlatforms = computed(() => {
            return store.getters['auth/platforms'];
        });

        const loggedInUser = computed(() => {
            return store.getters['auth/user']?.username;
        });

        const onStartOverClick = () => {
            showResultDialog.value = false;
        };

        const onAddWizardFormDirty = () => {
            addWizardFormDirty.value = true;
        };

        const selectedPlatform = computed(() => {
            return store.getters['auth/platform'];
        });

        const productHeader = ref(route.params.id);

        return {
            pairValues,
            onBackButtonClicked,
            onNextButtonClicked,
            onAddWizardFormDirty,
            activeIndex,
            savingInProgress,
            showResultDialog,
            platforms,
            allPlatforms,
            loggedInUser,
            selectedPlatform,
            saveResult,
            hasError,
            onStartOverClick,
            displayConfirmLeaveDialog,
            onChoiceConfirmed,
            loading,
            productHeader
        };
    }
};
