import Panel from 'primevue/panel';
import AccessoriesGrid from '@/components/grid/products/accessories/list.vue';
import {computed, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import {getSingle as getSingleProduct} from '@/services/products';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import router from '@/router';
import LoadingPlugin from 'vue-loading-overlay';
import Button from 'primevue/button';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';

export default {
    components: {
        Panel,
        AccessoriesGrid,
        BulkAddAccessories,
        LoadingPlugin,
        'p-button': Button
    },
    setup() {
        const productDetails = ref(null);
        const route = useRoute();
        const toast = useToast();
        const loading = ref(false);
        const dialogVisible = ref(false);

        onMounted(() => {
            loading.value = true;
            getSingleProduct(productNumber.value)
                .then((data) => {
                    loading.value = false;
                    if (data?.data?.weclapp) {
                        productDetails.value = Object.assign({}, data.data);
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                        router.push({
                            name: 'Products'
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                    loading.value = false;
                });
        });

        const productNumber = computed(() => {
            return (
                productDetails.value?.weclapp?.articleNumber || route.params.id
            );
        });

        const closeAddUsingSelectedMainProducts = (
            forceResfresh: boolean = false
        ) => {
            dialogVisible.value = false;
            if (forceResfresh) {
                getSingleProduct(productNumber.value)
                    .then((data) => {
                        loading.value = false;
                        if (data?.data?.weclapp) {
                            productDetails.value = Object.assign({}, data.data);
                        } else {
                            toast.error(
                                i18n.global.t('messages.noSuchProduct')
                            );
                            router.push({
                                name: 'Products'
                            });
                        }
                    })
                    .catch((error) => {
                        toast.error(error.message);
                        loading.value = false;
                    });
            }
        };

        return {
            productDetails,
            productNumber,
            loading,
            dialogVisible,
            closeAddUsingSelectedMainProducts
        };
    }
};
