import {createStore} from 'vuex';
import authModule from './auth';
import shipmentModule from './shipment';
import uiModule from './ui';
import printerModule from './printer';

export default createStore({
    modules: {
        auth: authModule,
        ui: uiModule,
        shipment: shipmentModule,
        printer: printerModule
    }
});
