import {computed, ref, toRefs} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useVuelidate} from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {requiredIf} from '@vuelidate/validators';
import {getAllForDuplicateCheck} from '@/services/products';
import PairProductDialog from '@/components/dialog/products/pair-product.vue';
import {
    ekPriceCalculation,
    uvpPriceCalculation,
    vkPriceCalculation
} from '@/utils/helpers';

export default {
    emits: ['environments-selected'],
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        PairProductDialog
    },
    props: {
        allPlatforms: Object
    },
    setup(props: any, context: any) {
        const {allPlatforms} = toRefs(props);
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const showCheckResults = ref(false);
        const duplicates = ref({});
        const selectedEnvironments = ref({});
        const toast = useToast();
        const sourceProduct = ref(null);
        const sourceProductPlatform = ref(null);
        const candidateSourceProduct = ref(null);
        const candidateSourceProductPlatform = ref(null);
        const displayPairProductDialog = ref(false);

        const state = ref({
            name: '',
            manufacturerPartNumber: '',
            sku: '',
            ean: ''
        });

        const rules = {
            name: {
                required: requiredIf(() => {
                    return (
                        state.value.manufacturerPartNumber.trim() === '' &&
                        state.value.sku.trim() === '' &&
                        state.value.ean.trim() === ''
                    );
                })
            },
            manufacturerPartNumber: {},
            sku: {},
            ean: {}
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            Object.keys(state.value).forEach(
                (k: any) =>
                    (state.value[k as keyof typeof state.value] =
                        state.value[k as keyof typeof state.value].trim())
            );

            getAllForDuplicateCheck(state.value)
                .then((data) => {
                    duplicates.value = data.data;
                    showCheckResults.value = true;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const showNextButton = computed(() => {
            return (
                Object.keys(selectedEnvironments.value).length > 0 &&
                Object.keys(selectedEnvironments.value).some(
                    (item: any) =>
                        selectedEnvironments.value[
                            item as keyof typeof selectedEnvironments.value
                        ] === true
                )
            );
        });

        const handleBackClick = () => {
            selectedEnvironments.value = {};
            showCheckResults.value = false;

            context.emit('environments-selected', {
                selectedEnvironments: [],
                duplicateCheckValues: state.value,
                pairValues: {}
            });
        };

        const handleProceedToAddingClick = () => {
            let pairValues = {};
            if (sourceProduct.value && sourceProductPlatform.value) {
                pairValues = Object.assign(
                    sourceProductPlatform.value === 'ttl' ||
                        sourceProductPlatform.value === 'vdl'
                        ? {
                              productGroupId:
                                  sourceProductPlatform.value.toUpperCase() +
                                  '-' +
                                  sourceProduct.value.articleNumber
                          }
                        : {},
                    sourceProduct.value.choice === 'full'
                        ? {
                              name: sourceProduct.value.name,
                              manufacturerPartNumber:
                                  sourceProduct.value.manufacturerPartNumber,
                              ean: sourceProduct.value.ean,
                              matchCode: sourceProduct.value.matchCode,
                              vkPrice: vkPriceCalculation(
                                  sourceProduct.value,
                                  false
                              ),
                              uvpPrice: uvpPriceCalculation(
                                  sourceProduct.value,
                                  false
                              ),
                              shortDescription:
                                  sourceProduct.value.shortDescription1
                          }
                        : {}
                );

                if (
                    sourceProduct.value.choice === 'full' &&
                    sourceProduct.value.articleSupplySources &&
                    sourceProduct.value.articleSupplySources.length > 0
                ) {
                    pairValues = Object.assign(pairValues, {
                        sku: sourceProduct.value.articleSupplySources[0]
                            .articleNumber,
                        supplierNumber:
                            sourceProduct.value.articleSupplySources[0]
                                .supplierNumber,
                        ekPrice: ekPriceCalculation(sourceProduct.value, false)
                    });
                }
            }
            context.emit('environments-selected', {
                selectedEnvironments: Object.keys(
                    selectedEnvironments.value
                ).filter(
                    (item: any) =>
                        selectedEnvironments.value[
                            item as keyof typeof selectedEnvironments.value
                        ] === true
                ),
                duplicateCheckValues: state.value,
                pairValues
            });
        };

        const findSku = (duplicates: Array<any>, submittedSku: string) => {
            return duplicates.find((item: any) => {
                return item.articleSupplySources.some(
                    (supplySource: any) =>
                        supplySource.articleNumber === submittedSku
                );
            });
        };

        const findDuplicateValue = (
            duplicates: Array<any>,
            propertyName: string
        ) => {
            return duplicates.find((item: any) => {
                if (!item[propertyName]) {
                    return false;
                }

                if (!state.value[propertyName as keyof typeof state.value]) {
                    return false;
                }
                return (
                    item[propertyName].toLowerCase() ===
                    state.value[
                        propertyName as keyof typeof state.value
                    ].toLowerCase()
                );
            });
        };

        const handleCloneClick = (platform: string, item: any) => {
            candidateSourceProduct.value = item;
            candidateSourceProductPlatform.value = platform;
            displayPairProductDialog.value = true;
        };

        const onClosePairProductDialog = (param: any) => {
            displayPairProductDialog.value = false;
            if (param?.choice) {
                sourceProduct.value = Object.assign(
                    param,
                    candidateSourceProduct.value
                );
                sourceProductPlatform.value =
                    candidateSourceProductPlatform.value;
            }
        };

        const removeSourceProduct = () => {
            sourceProduct.value = null;
            sourceProductPlatform.value = null;
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            showCheckResults,
            findSku,
            platforms: allPlatforms,
            duplicates,
            selectedEnvironments,
            showNextButton,
            handleBackClick,
            handleProceedToAddingClick,
            findDuplicateValue,
            sourceProduct,
            sourceProductPlatform,
            displayPairProductDialog,
            handleCloneClick,
            onClosePairProductDialog,
            removeSourceProduct,
            candidateSourceProduct
        };
    }
};
