<loading v-model:active="loading" />
<div class="m-2">
    <div class="flex align-items-center mb-3 mt-3">
        <div class="flex-none flex mr-5">
            <p-button
                class="text-sm"
                icon="pi pi-plus"
                :label="$t('buttons.addNewGeneric')"
                severity="success"
                @click="showAddDialog"
            ></p-button>
        </div>

        <div class="flex flex-grow-1">
            <div
                v-bind="getRootProps()"
                class="import-wizard-container font-medium text-sm flex-grow-1 flex"
            >
                <input v-bind="getInputProps()" />
                <p v-if="acceptedFiles.length > 0" class="text-center w-full">
                    {{ dropZoneText }}
                </p>
                <p v-else class="text-center w-full">
                    {{ $t("labels.importWizardInfoText") }}
                </p>
            </div>
        </div>

        <div v-if="showUploadConfirmButton" class="flex-none flex ml-5">
            <p-button
                class="p-button-sm p-button-success"
                :label="$t('buttons.confirm')"
                @click="handleUpload"
            ></p-button>

            <p-button
                style="margin-left: 5px"
                class="p-button-sm p-button-danger"
                :label="$t('buttons.cancel')"
                @click="acceptedFiles.length = 0"
            ></p-button>
        </div>

        <div v-if="showMultiDeleteButton" class="flex-none flex ml-5">
            <div class="flex justify-content-end">
                <p-button
                    class="p-button-sm p-button-danger"
                    :label="$t('labels.deleteSelected')"
                    icon="pi pi-trash"
                    @click="showDeleteDialog(selection)"
                ></p-button>
            </div>
        </div>
        <div v-else-if="showDeleteAllButton" class="flex-none flex ml-5">
            <div class="flex justify-content-end">
                <p-button
                    class="p-button-sm p-button-danger"
                    :label="$t('labels.deleteAll')"
                    icon="pi pi-trash"
                    @click="showDeleteDialog(images)"
                ></p-button>
            </div>
        </div>
    </div>
    <ScrollPanel style="height: calc(100vh - 33.5rem)">
        <OrderList
            v-model="images"
            dataKey="id"
            :listStyle="'min-height: ' + (images?.length * 13.7) +'rem;'"
            v-model:selection="selection"
        >
            <template #item="slotProps">
                <div class="product-item">
                    <div class="image-container">
                        <p-image
                            v-if="productDetails?.shopware?.coverId === slotProps.item.productMediaId"
                            width="180"
                            :src="slotProps.item.url"
                            :alt="slotProps.item.fileName"
                            imageStyle="box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);"
                            preview
                            v-badge="'Cover'"
                        />
                        <p-image
                            v-else
                            width="180"
                            :src="slotProps.item.url"
                            :alt="slotProps.item.fileName"
                            imageStyle="box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);"
                            preview
                        />
                    </div>
                    <div class="product-list-detail">
                        <div class="text-sm">
                            {{slotProps.item.fileName}}.{{slotProps.item.fileExtension}}
                            <i
                                class="pi pi-copy text-sm ml-1"
                                style="cursor: pointer"
                                @click="copyToClipboard(slotProps.item.fileName, $event)"
                                v-tooltip.top="$t('labels.copyFilename')"
                            ></i>
                        </div>
                        <div class="text-xs">
                            {{slotProps.item.fileSize}} bytes
                        </div>
                    </div>
                    <div class="product-list-action">
                        <div class="button-container">
                            <i
                                class="pi pi-trash text-sm mr-2 text-red-600"
                                style="cursor: pointer"
                                @click="showDeleteDialog([slotProps.item])"
                                v-tooltip.top="$t('labels.deleteImage')"
                            ></i>
                            <i
                                v-if="productDetails?.shopware?.coverId !== slotProps.item.productMediaId"
                                class="pi pi-star text-sm mr-2"
                                style="color: rgb(255, 193, 7); cursor: pointer"
                                @click="showUseAsCoverDialog([slotProps.item])"
                                v-tooltip.top="$t('labels.setAsCover')"
                            ></i>
                        </div>
                    </div>
                </div>
            </template>
        </OrderList>
    </ScrollPanel>
    <div class="flex justify-content-between mt-3">
        <p-button
            :disabled="!imagesReordered"
            severity="danger"
            @click="images = [].concat(productDetails?.shopware?.images || [])"
            v-text="$t('labels.revert')"
        >
        </p-button>
        <p-button
            :disabled="!imagesReordered"
            severity="success"
            @click="reorderImages"
            v-text="$t('labels.save')"
        >
        </p-button>
    </div>
</div>
<AddDialog
    :displayResponsive="displayAddDialog"
    :productDetails="productDetails"
    @close-dialog="closeDialogListener"
/>
<UseAsCoverDialog
    :displayResponsive="displayUseAsCoverDialog"
    :productDetails="productDetails"
    :selectedImage="selectedImages.length > 0 ? selectedImages[0] : null"
    @close-dialog="closeDialogListener"
/>
<DeleteDialog
    :displayResponsive="displayDeleteDialog"
    :productDetails="productDetails"
    :selectedImages="selectedImages"
    @close-dialog="closeDialogListener"
/>
