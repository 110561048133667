import {computed, ref} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import {i18n} from '@/utils/i18n';
import {DateTime} from 'luxon';
import InputNumber from 'primevue/inputnumber';
import store from '@/store';
import {
    getAllWithDisposition,
    getSingleWithDisposition,
    updateDisposition
} from '@/services/products';
import {useConfirm} from 'primevue/useconfirm';
import {ekPriceCalculation} from '@/utils/helpers';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';

export default {
    components: {
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        InputNumber,
        Panel,
        InputText,
        Skeleton,
        InputIcon,
        IconField
    },
    setup() {
        const loading = ref(false);
        const toast = useToast();
        const confirm = useConfirm();
        const articleNumber = ref(null);
        const foundProducts = ref([]);
        const singleEditInProgress = ref(null);

        const dt = ref();

        const getDateFormatted = (rawValue: number) => {
            return rawValue > 0
                ? DateTime.fromMillis(rawValue)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                      })
                : '';
        };

        const getCurrencyFormatted = (
            rawValue: number,
            currency: string = 'EUR'
        ) => {
            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency
            });

            return formatter.format(rawValue);
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const onCellEditComplete = (eventData: any) => {
            if (eventData.newValue === null) {
                return;
            }

            if (eventData.value === eventData.newValue) {
                toast.warning(i18n.global.t('messages.noChangesDetected'));
                return;
            }

            loading.value = true;
            updateDisposition(eventData.data.articleNumber, {
                [eventData.field]: eventData.newValue
            })
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    singleEditInProgress.value = eventData.data.articleNumber;
                    (foundProducts.value || []).forEach(
                        (element: any, index: any) => {
                            if (
                                element.articleNumber !==
                                eventData.data.articleNumber
                            ) {
                                return;
                            }
                            getSingleWithDisposition(element.articleNumber)
                                .then((singleData) => {
                                    if (
                                        (singleData.data.items || []).length > 0
                                    ) {
                                        foundProducts.value[index] = {
                                            ...singleData.data.items[0],
                                            minMaxDate:
                                                singleData.data.items[0]
                                                    .customAttributes
                                                    ?.art_minmax_datum
                                        };
                                    }
                                })
                                .catch((error) => {
                                    toast.error(
                                        error.response?.data?.error ||
                                            error.message
                                    );
                                })
                                .finally(() => {
                                    singleEditInProgress.value = null;
                                });

                            return false;
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const bulkUpdateMinMaxDate = () => {
            confirm.require({
                message: i18n.global.t(
                    'messages.minMaxDateBulkUpdateConfirmation'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const resolvedPromisesArray: Array<any> = [];

                    (foundProducts.value || []).forEach((element: any) => {
                        if (
                            !element.minMaxDate ||
                            DateTime.fromMillis(element.minMaxDate).startOf(
                                'day'
                            ) < DateTime.now().startOf('day')
                        ) {
                            resolvedPromisesArray.push(
                                Promise.resolve(
                                    updateDisposition(element.articleNumber, {
                                        minMaxDate: new Date()
                                    })
                                )
                            );
                        }
                    });

                    if (resolvedPromisesArray.length < 1) {
                        toast.warning(
                            i18n.global.t('messages.noChangesDetected')
                        );
                        return;
                    }
                    loading.value = true;
                    Promise.all(resolvedPromisesArray)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loading.value = false;
                            handleSearch();
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                            loading.value = false;
                        });
                }
            });
        };

        const handleSearch = () => {
            loading.value = true;
            getAllWithDisposition(articleNumber.value)
                .then((data) => {
                    foundProducts.value = (data.data.items || []).map(
                        (item: any) => {
                            return {
                                ...item,
                                minMaxDate:
                                    item.customAttributes?.art_minmax_datum
                            };
                        }
                    );

                    (foundProducts.value || []).forEach(
                        (element: any, index: any) => {
                            getSingleWithDisposition(element.articleNumber)
                                .then((singleData) => {
                                    if (
                                        (singleData.data.items || []).length > 0
                                    ) {
                                        foundProducts.value[index] = {
                                            ...singleData.data.items[0],
                                            minMaxDate:
                                                singleData.data.items[0]
                                                    .customAttributes
                                                    ?.art_minmax_datum
                                        };
                                    }
                                })
                                .catch((error) => {
                                    toast.error(
                                        error.response?.data?.error ||
                                            error.message
                                    );
                                });
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            articleNumber,
            loading,
            dt,
            getDateFormatted,
            onCellEditComplete,
            locale: i18n.global.locale,
            getCurrencyFormatted,
            bulkUpdateMinMaxDate,
            ekPriceCalculation,
            handleSearch,
            foundProducts,
            editPermissionAvailable,
            singleEditInProgress
        };
    }
};
