import Dialog from 'primevue/dialog';
import {ref, watch} from 'vue';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {setAsCover} from '@/services/product-images';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        selectedImage: Object,
        productDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const productNumber = ref(null);
        const selectedImage = ref(null);
        const toast = useToast();
        const savingInProgress = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            productNumber.value =
                args.productDetails.weclapp?.articleNumber ||
                args.productDetails.shopware?.productNumber;
            selectedImage.value = props.selectedImage;
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            savingInProgress.value = false;
            context.emit('close-dialog', forceRefresh);
        };

        const confirmSetAsCover = () => {
            savingInProgress.value = true;
            setAsCover(productNumber.value, selectedImage.value.productMediaId)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.message);
                    closeDialog();
                });
        };

        return {
            showDialog,
            closeDialog,
            confirmSetAsCover,
            selectedImage,
            savingInProgress
        };
    }
};
