<LoadingPlugin v-model:active="loading" />
<Panel v-if="productDetails">
    <template #header>
        <div class="flex align-items-center">
            <div v-if="productDetails?.weclapp" class="font-semibold">
                <a
                    :href="productDetails.weclapp.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + productDetails.weclapp.id"
                    target="_blank"
                    >{{ productNumber }}</a
                >
                <span class="spacer-dot"></span>
                {{ productDetails.weclapp.name}}
                <span class="spacer-dot"></span>
                {{ $t("labels.articleAccessories") }}
            </div>
            <div>
                <p-button
                    class="ml-2 text-sm"
                    @click="dialogVisible = true;"
                    :label="$t('buttons.addNewGeneric')"
                    icon="pi pi-plus text-sm"
                    severity="success"
                >
                </p-button>
            </div>
        </div>
    </template>
    <AccessoriesGrid :product-details="productDetails" stand-alone>
    </AccessoriesGrid>
</Panel>
<BulkAddAccessories
    :products="[{...productDetails?.weclapp, shopwareData: productDetails?.shopware}]"
    :displayResponsive="dialogVisible"
    mainProductsSelected
    @close-dialog="closeAddUsingSelectedMainProducts"
/>
