<template v-if="apiCredentials">
    <div class="formgrid grid p-fluid">
        <template
            v-for="item in [{ value: 'teltec-demo', label: 'Teltec DEMO' }, { value: 'teltec', label: 'Teltec LIVE' }, { value: 'videodata-demo', label: 'videodata DEMO' }, { value: 'videodata', label: 'videodata LIVE' }]"
        >
            <div class="field col">
                <label>{{ item.label }}</label>
                <div
                    v-if="apiCredentials && apiCredentials[item.value]"
                    @click="handleDialogOpen($event, item)"
                    style="cursor: pointer"
                >
                    <span
                        class="pi pi-pencil"
                        style="vertical-align: middle"
                    ></span>
                    <span style="margin-left: 0.5rem; vertical-align: middle"
                        >{{ $t('buttons.edit')}}</span
                    >
                </div>
                <div
                    v-else
                    @click="handleDialogOpen($event, item)"
                    style="cursor: pointer"
                >
                    <span
                        class="pi pi-plus text-green-600"
                        style="vertical-align: middle"
                    ></span>
                    <span style="margin-left: 0.5rem; vertical-align: middle"
                        >{{ $t('labels.addNew')}}</span
                    >
                </div>
            </div>
        </template>
    </div>
</template>
<PrimeDialog
    v-model:visible="dialogVisible"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '20vw'}"
    :modal="true"
    @hide="onHideDialog"
    :dismissableMask="true"
    :header="dialogHeader"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="p-fluid">
            <InputText
                placeholder="API-Token"
                autocomplete="off"
                v-model="v$.token.$model"
                :class="{'p-invalid':v$.token.$invalid && submitted}"
            />
            <small
                v-if="(v$.token.$invalid && submitted) || v$.token.$pending.$response"
                class="p-error"
                >{{v$.token.required.$message.replace('Value',
                'API-Token')}}</small
            >
        </div>
        <div class="mt-3 flex justify-content-end">
            <p-button
                type="submit"
                severity="success"
                :disabled="savingInProgress"
            >
                {{ savingInProgress ? $t('labels.inProgress') :
                $t('buttons.confirm') }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
