import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "flex mt-3 mb-3 justify-content-between" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "mr-3" }
const _hoisted_5 = { style: {"margin-top":"-5px"} }
const _hoisted_6 = { class: "formgrid grid p-fluid" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { class: "formgrid grid p-fluid mt-3" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "mt-3 formgrid grid p-fluid" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = { class: "mt-3 flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_tiny = _resolveComponent("tiny")
  const _component_InputText = _resolveComponent("InputText")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_ConfirmLeave = _resolveComponent("ConfirmLeave")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.savingInProgress,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
    }, null, 8, ["active"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 29rem)"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('labels.autoSave', {numberOfSeconds: 10})), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_InputSwitch, {
                    modelValue: _ctx.autoSave,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.autoSave) = $event)),
                    onInput: _ctx.toogleAutoSave
                  }, null, 8, ["modelValue", "onInput"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.webshopText')), 1),
                _createVNode(_component_tiny, {
                  "api-key": "27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8",
                  modelValue: _ctx.v$.webshopText.$model,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.webshopText.$model) = $event)),
                  init: {
                    
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize save',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                    browser_spellcheck: true,
                    autoresize_bottom_margin: 50,
                    min_height: 500,
                    save_onsavecallback: function(inst) {
                        _ctx.handleSubmit(!_ctx.v$.$invalid);
                    }
                }
                }, null, 8, ["modelValue", "init"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.webshopText.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.shortDescription')), 1),
                _createVNode(_component_InputText, {
                  placeholder: _ctx.$t('labels.productDescription.shortDescription'),
                  autocomplete: "off",
                  modelValue: _ctx.v$.shortDescription.$model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.shortDescription.$model) = $event)),
                  onKeyup: _cache[4] || (_cache[4] = $event => (_ctx.onKeyUp($event))),
                  maxLength: 100,
                  class: _normalizeClass({'p-invalid':_ctx.v$.shortDescription.$invalid && _ctx.submitted})
                }, null, 8, ["placeholder", "modelValue", "class"]),
                _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.shortDescriptionCharactersLeft})), 1),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.shortDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.longDescription')), 1),
                _createVNode(_component_tiny, {
                  "api-key": "27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8",
                  modelValue: _ctx.v$.longDescription.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.longDescription.$model) = $event)),
                  init: {
                    
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize save',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                    browser_spellcheck: true,
                    autoresize_bottom_margin: 50,
                    min_height: 500,
                    save_onsavecallback: function(inst) {
                        _ctx.handleSubmit(!_ctx.v$.$invalid);
                    }
                }
                }, null, 8, ["modelValue", "init"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.longDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_p_button, {
            severity: "success",
            disabled: _ctx.savingInProgress,
            type: "submit",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm'))
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _createVNode(_component_ConfirmLeave, {
      displayConfirmLeaveDialog: _ctx.displayConfirmLeaveDialog,
      onChoiceConfirmed: _ctx.onChoiceConfirmed
    }, null, 8, ["displayConfirmLeaveDialog", "onChoiceConfirmed"])
  ], 64))
}