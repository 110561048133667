import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (
    parentId: string | null = null
): Promise<AxiosResponse> => {
    if (parentId) {
        return apiClient.get(
            '/api/v1/products/categories?parentId=' + parentId
        );
    }

    return apiClient.get('/api/v1/products/categories');
};

export const search = (searchTerm: string): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/products/categories?searchTerm=' + searchTerm
    );
};
