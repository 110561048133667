import {IProductDescription} from '@/interfaces/product/details/product-description';
import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';
import {FilterMatchMode} from 'primevue/api';

export const getSingle = (
    productNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/products/' + productNumber);
};

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ),
            filterConjunction: input.filterConjunction || 'and'
        },
        input.platform
            ? {
                  platform: input.platform
              }
            : {}
    );

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/products?' + queryString);
};

export const getAllForDuplicateCheck = (input: any): Promise<AxiosResponse> => {
    const filters = {};
    for (const key in input) {
        Object.assign(filters, {
            [key]: {
                value: input[key],
                matchMode: FilterMatchMode.EQUALS
            }
        });
    }

    const params = Object.assign(
        {},
        {
            allEnvs: true,
            start: 0,
            end: 10,
            filters: encodeURIComponent(
                JSON.stringify(clearEmptyFilters(filters || {}))
            ),
            filterConjunction: 'or'
        }
    );

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/products?' + queryString);
};

export const getAllUsed = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || 'articleNumber',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify([
                'articleNumber',
                'name',
                'vkPrice',
                'articleCategoryName',
                'ekPrice',
                'productCategory',
                'activeInShop',
                'description',
                'shortDescription1',
                'stock',
                'weclappExtraInfo',
                'availableInShop',
                'activeInShop'
            ]),
            filters: encodeURIComponent(
                JSON.stringify(
                    clearEmptyFilters(Object.assign(input.filters || {}))
                )
            ),
            filterConjunction: 'and'
        }
    );

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/used-products?' + queryString);

    //return apiClient.get('/api/v1/used-products');
};

export const getAllWithDisposition = (input: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/product-disposition', {
        params: {
            articleNumber: input.split(',').map((item: string) => item.trim())
        }
    });
};

export const getSingleWithDisposition = (
    articleNumber: any,
    withStatistics: boolean = true
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/product-disposition', {
        params: {
            articleNumber: [articleNumber],
            withStatistics
        }
    });
};

export const getAllWithIncompleteDimensions = (
    input: any
): Promise<AxiosResponse> => {
    const filtersToSubmit = Object.assign({}, input.filters);
    delete filtersToSubmit['warehouseLevel.name'];

    return apiClient.get(
        '/api/v1/products-with-incomplete-dimensions?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(filtersToSubmit))
            )
    );
};

export const addNew = async (
    selectedEnvironments: Array<string>,
    data: {step1: any; step2: any; step3: any},
    cleanNonPlatformSpecific: {step2: boolean; step3: boolean} = {
        step2: true,
        step3: true
    }
): Promise<AxiosResponse> => {
    Object.keys(data.step2).forEach((key) => {
        const foundEnv = selectedEnvironments.some((item) =>
            key.endsWith(item)
        );
        if (!foundEnv && cleanNonPlatformSpecific.step2) {
            delete data.step2[key];
        }
    });

    Object.keys(data.step3).forEach((key) => {
        const foundEnv = selectedEnvironments.some((item) =>
            key.endsWith(item)
        );
        if (!foundEnv && cleanNonPlatformSpecific.step3) {
            delete data.step3[key];
        }
    });

    return apiClient.post(
        '/api/v1/products?' +
            selectedEnvironments
                .map((item: string) => {
                    return 'selectedEnvironments[]=' + item;
                })
                .join('&'),

        {
            data: {...data.step1, ...data.step2, ...data.step3}
        }
    );
};

export const updateBasicInfo = async (
    productNumber: string,
    basicInfo: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        basicInfo
    });
};

export const updateDisposition = async (
    productNumber: string,
    disposition: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        disposition
    });
};

export const updateDimensions = async (
    productNumber: string,
    dimensions: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        dimensions
    });
};

export const updateGrouping = async (
    productNumber: string,
    grouping: {
        productNumber: string;
    }
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        grouping
    });
};

export const updatePromotions = async (
    productNumber: string,
    promotions: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        promotions
    });
};

export const updateCompliance = async (
    productNumber: string,
    compliance: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        compliance
    });
};

export const updateGoogleAdwords = async (
    productNumber: string,
    googleAdwords: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        googleAdwords
    });
};

export const updateBStockData = async (
    productNumber: string,
    bStock: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        bStock
    });
};

export const updateDescription = async (
    productNumber: string,
    description: IProductDescription
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber, {
        description
    });
};

export const updateBadges = async (
    productNumber: string,
    badges: Array<string>
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber + '/badges', {
        badges
    });
};

export const updateCategories = async (
    productNumber: string,
    categories: Array<string>
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/products/' + productNumber + '/categories', {
        categories
    });
};

export const bulkProcessBadges = async (
    products: Array<any>,
    productFilters: any,
    badges: Array<any>,
    isRemove: boolean,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-badges',
        Object.assign(
            {
                isRemove,
                badges: badges.map(({id, name}) => {
                    return {
                        id,
                        name
                    };
                })
            },
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkProcessRules = async (
    products: Array<any>,
    productFilters: any,
    rules: object,
    isRemove: boolean,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-shop-rules',
        Object.assign(
            {
                isRemove,
                rules
            },
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkProcessCategories = async (
    products: Array<any>,
    productFilters: any,
    categories: Array<string>,
    isRemove: boolean,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-categories',
        Object.assign(
            {
                isRemove,
                categories
            },
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkProcessOnlineStatus = async (
    products: Array<any>,
    productFilters: any,
    categories: Array<string>,
    setOnline: boolean,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-shop-online-status',
        Object.assign(
            {
                setOnline
            },
            categories.length > 0 ? {categories} : {},
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkUpdateDisposition = async (
    products: Array<any>,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/product-disposition', {
        payload,
        products: products.map(({id, articleNumber}) => {
            return {
                id,
                articleNumber
            };
        })
    });
};

export const bulkUpdatePromotions = async (
    products: Array<any>,
    productFilters: any,
    payload: any,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-promotions',
        Object.assign(
            payload,
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkUpdateCompliance = async (
    products: Array<any>,
    productFilters: any,
    payload: any,
    isFilterRelated: boolean,
    startTime?: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-compliance',
        Object.assign(
            payload,
            startTime ? {startTime} : {},
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkUpdateMetatags = async (
    products: Array<any>,
    productFilters: any,
    metatags: Array<string>,
    isRemove: boolean,
    isFilterRelated: boolean
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-metatags',
        Object.assign(
            {
                isRemove,
                metatags
            },
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};

export const bulkUpdateTags = async (
    products: Array<any>,
    productFilters: any,
    tags: Array<string>,
    isRemove: boolean,
    isFilterRelated: boolean
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/product-weclapp-tags',
        Object.assign(
            {
                isRemove,
                tags
            },
            isFilterRelated
                ? {filters: clearEmptyFilters(productFilters)}
                : {
                      products: products.map(({id, articleNumber}) => {
                          return {
                              id,
                              articleNumber
                          };
                      })
                  }
        )
    );
};
