<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="flex flex-column">
            <div>
                <span
                    v-if="collidingUsers.length > 0"
                    class="text-orange-600"
                    v-tooltip.top="$t('messages.collidingUsersProductEditPlain', {item:
                            collidingUsers.join(', ')})"
                >
                    <font-awesome-icon
                        style="margin-right: 10px"
                        :icon="['fas', 'exclamation-triangle']"
                    />
                </span>
                <span v-if="productDetails?.weclapp" class="text-lg">
                    <a
                        :href="productDetails.weclapp.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + productDetails.weclapp.id"
                        target="_blank"
                        >{{ productNumber }}</a
                    >
                    <span class="spacer-dot"></span>{{
                    productDetails?.weclapp?.name}}<span
                        class="spacer-dot"
                    ></span>
                    {{ $t('labels.vkPrice') }} {{vkPrice}}
                    <span class="spacer-dot"></span>
                    {{ $t('labels.ekPrice') }} {{ekPrice}}
                    <span class="spacer-dot"></span>
                    UVP {{listPrice}}
                </span>
                <template
                    v-if="productDetails?.shopware?.name && productDetails?.shopware?.url"
                >
                    <span class="spacer-dot"></span>
                    <a
                        target="_blank"
                        :href="resolvedShopwareUrl(productDetails?.shopware)"
                    >
                        <i
                            class="pi pi-shopping-cart text-color"
                            style="font-size: 1.2rem"
                        ></i> </a
                ></template>
            </div>
            <div class="text-sm" v-if="productDetails?.weclapp?.extraInfo">
                {{ $t('labels.stockQuantityWithoutOrder') }}:
                <b
                    >{{productDetails?.weclapp?.extraInfo.stockQuantityWithoutOrder
                    }}</b
                >
                <span class="spacer-dot"></span>
                {{ $t('labels.stockTotal') }}:
                <b>{{productDetails?.weclapp?.extraInfo.stockQuantity }}</b>
                <span class="spacer-dot"></span>
                {{ $t('labels.stockValuationPrice') }}:
                <b
                    >{{formatter.format(productDetails.weclapp.extraInfo.stockValuationPrice)
                    }}</b
                >
            </div>
        </div>
    </template>
    <template #icons>
        <div class="flex justify-contente-end align-items-baseline">
            <template v-if="showOverlayButton">
                <i
                    v-if="previousHistoryItem"
                    class="pi pi-chevron-left text-xs mr-3"
                    @click="onHistoryItemSelected(previousHistoryItem.articleNumber)"
                    style="cursor: pointer"
                    v-tooltip.top="$t('labels.previousHistoryItem')"
                ></i>
                <i
                    v-if="nextHistoryItem"
                    class="pi pi-chevron-right text-xs mr-3"
                    @click="onHistoryItemSelected(nextHistoryItem.articleNumber)"
                    style="cursor: pointer"
                    v-tooltip.top="$t('labels.nextHistoryItem')"
                ></i>

                <p-button
                    v-if="historyProducts.length > 0"
                    icon="pi pi-chevron-down"
                    class="mr-3 p-button-secondary p-button-rounded overlay-panel-toggle"
                    @click="toggleOverlayPanel"
                    v-tooltip.top="$t('labels.allHistoryItems')"
                />

                <OverlayPanel
                    ref="overlayPanel"
                    v-if="historyProducts.length > 0"
                >
                    <DataView
                        :value="historyProducts"
                        class="text-sm"
                        layout="list"
                    >
                        <template #list="slotProps">
                            <div class="grid grid-nogutter">
                                <div class="col">
                                    <div
                                        v-for="(item, index) in slotProps.items"
                                        :key="index"
                                        class="col"
                                        @click="onHistoryItemSelected(item.articleNumber)"
                                        style="cursor: pointer"
                                    >
                                        {{ item.name }} ({{item.articleNumber}})
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DataView>
                </OverlayPanel>
            </template>

            <a href="/products">{{ $t("labels.article") }} </a
            >&nbsp;&nbsp;/&nbsp;&nbsp;{{ productNumber }}
            <i
                @click="toggleBookmark"
                class="fas fa-star ml-2 mr-2"
                :style="isBookmarked ? 'color: rgb(255, 193, 7)' : ''"
            ></i>

            <p-multiselect
                :modelValue="selectedTabs"
                class="text-xs"
                ref="tabSelectorComponent"
                :options="tabOptions"
                optionLabel="label"
                optionValue="value"
                optionDisabled="optionDisabled"
                @change="onToggleTabVisibility"
                placeholder="Select Tabs"
                style="max-width: 12em"
            >
                <template #option="slotProps">
                    <div class="text-sm">{{ slotProps.option.label }}</div>
                </template>
                <template #footer>
                    <div class="w-full m-2 flex justify-content-between">
                        <p-button
                            severity="danger"
                            class="mr-3"
                            size="small"
                            @click="onCancelTabsSelection"
                        >
                            {{ $t("buttons.cancel") }}
                        </p-button>
                        <p-button
                            severity="success"
                            size="small"
                            @click="onConfirmTabsSelection"
                        >
                            {{ $t("buttons.confirm") }}
                        </p-button>
                    </div>
                </template>
            </p-multiselect>
        </div>
    </template>
    <TabView
        class="tabview-custom text-sm"
        v-model:activeIndex="activeTabIndex"
        scrollable
    >
        <TabPanel>
            <template #header>
                <i class="pi pi-align-justify"></i>
                <span>Home</span>
            </template>
            <ProductHome
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="['ttl', 'vdl', 'vct'].indexOf(importPlatform?.threeLetterId) === -1"
        >
            <template #header>
                <i class="pi pi-link"></i>
                <span>Group ID</span>
            </template>
            <GroupTab :product-details="productDetails"> </GroupTab>
        </TabPanel>
        <TabPanel :disabled="!productDetails?.shopware">
            <template #header>
                <i class="pi pi-align-justify"></i>
                <span>{{ $t("labels.description") }}</span>
            </template>
            <ProductDescription
                :product-details="productDetails"
                @reload-product="loadProductDetails"
                @auto-save-description="onAutoSaveDescription"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware"
            v-if="visibleTabs.indexOf('Accessories') !== -1"
        >
            <template #header>
                <i class="pi pi-sitemap"></i>
                <span>{{ $t("labels.articleAccessories") }}</span>
            </template>
            <p-button
                class="mt-3 mb-3 text-sm"
                @click="bulkAddAccessoriesDialogVisible = true;"
                :label="$t('buttons.addNewGeneric')"
                icon="pi pi-plus text-sm"
                severity="success"
            >
            </p-button>
            <Accessories
                v-if="productDetails"
                :product-details="productDetails"
            >
            </Accessories>
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware"
            v-if="visibleTabs.indexOf('Categories') !== -1"
        >
            <template #header>
                <i v-if="!productDetails?.shopware" class="pi pi-sitemap"></i>
                <span>{{ $t("labels.categories") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.categories"
                    >({{ productDetails.shopware.categories.length }})</span
                >
            </template>
            <Categories
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware || importPlatform?.threeLetterId === 'vct'"
            v-if="visibleTabs.indexOf('Badges') !== -1"
        >
            <template #header>
                <i v-if="!productDetails?.shopware" class="pi pi-tags"></i>
                <span>{{ $t("labels.badges") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.badges"
                    >({{ productDetails.shopware.badges.length }})</span
                >
            </template>
            <Badges
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware"
            v-if="visibleTabs.indexOf('Images') !== -1"
        >
            <template #header>
                <i v-if="!productDetails?.shopware" class="pi pi-images"></i>
                <span>{{ $t("labels.images") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.images"
                    >({{ productDetails.shopware.images.length }})</span
                >
            </template>
            <Images
                v-if="productDetails?.shopware"
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware"
            v-if="visibleTabs.indexOf('Videos') !== -1"
        >
            <template #header>
                <i v-if="!productDetails?.shopware" class="pi pi-youtube"></i>
                <span>{{ $t("labels.videos") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.videos"
                    >({{ productDetails.shopware.videos.length }})</span
                >
            </template>
            <Videos
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware || importPlatform?.threeLetterId === 'vct'"
            v-if="visibleTabs.indexOf('Documents') !== -1"
        >
            <template #header>
                <i v-if="!productDetails?.shopware" class="pi pi-file-pdf"></i>
                <span>{{ $t("labels.documents") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.documents"
                    >({{ productDetails?.shopware.documents.length }})</span
                >
            </template>
            <Documents
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware || importPlatform?.threeLetterId === 'vct'"
            v-if="visibleTabs.indexOf('Links') !== -1"
        >
            <template #header>
                <i
                    v-if="!productDetails?.shopware"
                    class="pi pi-external-link"
                ></i>
                <span>{{ $t("labels.links") }}</span>
                <span
                    class="tab-header-badge"
                    v-if="productDetails?.shopware?.links"
                    >({{ productDetails.shopware.links.length }})</span
                >
            </template>
            <Links
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            />
        </TabPanel>
        <TabPanel v-if="visibleTabs.indexOf('Promotions') !== -1">
            <template #header>
                <i class="pi pi-percentage text-xs"></i>
                <span>{{ $t("labels.promotions") }}</span>
            </template>
            <Promotions
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            >
            </Promotions>
        </TabPanel>
        <TabPanel v-if="visibleTabs.indexOf('Compliance') !== -1">
            <template #header>
                <i class="pi pi-check-square"></i>
                <span>{{ $t("labels.compliance") }}</span>
            </template>
            <Compliance
                :product-details="productDetails"
                @reload-product="loadProductDetails"
            >
            </Compliance>
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware || importPlatform?.threeLetterId === 'vct'"
            v-if="visibleTabs.indexOf('GoogleAds') !== -1"
        >
            <template #header>
                <i class="pi pi-google"></i>
                <span>{{ $t("labels.productGoogleAds.label") }}</span>
            </template>
            <GoogleAdwords :product-details="productDetails"></GoogleAdwords>
        </TabPanel>
        <TabPanel
            :disabled="!productDetails?.shopware || importPlatform?.threeLetterId !== 'ttl'"
            v-if="visibleTabs.indexOf('BStock') !== -1"
        >
            <template #header>
                <i class="fa fa-box-open" style="font-size: 1rem"></i>
                <span>B-Stock</span>
            </template>
            <BStock :product-details="productDetails"></BStock>
        </TabPanel>
    </TabView>
</Panel>
<p-dialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showCollidingUsersDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    modal
>
    <div class="card-body">
        <div>
            <h6
                v-html="$t('messages.collidingUsersProductEdit', {item:
                    collidingUsers.join(', ')})"
            ></h6>
        </div>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" size="small" @click="cancelHandler">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                size="small"
                @click="continueAnywayHandler"
            >
                {{ $t("buttons.continueAnyway") }}
            </p-button>
        </div>
    </div>
</p-dialog>
<BulkAddAccessories
    :products="[weclappDetails]"
    :displayResponsive="bulkAddAccessoriesDialogVisible"
    mainProductsSelected
    @close-dialog="closeAddUsingSelectedMainProducts"
/>
