import Dialog from 'primevue/dialog';
import {reactive, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {required, url, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {useDropzone} from 'vue3-dropzone';
import {add, addFromUrl, update} from '@/services/product-documents';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        selectedDocument: Object,
        productDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const productNumber = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const acceptFiles = ref([]);

        const state = reactive({
            id: '',
            title: '',
            url: ''
        });

        const rules = {
            title: {required},
            url: {
                url,
                required: requiredIf(() => {
                    return state.id === '' && acceptFiles.value.length < 1;
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            resetForm();
            productNumber.value =
                args.productDetails.weclapp?.articleNumber ||
                args.productDetails.shopware?.productNumber;
            if (args.selectedDocument) {
                state.title = args.selectedDocument.title;
                state.id = args.selectedDocument.productMediaId;
            }
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            if (state.id) {
                update(productNumber.value, state.title, state.id)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        closeDialog(true);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            } else {
                if (acceptFiles.value.length > 0) {
                    add(productNumber.value, state.title, acceptFiles.value)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            closeDialog(true);
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            savingInProgress.value = false;
                        });
                } else {
                    addFromUrl(productNumber.value, state.title, state.url)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            closeDialog(true);
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            savingInProgress.value = false;
                        });
                }
            }
        };

        const resetForm = () => {
            state.id = '';
            state.url = '';
            state.title = '';
            submitted.value = false;
            acceptFiles.value.length = 0;
        };

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: '.pdf'
        });

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            acceptFiles,
            getRootProps,
            getInputProps,
            ...rest
        };
    }
};
