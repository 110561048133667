import {computed, onMounted, ref, toRefs, watch} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import {getAll} from '@/services/metadata';
import Dialog from 'primevue/dialog';
import {stripTagsAndTruncate} from '@/utils/helpers';
import store from '@/store';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        selectedEnvironments: Object,
        duplicateCheckValues: Object,
        allPlatforms: Object,
        pairValues: Object,
        savingInProgress: Boolean,
        singleEnvironment: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-fieldset': Fieldset,
        'p-dropdown': Dropdown,
        InputNumber,
        tiny: Editor,
        PrimeDialog: Dialog
    },
    setup(props: any, context: any) {
        const {
            selectedEnvironments,
            savingInProgress,
            allPlatforms,
            singleEnvironment
        } = toRefs(props);

        const pairValues = ref(null);
        const duplicateCheckValues = ref(null);
        const submitted = ref(false);
        onMounted(() => {
            getAll(['supplier'], !singleEnvironment.value).then((data: any) => {
                if (singleEnvironment.value) {
                    const test = data.data['supplier'].map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                            currencyId: string;
                            currencyName: string;
                            company2: string;
                        }) => {
                            return {
                                label:
                                    stripTagsAndTruncate(
                                        [item.name, item.company2].join(' '),
                                        150,
                                        '...'
                                    ) +
                                    ' (' +
                                    item.supplierNumber +
                                    ') ',
                                value: item.id,
                                currencyId: item.currencyId,
                                currencyName: item.currencyName
                            };
                        }
                    );
                    data.data['supplier'] = test;
                    suppliers.value = {
                        [store.getters['auth/platform']]: data.data
                    };
                } else {
                    Object.keys(data.data).forEach((env: string) => {
                        const test = data.data[env]['supplier'].map(
                            (item: {
                                id: string;
                                name: string;
                                supplierNumber: string;
                                currencyId: string;
                                currencyName: string;
                                company2: string;
                            }) => {
                                return {
                                    label:
                                        stripTagsAndTruncate(
                                            [item.name, item.company2].join(
                                                ' '
                                            ),
                                            150,
                                            '...'
                                        ) +
                                        ' (' +
                                        item.supplierNumber +
                                        ') ',
                                    value: item.id,
                                    currencyId: item.currencyId,
                                    currencyName: item.currencyName
                                };
                            }
                        );
                        data.data[env]['supplier'] = test;
                    });

                    suppliers.value = data.data;
                }
            });
        });

        const prePopulateForm = () => {
            allPlatforms.value.forEach(
                (platform: {label: string; value: string}) => {
                    state.value['sku-' + platform.value] =
                        pairValues.value?.sku ||
                        duplicateCheckValues.value?.sku ||
                        state.value['sku-' + platform.value];

                    state.value['ekPrice-' + platform.value] =
                        pairValues.value?.ekPrice ||
                        state.value['ekPrice-' + platform.value];
                }
            );

            for (const key in state.value) {
                if (
                    Object.keys(pairValues.value).includes(key) &&
                    (!key.startsWith('ekStartDate') ||
                        pairValues.value[key] > 0)
                ) {
                    state.value[key] = pairValues.value[key];
                }
            }
        };

        const fieldDefinitions: any = [
            {
                name: 'supplier',
                value: null
            },
            {
                name: 'sku',
                value: null
            },
            {
                name: 'ekTaxRateType',
                value: 'STANDARD'
            },
            {
                name: 'ekPrice',
                value: null
            },
            {
                name: 'currency',
                value: null
            },
            {
                name: 'ekStartDate',
                value: new Date()
            },
            {
                name: 'discount1',
                value: null
            },
            {
                name: 'discount2',
                value: null
            },
            {
                name: 'discountGroup',
                value: null
            },
            {
                name: 'trailingDiscount',
                value: null
            }
        ];

        const stateDef: any = {};
        const supplierDef: any = {};

        allPlatforms.value.forEach(
            (platform: {label: string; value: string}) => {
                fieldDefinitions.forEach(
                    (fieldDefinition: {name: string; value: any}) => {
                        const key = fieldDefinition.name + '-' + platform.value;
                        stateDef[key as keyof typeof stateDef] =
                            fieldDefinition.value;
                    }
                );
                supplierDef[platform.value] = {
                    supplier: []
                };
            }
        );

        const state = ref(stateDef);
        const suppliers = ref(supplierDef);

        const rules = computed(() => {
            const localRules: any = {};
            selectedEnvironments.value.forEach((platform: string) => {
                /*['sku', 'supplier', 'currency'].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {
                        required
                    };
                });*/

                localRules['supplier-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['sku-' + platform] !== null;
                    })
                };

                localRules['sku-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['supplier-' + platform] !== null;
                    })
                };

                localRules['currency-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['supplier-' + platform] !== null;
                    })
                };

                /*localRules['ekPrice-' + platform] = {
                    required: requiredIf(() => {
                        return (
                            state.value['ekStartDate-' + platform] !== null ||
                            state.value['discount1-' + platform] !== null ||
                            state.value['discount2-' + platform] !== null
                        );
                    })
                };

                localRules['ekStartDate-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['ekPrice-' + platform] !== null;
                    })
                };

                localRules['ekTaxRateType-' + platform] = {
                    required: requiredIf(() => {
                        return (
                            state.value['ekPrice-' + platform] !== null ||
                            state.value['ekStartDate-' + platform] !== null
                        );
                    })
                };*/

                [
                    'ekPrice',
                    'ekStartDate',
                    'ekTaxRateType',
                    'discount1',
                    'discount2',
                    'discountGroup',
                    'trailingDiscount'
                ].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {};
                });
            });
            return localRules;
        });

        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
            }

            if (
                args.duplicateCheckValues &&
                duplicateCheckValues.value === null
            ) {
                duplicateCheckValues.value = args.duplicateCheckValues;
            }

            if (pairValues.value && duplicateCheckValues.value) {
                prePopulateForm();
            }
        });

        const v$ = useVuelidate(rules, state);

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 4,
                formValues: state.value
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            if (fieldName.startsWith('supplier-')) {
                const env = fieldName.replace('supplier-', '');
                const supplier = suppliers.value[env].supplier.find(
                    (item: any) => event.value === item.value
                );
                if (supplier) {
                    temp['currency-' + env] = supplier.currencyName;
                }
            }

            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 4);
        };

        return {
            state,
            v$,
            handleSubmit,
            selectedEnvironments,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            suppliers,
            locale: i18n.global.locale,
            taxRateTypeOptions: [
                'STANDARD',
                'REDUCED',
                'ZERO',
                'SLIGHTLY_REDUCED',
                'SUPER_REDUCED'
            ].map((item: string) => {
                return {
                    value: item,
                    label: i18n.global.t(
                        'labels.productAdd.taxRateTypeOptions.' + item
                    )
                };
            }),
            platforms: allPlatforms,
            savingInProgress
        };
    }
};
