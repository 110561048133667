import {computed, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import {i18n} from '@/utils/i18n';
import {getSingle, update, removeItem} from '@/services/purchase-orders';
import {DateTime} from 'luxon';
import InputNumber from 'primevue/inputnumber';
import store from '@/store';
import {updateDisposition} from '@/services/products';
import {useConfirm} from 'primevue/useconfirm';
import {ekPriceCalculation, stripTagsAndTruncate} from '@/utils/helpers';

export default {
    components: {
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        InputNumber,
        Panel
    },
    setup() {
        const route = useRoute();
        const loading = ref(false);
        const toast = useToast();
        const purchaseOrderNumber = ref(null);
        const purchaseOrderDetails = ref(null);
        const confirm = useConfirm();

        const dt = ref();

        onMounted(() => {
            purchaseOrderNumber.value = route.params?.purchaseOrderNumber;
            loading.value = true;
            getSingle(purchaseOrderNumber.value)
                .then((data) => {
                    purchaseOrderDetails.value = data.data;
                    purchaseOrderDetails.value.purchaseOrderItems = (
                        purchaseOrderDetails.value?.purchaseOrderItems || []
                    ).map((item: any) => {
                        return {
                            ...item,
                            minimumStockQuantity:
                                item.article?.minimumStockQuantity,
                            targetStockQuantity:
                                item.article?.targetStockQuantity,
                            minMaxDate:
                                item.article?.customAttributes?.art_minmax_datum
                        };
                    });
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(
                            i18n.global.t('messages.noSuchPurchaseOrder')
                        );
                    } else {
                        toast.error(error.message);
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        });

        const getStatisticsForArticleNumber = (
            data: any,
            articleNumber: string
        ) => {
            if (Object.keys(data).indexOf(articleNumber) === -1) {
                return null;
            }

            return data[articleNumber as keyof typeof data];
        };

        const getDateFormatted = (rawValue: number) => {
            return rawValue > 0
                ? DateTime.fromMillis(rawValue)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                      })
                : '';
        };

        const getCurrencyFormatted = (
            rawValue: number,
            currency: string = 'EUR'
        ) => {
            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency
            });

            return formatter.format(rawValue);
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('weclapp-purchase-orders-edit') !==
                -1
            );
        });

        const editingAvailable = computed(
            () =>
                editPermissionAvailable.value &&
                purchaseOrderDetails.value?.status === 'ORDER_ENTRY_IN_PROGRESS'
        );

        const onCellEditComplete = (eventData: any) => {
            if (!editingAvailable.value || eventData.newValue === null) {
                return;
            }

            if (eventData.value === eventData.newValue) {
                toast.warning(i18n.global.t('messages.noChangesDetected'));
                return;
            }

            if (eventData.field === 'quantity') {
                loading.value = true;
                update(purchaseOrderNumber.value, {items: [eventData.newData]})
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        loading.value = false;
                        reloadDataWithStatistics();
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        loading.value = false;
                    });
            } else {
                updateDisposition(eventData.data.articleNumber, {
                    [eventData.field]: eventData.newValue
                })
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        loading.value = false;
                        getSingle(purchaseOrderNumber.value)
                            .then((data) => {
                                purchaseOrderDetails.value = data.data;
                                purchaseOrderDetails.value.purchaseOrderItems =
                                    (
                                        purchaseOrderDetails.value
                                            ?.purchaseOrderItems || []
                                    ).map((item: any) => {
                                        return {
                                            ...item,
                                            minimumStockQuantity:
                                                item.article
                                                    ?.minimumStockQuantity,
                                            targetStockQuantity:
                                                item.article
                                                    ?.targetStockQuantity,
                                            minMaxDate:
                                                item.article?.customAttributes
                                                    ?.art_minmax_datum
                                        };
                                    });
                            })
                            .catch((error) => {
                                if (error.response?.status === 404) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.noSuchPurchaseOrder'
                                        )
                                    );
                                } else {
                                    toast.error(error.message);
                                }
                            })
                            .finally(() => {
                                loading.value = false;
                            });
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        loading.value = false;
                    });
            }
        };

        const bulkUpdateMinMaxDate = () => {
            confirm.require({
                message: i18n.global.t(
                    'messages.minMaxDateBulkUpdateConfirmation'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const resolvedPromisesArray: Array<any> = [];

                    (
                        purchaseOrderDetails.value?.purchaseOrderItems || []
                    ).forEach((element: any) => {
                        if (
                            !element.minMaxDate ||
                            DateTime.fromMillis(element.minMaxDate).startOf(
                                'day'
                            ) < DateTime.now().startOf('day')
                        ) {
                            resolvedPromisesArray.push(
                                Promise.resolve(
                                    updateDisposition(element.articleNumber, {
                                        minMaxDate: new Date()
                                    })
                                )
                            );
                        }
                    });

                    if (resolvedPromisesArray.length < 1) {
                        toast.warning(
                            i18n.global.t('messages.noChangesDetected')
                        );
                        return;
                    }
                    loading.value = true;
                    Promise.all(resolvedPromisesArray)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loading.value = false;
                            getSingle(purchaseOrderNumber.value)
                                .then((data) => {
                                    purchaseOrderDetails.value = data.data;
                                    purchaseOrderDetails.value.purchaseOrderItems =
                                        (
                                            purchaseOrderDetails.value
                                                ?.purchaseOrderItems || []
                                        ).map((item: any) => {
                                            return {
                                                ...item,
                                                minimumStockQuantity:
                                                    item.article
                                                        ?.minimumStockQuantity,
                                                targetStockQuantity:
                                                    item.article
                                                        ?.targetStockQuantity,
                                                minMaxDate:
                                                    item.article
                                                        ?.customAttributes
                                                        ?.art_minmax_datum
                                            };
                                        });
                                })
                                .catch((error) => {
                                    if (error.response?.status === 404) {
                                        toast.error(
                                            i18n.global.t(
                                                'messages.noSuchPurchaseOrder'
                                            )
                                        );
                                    } else {
                                        toast.error(error.message);
                                    }
                                })
                                .finally(() => {
                                    loading.value = false;
                                });
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                            loading.value = false;
                        });
                }
            });
        };

        const reloadDataWithStatistics = () => {
            getSingle(purchaseOrderNumber.value, false)
                .then((data) => {
                    const oldStatistics = Object.assign(
                        purchaseOrderDetails.value.statistics,
                        {}
                    );

                    purchaseOrderDetails.value = Object.assign(
                        purchaseOrderDetails.value,
                        data.data
                    );

                    purchaseOrderDetails.value.purchaseOrderItems = (
                        purchaseOrderDetails.value?.purchaseOrderItems || []
                    ).map((item: any) => {
                        return {
                            ...item,
                            minimumStockQuantity:
                                item.article?.minimumStockQuantity,
                            targetStockQuantity:
                                item.article?.targetStockQuantity,
                            minMaxDate:
                                item.article?.customAttributes?.art_minmax_datum
                        };
                    });

                    for (const key in purchaseOrderDetails.value.statistics) {
                        purchaseOrderDetails.value.statistics[key] =
                            Object.assign(
                                oldStatistics[key],
                                purchaseOrderDetails.value.statistics[key]
                            );
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(
                            i18n.global.t('messages.noSuchPurchaseOrder')
                        );
                    } else {
                        toast.error(error.message);
                    }
                });
        };

        const handleRemoveItemClick = (data: any) => {
            confirm.require({
                message: i18n.global.t(
                    'messages.removePurchaseOrderItemConfirmation',
                    {positionNumber: data.positionNumber}
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    removeItem(purchaseOrderNumber.value, data.id)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );

                            reloadDataWithStatistics();
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            loading.value = false;
                        });
                }
            });
        };

        return {
            purchaseOrderNumber,
            loading,
            purchaseOrderDetails,
            dt,
            getStatisticsForArticleNumber,
            getDateFormatted,
            onCellEditComplete,
            locale: i18n.global.locale,
            editingAvailable,
            getCurrencyFormatted,
            bulkUpdateMinMaxDate,
            ekPriceCalculation,
            stripTagsAndTruncate,
            handleRemoveItemClick
        };
    }
};
