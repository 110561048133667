<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <ScrollPanel style="height: calc(100vh - 28rem)">
            <div class="grid formgrid p-fluid mt-3 mb-3">
                <div class="col field">
                    <label
                        >{{ $t('labels.productPromotions.ekPromoSwitch') }}
                    </label>
                    <app-checkbox
                        v-if="!isBulkEdit"
                        v-model="v$.ekPromoSwitch.$model"
                        :binary="true"
                    />
                    <TriStateCheckbox
                        v-else
                        v-model="v$.ekPromoSwitch.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="col field">
                    <label
                        >{{ $t('labels.productPromotions.vkPromoSwitch') }}
                    </label>

                    <app-checkbox
                        v-if="!isBulkEdit"
                        v-model="v$.vkPromoSwitch.$model"
                        :binary="true"
                    />
                    <TriStateCheckbox
                        v-else
                        v-model="v$.vkPromoSwitch.$model"
                    ></TriStateCheckbox>
                </div>
                <div class="col field">
                    <label
                        >{{ $t('labels.productPromotions.promoFlag') }}
                    </label>

                    <app-checkbox
                        v-if="!isBulkEdit"
                        v-model="v$.promoFlag.$model"
                        :binary="true"
                    />
                    <TriStateCheckbox
                        v-else
                        v-model="v$.promoFlag.$model"
                    ></TriStateCheckbox>
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.ekPromoText') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.ekPromoText"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.ekPromoText"
                        v-model="v$.ekPromoText.$model"
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.vkPromoText') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.vkPromoText"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.vkPromoText"
                        v-model="v$.vkPromoText.$model"
                    />
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label
                        >{{
                        $t('labels.productDescription.internalNoteSupplySource')
                        }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.internalNoteSupplySource"
                        ></InputSwitch>
                    </div>

                    <tiny
                        api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                        v-model="v$.internalNoteSupplySource.$model"
                        :disabled="isBulkEdit && !bulkEditSwitches.internalNoteSupplySource"
                        :init="{
                    height: 200,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true
                }"
                    ></tiny>
                    <small>
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.internalNoteSupplySource})
                        }}</small
                    >
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.internalNoteSupplySource.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productDescription.internalNote') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.internalNote"
                        ></InputSwitch>
                    </div>

                    <tiny
                        api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                        v-model="v$.internalNote.$model"
                        :disabled="isBulkEdit && !bulkEditSwitches.internalNote"
                        :init="{
                    height: 200,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true
                }"
                    ></tiny>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.internalNote.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.ekPromoStart') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.ekPromoStart"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.ekPromoStart"
                        v-model="v$.ekPromoStart.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.ekPromoEnd') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.ekPromoEnd"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.ekPromoEnd"
                        v-model="v$.ekPromoEnd.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.vkPromoStart') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.vkPromoStart"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.vkPromoStart"
                        v-model="v$.vkPromoStart.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.vkPromoEnd') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.vkPromoEnd"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.vkPromoEnd"
                        v-model="v$.vkPromoEnd.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.promoCode') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.promoCode"
                        ></InputSwitch>
                    </div>

                    <p-dropdown
                        :disabled="isBulkEdit && !bulkEditSwitches.promoCode"
                        v-model="v$.promoCode.$model"
                        :options="promoCodeOptions"
                        optionLabel="label"
                        optionValue="value"
                        @change="setDropdownValue('promoCode', $event)"
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.blackWeekMax') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.blackWeekMax"
                        ></InputSwitch>
                    </div>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.blackWeekMax"
                        v-model="v$.blackWeekMax.$model"
                        class="full-width"
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.uvpLockStart') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.uvpLockStart"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.uvpLockStart"
                        v-model="v$.uvpLockStart.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productPromotions.uvpLockEnd') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.uvpLockEnd"
                        ></InputSwitch>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.uvpLockEnd"
                        v-model="v$.uvpLockEnd.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
            </div>
        </ScrollPanel>
        <div class="flex justify-content-end" v-if="!isBulkEdit">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</div>
