import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {allEnvs: input.allEnvs},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ),
            filterConjunction: input.filterConjunction || 'and'
        },
        input.platform
            ? {
                  platform: input.platform
              }
            : {}
    );

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/price-imports?' + queryString);
};

export const getSupplierConfiguration = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/price-supplier-configuration');
};

export const updateSupplierConfiguration = (
    id: any,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/price-supplier-configuration/' + id, payload);
};

export const getSingle = (
    id: any,
    includeRegular: boolean = true,
    includeIrregular: boolean = true
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/price-imports/' + id, {
        params: {
            includeRegular,
            includeIrregular
        }
    });
};

export const downloadFile = (
    importId: string,
    fileId: string,
    transformed: boolean = false
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/price-imports/' +
            importId +
            '/download-source-file/' +
            fileId +
            (transformed ? '?transformed' : ''),
        {
            responseType: 'blob'
        }
    );
};
