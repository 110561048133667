<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    :modal="true"
>
    <div class="confirmation-content">
        <div>{{ $t('messages.cormirmUnsavedChages') }}</div>
    </div>
    <template #footer>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" @click="handleAnswer(false, $event)">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button severity="success" @click="handleAnswer(true, $event)">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</PrimeDialog>
