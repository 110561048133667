import {IUser} from '@/interfaces/user';
import router from '@/router/index';
import {clearAuthTokens, setAuthTokens} from 'axios-jwt';

export default {
    login: (
        context: any,
        payload: {access_token: string; refresh_token: string}
    ): void => {
        setAuthTokens({
            accessToken: payload.access_token,
            refreshToken: payload.refresh_token
        });
        router.replace('/');
    },
    redirectToExternalAuth: (
        context: any,
        payload: {state: string; redirectUrl: string}
    ): void => {
        localStorage.setItem('azureState', payload.state);
        window.location.href = payload.redirectUrl;
    },
    getUser: (context: any, payload: IUser): void => {
        context.commit('setUser', payload);
    },
    setPlatform: (context: any, payload: string): void => {
        context.commit('setPlatform', payload);
        localStorage.setItem('platform', payload);
    },
    setAutoSaveProductDescription: (context: any, payload: string): void => {
        context.commit('setAutoSaveProductDescription', payload);
        localStorage.setItem('auto-save-product-description', payload);
    },
    setPlatforms: (
        context: any,
        payload: Array<{label: string; value: string}>
    ): void => {
        context.commit('setPlatforms', payload);
    },
    logout: async (context: any): Promise<void> => {
        await context.commit('setUser', null);
        localStorage.removeItem('azureState');
        clearAuthTokens();
        router.replace('/login');
    }
};
