import {computed, onMounted, ref, toRefs, watch} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {getAll} from '@/services/metadata';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {helpers, required} from '@vuelidate/validators';
import countries from 'i18n-iso-countries';
import Chips from 'primevue/chips';
import store from '@/store';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        selectedEnvironments: Object,
        pairValues: {
            type: Object,
            default: null
        },
        allPlatforms: Object,
        singleEnvironment: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-fieldset': Fieldset,
        'p-dropdown': Dropdown,
        InputNumber,
        Chips
    },
    setup(props: any, context: any) {
        const {selectedEnvironments, allPlatforms, singleEnvironment} =
            toRefs(props);
        const pairValues = ref(null);
        const submitted = ref(false);
        const serverData = ref({});

        onMounted(() => {
            getAll(
                ['articleCategory', 'manufacturer', 'customsTariffNumber'],
                !singleEnvironment.value
            ).then((data: any) => {
                if (singleEnvironment.value) {
                    const test = data.data['customsTariffNumber'].map(
                        (item: {
                            id: string;
                            name: string;
                            description: string | null;
                        }) => {
                            return {
                                name: item.description
                                    ? stripTagsAndTruncate(
                                          item.description,
                                          100,
                                          '...'
                                      ) +
                                      ' ( ' +
                                      item.name +
                                      ' ) '
                                    : item.name,
                                id: item.id
                            };
                        }
                    );
                    data.data['customsTariffNumber'] = test;
                    serverData.value = {
                        [store.getters['auth/platform']]: data.data
                    };
                } else {
                    Object.keys(data.data).forEach((env: string) => {
                        const test = data.data[env]['customsTariffNumber'].map(
                            (item: {
                                id: string;
                                name: string;
                                description: string | null;
                            }) => {
                                return {
                                    name: item.description
                                        ? stripTagsAndTruncate(
                                              item.description,
                                              100,
                                              '...'
                                          ) +
                                          ' ( ' +
                                          item.name +
                                          ' ) '
                                        : item.name,
                                    id: item.id
                                };
                            }
                        );
                        data.data[env]['customsTariffNumber'] = test;
                    });

                    serverData.value = data.data;
                }
            });
        });

        const fieldDefinitions: any = [
            {name: 'countryOfOrigin', value: null},

            {
                name: 'matchCode',
                value: null
            },
            {
                name: 'vkPrice',
                value: null
            },
            {
                name: 'vkStartDate',
                value: new Date()
            },
            {
                name: 'procurementLeadDays',
                value: null
            },
            {
                name: 'minimumStockQuantity',
                value: null
            },
            {
                name: 'targetStockQuantity',
                value: null
            },
            {
                name: 'articleCategory',
                value: null
            },
            {
                name: 'manufacturer',
                value: null
            },
            {
                name: 'customsTariffNumber',
                value: null
            },
            {
                name: 'serialNumberRequired',
                value: null
            },
            {
                name: 'serialNumberIntern',
                value: false
            },
            {
                name: 'articleWidth',
                value: null
            },
            {
                name: 'articleHeight',
                value: null
            },
            {
                name: 'articleLength',
                value: null
            },
            {
                name: 'articleNetWeight',
                value: null
            },
            {
                name: 'articleGrossWeight',
                value: null
            },
            {
                name: 'articleMetatags',
                value: []
            }
        ];

        const stateDef: any = {};

        allPlatforms.value.forEach(
            (platform: {
                label: string;
                value: string;
                threeLetterId: string;
            }) => {
                fieldDefinitions.forEach(
                    (fieldDefinition: {name: string; value: any}) => {
                        const key = fieldDefinition.name + '-' + platform.value;
                        stateDef[key as keyof typeof stateDef] =
                            fieldDefinition.name === 'serialNumberRequired' &&
                            (platform?.threeLetterId === 'vdl' ||
                                platform?.threeLetterId === 'vdd')
                                ? true
                                : fieldDefinition.value;
                    }
                );
            }
        );

        const state = ref(stateDef);

        const rules = computed(() => {
            const localRules: any = {};
            selectedEnvironments.value.forEach((platform: string) => {
                /*['procurementLeadDays'].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    };
                });*/
                /*localRules['vkPrice-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['vkStartDate-' + platform] !== null;
                    })
                };

                localRules['vkStartDate-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['vkPrice-' + platform] !== null;
                    })
                };*/

                const resolvedPlatformObject = allPlatforms.value.find(
                    (p: {value: string}) => p.value === platform
                );

                [
                    'customsTariffNumber',
                    'countryOfOrigin',
                    'serialNumberRequired'
                ].forEach((item: string) => {
                    const key = item + '-' + platform;

                    localRules[key as keyof typeof localRules] =
                        resolvedPlatformObject?.threeLetterId === 'ttl' ||
                        resolvedPlatformObject?.threeLetterId === 'ttd'
                            ? {
                                  required: helpers.withMessage(
                                      i18n.global.t('messages.valueIsRequired'),
                                      required
                                  )
                              }
                            : {};
                });

                [
                    'manufacturer',
                    'vkPrice',
                    'vkStartDate',
                    'matchCode',
                    'minimumStockQuantity',
                    'targetStockQuantity',
                    'articleCategory',
                    //'customsTariffNumber',
                    //'serialNumberRequired',
                    'serialNumberIntern',
                    'articleWidth',
                    'articleHeight',
                    'articleLength',
                    'articleNetWeight',
                    'articleGrossWeight',
                    'articleMetatags',
                    'procurementLeadDays'
                ].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {};
                });
            });
            return localRules;
        });

        const v$ = useVuelidate(rules, state);

        const prePopulateForm = () => {
            allPlatforms.value.forEach(
                (platform: {label: string; value: string}) => {
                    state.value['vkPrice-' + platform.value] =
                        pairValues.value?.vkPrice ||
                        state.value['vkPrice-' + platform.value];

                    state.value['countryOfOrigin-' + platform.value] =
                        pairValues.value?.countryOfOriginCode || null;

                    state.value['articleCategory-' + platform.value] =
                        pairValues.value?.articleCategoryId || null;

                    state.value['customsTariffNumber-' + platform.value] =
                        pairValues.value?.customsTariffNumberId || null;

                    state.value['manufacturer-' + platform.value] =
                        pairValues.value?.manufacturerId || null;

                    state.value['procurementLeadDays-' + platform.value] =
                        pairValues.value?.procurementLeadDays || null;

                    state.value['minimumStockQuantity-' + platform.value] =
                        pairValues.value?.minimumStockQuantity || 0;

                    state.value['targetStockQuantity-' + platform.value] =
                        pairValues.value?.targetStockQuantity || 0;

                    state.value['matchCode-' + platform.value] =
                        pairValues.value?.matchCode || null;

                    if (
                        pairValues.value &&
                        typeof pairValues.value.serialNumberRequired !==
                            'undefined'
                    ) {
                        state.value['serialNumberRequired-' + platform.value] =
                            !!pairValues.value?.serialNumberRequired;
                    }

                    if (
                        pairValues.value &&
                        typeof pairValues.value.customAttributes !== 'undefined'
                    ) {
                        state.value['serialNumberIntern-' + platform.value] =
                            !!pairValues.value?.customAttributes
                                ?.SN_AUTO_GENERATE;
                    }

                    state.value['articleGrossWeight-' + platform.value] =
                        pairValues.value?.articleGrossWeight || null;

                    state.value['articleNetWeight-' + platform.value] =
                        pairValues.value?.articleNetWeight || null;

                    state.value['articleLength-' + platform.value] = pairValues
                        .value?.articleLength
                        ? pairValues.value?.articleLength * 100
                        : null;

                    state.value['articleWidth-' + platform.value] = pairValues
                        .value?.articleWidth
                        ? pairValues.value?.articleWidth * 100
                        : null;

                    state.value['articleHeight-' + platform.value] = pairValues
                        .value?.articleHeight
                        ? pairValues.value?.articleHeight * 100
                        : null;
                }
            );

            for (const key in state.value) {
                if (
                    Object.keys(pairValues.value).includes(key) &&
                    (!key.startsWith('vkStartDate') ||
                        pairValues.value[key] > 0)
                ) {
                    state.value[key] = pairValues.value[key];
                }
            }
        };

        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 3,
                formValues: state.value
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 3);
        };

        return {
            state,
            v$,
            handleSubmit,
            selectedEnvironments,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            serverData,
            locale: i18n.global.locale,
            platforms: allPlatforms,
            countryOptions: Object.entries(
                countries.getNames(i18n.global.locale) ||
                    countries.getNames('en')
            ).map((item) => {
                return {
                    label: item[1],
                    value: item[0]
                };
            }),
            singleEnvironment
        };
    }
};
