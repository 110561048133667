<Panel>
    <template #header>
        <div class="font-bold">{{ticketDetails?.subject}}</div>
    </template>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid">
            <div class="col">
                <label>{{ $t('labels.customer') }} </label>

                <div>
                    <a
                        v-if="ticketDetails?.customer"
                        :href="ticketDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + ticketDetails.partyId"
                        target="_blank"
                        >{{ ticketDetails.customer.company ??
                        ticketDetails.customer.firstName + ' ' +
                        ticketDetails.customer.lastName }}</a
                    >

                    <template v-if="ticketDetails.email">
                        ({{ ticketDetails.email }})</template
                    >
                </div>
            </div>
            <div class="col">
                <label>{{ $t('labels.assignedUser') }} </label>

                <div class="font-semibold">
                    {{ ticketDetails.assignedUser }}
                </div>
            </div>
            <div class="col">
                <label>{{ $t('labels.ticketChannel') }} </label>
                <div class="font-semibold">
                    {{ ticketDetails.ticketChannel }}
                </div>
            </div>
            <div class="col">
                <label>{{ $t('labels.article') }} </label>

                <div class="font-semibold">
                    <a
                        v-if="ticketDetails.ticketArticle"
                        :href="ticketDetails.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + ticketDetails.ticketArticle.id"
                        target="_blank"
                        >{{ ticketDetails.ticketArticle.name }} [{{
                        ticketDetails.ticketArticle.articleNumber }}]</a
                    >
                </div>
            </div>
        </div>
        <div class="grid mt-5">
            <div
                class="col field p-fluid"
                v-for="(customAtrributeKey, translationKey) in ({
        'externalRMA': 'ticket_external_rma',
        'internalComment': 'ticket_internal_comment',
        'serialNumber': 'ticket_serialnumber',
        'inboundTracking': 'ticket_inbound_tracking'
    })"
            >
                <label
                    >{{ $t('labels.ticket.additional.' + translationKey) }}
                </label>

                <p-textarea v-model.trim="v$[customAtrributeKey].$model">
                </p-textarea>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$[customAtrributeKey].$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <Panel>
            <template #header>
                <div class="font-bold">{{ $t('labels.description') }}</div>
            </template>
            <div class="grid">
                <div class="col field p-fluid">
                    <label>{{ $t('labels.mail.subject') }} </label>

                    <p-inputtext v-model.trim="v$.subject.$model">
                    </p-inputtext>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.subject.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid">
                <div class="col field p-fluid">
                    <label>{{ $t('labels.description') }} </label>
                    <tiny
                        api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                        v-model="v$.description.$model"
                        :init="{
height: 200,
menubar: false,
plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
browser_spellcheck: true
}"
                    ></tiny>

                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.description.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </Panel>
        <div class="flex justify-content-end mt-5">
            <p-button
                :disabled="savingInProgress || !v$.$anyDirty"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</Panel>
