import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"vertical-align":"middle"} }
const _hoisted_2 = ["href"]
const _hoisted_3 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_4 = { style: {"vertical-align":"middle"} }
const _hoisted_5 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_6 = { class: "mt-2" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { style: {"vertical-align":"middle"} }
const _hoisted_9 = { style: {"vertical-align":"middle"} }
const _hoisted_10 = { style: {"vertical-align":"middle"} }
const _hoisted_11 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "font-semibold" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Badge = _resolveComponent("Badge")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Tag = _resolveComponent("Tag")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_DataTable = _resolveComponent("DataTable")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_DataTable, {
      class: "p-datatable-sm text-sm",
      stripedRows: "",
      rowClass: _ctx.rowClass,
      paginator: "",
      rows: 10,
      lazy: "",
      scrollable: _ctx.scrollableHeight !== null,
      scrollHeight: _ctx.scrollableHeight,
      ref: "dt",
      dataKey: "id",
      totalRecords: _ctx.totalRecords,
      value: _ctx.products,
      responsiveLayout: "scroll",
      "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
      "rows-per-page-options": [10,20,50,100,200],
      "current-page-report-template": "{first} to {last} of {totalRecords}",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
      filterDisplay: "row",
      onPage: _cache[3] || (_cache[3] = $event => (_ctx.onPage($event))),
      onFilter: _cache[4] || (_cache[4] = $event => (_ctx.onFilter($event))),
      onSort: _cache[5] || (_cache[5] = $event => (_ctx.onSort($event))),
      expandedRows: _ctx.expandedRows,
      "onUpdate:expandedRows": _cache[6] || (_cache[6] = $event => ((_ctx.expandedRows) = $event)),
      selection: _ctx.selectedProducts,
      "onUpdate:selection": _cache[7] || (_cache[7] = $event => ((_ctx.selectedProducts) = $event)),
      onRowSelect: _ctx.onRowSelect,
      onRowSelectAll: _ctx.onRowSelectAll,
      onRowUnselect: _ctx.onRowUnselect,
      onRowUnselectAll: _ctx.onRowUnselectAll
    }, {
      default: _withCtx(() => [
        (_ctx.selectionMode)
          ? (_openBlock(), _createBlock(_component_Column, {
              key: 0,
              selectionMode: _ctx.selectionMode,
              headerStyle: "width: 3em"
            }, {
              header: _withCtx(() => [
                _createVNode(_component_Badge, {
                  value: _ctx.mergedSelection.length
                }, null, 8, ["value"]),
                (_ctx.mergedSelection.length > 0)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-times-circle text-red-600",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["selectionMode"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Column, {
          field: "articleNumber",
          sortable: true,
          header: _ctx.$t('labels.articleNumber'),
          style: {"min-width":"12rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: "#"
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_1, [
              _createElementVNode("a", {
                href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                target: "_blank"
              }, _toDisplayString(data.articleNumber), 9, _hoisted_2)
            ]),
            (data.shopwareData && data.articleNumber !== data.shopwareData.productNumber)
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.articleNumber'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "name",
          sortable: true,
          style: {"min-width":"12rem"},
          header: _ctx.$t('labels.name')
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: _ctx.$t('labels.name')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(data.name || data.shopwareData?.name), 1),
            (data.shopwareData && data?.name !== data?.shopwareData?.name)
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.name'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                return (_openBlock(), _createBlock(_component_Tag, {
                  severity: "info",
                  class: "mt-1 mr-1"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ])
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "description",
          style: {"min-width":"12rem"},
          header: _ctx.$t('labels.productDescription.webshopText')
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: _ctx.$t('labels.productDescription.webshopText')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, _toDisplayString(_ctx.stripTagsAndTruncate(data.shopwareData?.description, 200,
                '...')), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "productCategory",
          header: _ctx.$t('labels.articleCategory'),
          style: {"min-width":"12rem"}
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-sm",
              placeholder: _ctx.$t('labels.articleCategory')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            (data?.shopwareData?.categories)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.shopwareData.categories, (element) => {
                    return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element.name), 1))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "ean",
          sortable: true,
          header: _ctx.$t('labels.ean'),
          style: {"min-width":"12rem"}
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: _ctx.$t('labels.ean')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_8, _toDisplayString(data.ean || data.shopwareData?.ean), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "manufacturerPartNumber",
          sortable: true,
          header: _ctx.$t('labels.mpn'),
          style: {"min-width":"12rem"}
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: _ctx.$t('labels.mpn')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_9, _toDisplayString(data.manufacturerPartNumber || data.shopwareData?.mpn), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "manufacturerName",
          sortable: true,
          header: _ctx.$t('labels.manufacturer'),
          style: {"min-width":"12rem"}
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_InputText, {
              type: "text",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
              class: "p-column-filter text-xs",
              placeholder: _ctx.$t('labels.manufacturer')
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
          ]),
          body: _withCtx(({data}) => [
            _createElementVNode("span", _hoisted_10, _toDisplayString(data.manufacturerName || data.shopwareData?.manufacturer), 1),
            (data.shopwareData && (data.manufacturerName || data.shopwareData.manufacturer) && (data.manufacturerName !== data.shopwareData.manufacturer))
              ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_11, null, 512)), [
                  [
                    _directive_tooltip,
                    _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.manufacturer'),
                    void 0,
                    { top: true }
                  ]
                ])
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.$t('labels.stock'),
          field: "availableStock",
          style: {"min-width":"8rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel, filterCallback}) => [
            _createVNode(_component_TriStateCheckbox, {
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              onChange: $event => (filterCallback())
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
          ]),
          body: _withCtx(({data}) => [
            _createTextVNode(_toDisplayString(data.shopwareData?.availableStock), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "tags",
          header: _ctx.$t('labels.tags'),
          style: {"min-width":"10rem"},
          filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
        }, {
          filter: _withCtx(({filterModel,filterCallback}) => [
            _createVNode(_component_p_multiselect, {
              class: "text-sm",
              modelValue: filterModel.value,
              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
              options: _ctx.availableProductTags,
              maxSelectedLabels: 3,
              optionLabel: "name",
              optionValue: "name",
              filter: "",
              onChange: $event => (filterCallback()),
              style: {"min-width":"5rem","max-width":"20rem"}
            }, {
              option: _withCtx(({option}) => [
                _createTextVNode(_toDisplayString(option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')), 1)
              ]),
              value: _withCtx((content) => [
                _createTextVNode(_toDisplayString((content.value || []).map((item) =>
                    item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                    .join(', ')), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "options", "onChange"])
          ]),
          body: _withCtx(({data}) => [
            (data?.tags)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                    return (_openBlock(), _createElementBlock("li", null, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')), 1)
                    ]))
                  }), 256))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["rowClass", "scrollable", "scrollHeight", "totalRecords", "value", "filters", "expandedRows", "selection", "onRowSelect", "onRowSelectAll", "onRowUnselect", "onRowUnselectAll"])
  ], 64))
}