import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {bulkProcessOnlineStatus} from '@/services/products';
import {i18n} from '@/utils/i18n';
import {getAll} from '@/services/product-categories';
import Tree from 'primevue/tree';
import Calendar from 'primevue/calendar';
import {isShopware5} from '@/utils/helpers';
import store from '@/store';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        TabPanel,
        TabView,
        ProductList,
        'p-button': Button,
        LoadingPlugin,
        'p-tree': Tree,
        DataTable,
        Column,
        Calendar,
        'p-checkbox': Checkbox,
        Panel,
        ScrollPanel
    },
    props: {
        products: Array,
        displayDialog: Boolean,
        productFilters: Object,
        productTotalRecords: Number,
        isRemove: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isRemove = ref(false);
        const isFilterRelated = ref(false);
        const selectedCategories = ref(null);
        const loading = ref(false);
        const selectedCategoriesLabels = ref([]);

        const toast = useToast();

        const categories = ref([]);
        const immediateExecution = ref(true);
        const startTime = ref(null);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isRemove.value = args.isRemove || false;
            isFilterRelated.value = args.isFilterRelated || false;
            if (showDialog.value) {
                loadCategories();
            }
        });

        const loadCategories = () => {
            getAll()
                .then((data) => {
                    categories.value = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    (isRemove.value || item.childCount === 0) &&
                                    item.active,
                                style: item.active
                                    ? 'color: var(--text-color);'
                                    : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const onNodeExpand = (node: any) => {
            if (!node.children) {
                expandNode(node);
            }
        };

        const expandNode = (node: any) => {
            getAll(node.key)
                .then((data) => {
                    node.children = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    (isRemove.value || item.childCount === 0) &&
                                    item.active,

                                style: item.active
                                    ? 'color: var(--text-color);'
                                    : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const onNodeSelect = (node: any) => {
            selectedCategoriesLabels.value.push(node);
        };
        const onNodeUnselect = (node: any) => {
            selectedCategoriesLabels.value =
                selectedCategoriesLabels.value.filter(
                    (e) => e.label !== node.label
                );
        };

        const closeResponsive = (forceResfresh: boolean = false) => {
            selectedCategories.value = null;
            selectedCategoriesLabels.value = [];
            context.emit('close-dialog', forceResfresh);
        };

        const showSummaryTab = computed(() => {
            return (
                isFilterRelated.value ||
                (products.value && products.value.length > 0)
            );
        });

        const isShopware5Instance = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            const resolvedPlatform = platforms.find(
                (item: any) => item.value === currentPlatform
            );

            return isShopware5(resolvedPlatform);
        });

        const onBulkProcessOnlineStatus = async () => {
            loading.value = true;

            bulkProcessOnlineStatus(
                products.value,
                productFilters.value,
                selectedCategories.value
                    ? Object.keys(selectedCategories.value)
                    : [],
                !isRemove.value,
                isFilterRelated.value,
                immediateExecution.value ? null : startTime.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || !immediateExecution.value
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            categories,
            showDialog,
            loading,
            selectedCategories,
            closeResponsive,
            onBulkProcessOnlineStatus,
            showSummaryTab,
            onNodeExpand,
            onNodeSelect,
            onNodeUnselect,
            selectedCategoriesLabels,
            isFilterRelated,
            immediateExecution,
            startTime,
            isShopware5Instance,
            isRemove
        };
    }
};
