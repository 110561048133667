<!-- Content Header (Page header) -->
<loading v-model:active="loading" />
<Panel
    class="panel-container-datatable"
    :header="$t('labels.salesOrder.disposition')"
>
    <div class="grid p-fluid mb-3">
        <div class="col">
            <span class="p-input-icon-left">
                <IconField iconPosition="left">
                    <InputIcon class="pi pi-search"> </InputIcon>
                    <InputText
                        class="text-sm"
                        v-model.trim="articleNumber"
                        :placeholder="$t('labels.articleNumber') + ', ' + $t('labels.commaSeparated')"
                        @keydown.enter="handleSearch"
                    />
                </IconField>
            </span>
        </div>
    </div>
    <DataTable
        v-if="foundProducts.length > 0"
        class="p-datatable-sm"
        stripedRows
        :lazy="false"
        editMode="cell"
        @cell-edit-complete="onCellEditComplete"
        ref="dt"
        dataKey="id"
        :value="foundProducts"
        responsiveLayout="scroll"
    >
        <Column
            field="title"
            style="min-width: 9rem"
            :header="$t('labels.article')"
        >
            <template #body="{data}">
                <div class="text-sm">
                    {{data.name}} [<a
                        :href="data?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                        target="_blank"
                        >{{ data.articleNumber }}</a
                    >]
                </div>
                <div class="text-xs">
                    <b
                        >{{$t('labels.createdAt')}}
                        {{getDateFormatted(data?.createdDate) }}<span
                            v-if="ekPriceCalculation(data)"
                            class="ml-3"
                            >EK {{ekPriceCalculation(data)}}</span
                        ></b
                    >
                </div>
            </template>
        </Column>
        <Column field="minimumStockQuantity" header="Min">
            <template #body="{data}">
                <div style="cursor: pointer">
                    <Skeleton
                        v-if="singleEditInProgress === data.articleNumber"
                    ></Skeleton>
                    <template v-else>
                        {{ data.minimumStockQuantity }}
                    </template>
                </div>
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    v-model="data[field]"
                    showButtons
                    :min="0"
                    :disabled="!editPermissionAvailable"
                />
            </template>
        </Column>
        <Column field="targetStockQuantity" header="Max">
            <template #body="{data}">
                <div style="cursor: pointer">
                    <Skeleton
                        v-if="singleEditInProgress === data.articleNumber"
                    ></Skeleton>
                    <template v-else> {{ data.targetStockQuantity }} </template>
                </div>
            </template>
            <template #editor="{ data, field }">
                <InputNumber
                    :locale="locale"
                    v-model="data[field]"
                    showButtons
                    :min="0"
                    :disabled="!editPermissionAvailable"
                />
            </template>
        </Column>
        <Column field="minMaxDate">
            <template #header>
                MinMaxAkt
                <i
                    class="pi pi-refresh"
                    style="padding-left: 7px; cursor: pointer"
                    @click="bulkUpdateMinMaxDate"
                ></i>
            </template>
            <template #body="{data}">
                <div v-if="data.minMaxDate">
                    <Skeleton
                        v-if="singleEditInProgress === data.articleNumber"
                    ></Skeleton>
                    <template v-else>
                        {{ getDateFormatted(data.minMaxDate) }}
                    </template>
                </div>
            </template>
        </Column>
        <Column header="Dispo">
            <template #body="{data}">
                <Skeleton v-if="!data?.statistics"></Skeleton>
                <template v-else>
                    {{parseInt(data?.statistics?.extraInfo?.orderedQuantity ||
                    0)+ parseInt(data?.statistics?.extraInfo?.stockQuantity ||
                    0) -
                    parseInt(data?.statistics?.extraInfo?.plannedSalesQuantity
                    || 0) }}
                </template>
            </template>
        </Column>
        <Column header="VK-RG (3M)">
            <template #body="{data}">
                <Skeleton v-if="!data?.statistics"></Skeleton>
                <template v-else>
                    {{data?.statistics?.salesInvoices?.lastThreeMonthsItemCount}}
                    / {{data?.statistics?.salesInvoices?.lastThreeMonthsCount}}
                </template>
            </template>
        </Column>
        <Column header="VK-RG (12M)">
            <template #body="{data}">
                <Skeleton v-if="!data?.statistics"></Skeleton>
                <template v-else>
                    {{data?.statistics?.salesInvoices?.itemCount}} /
                    {{data?.statistics?.salesInvoices?.count}}
                </template>
            </template>
        </Column>
        <Column header="BE (12M)">
            <template #body="{data}">
                <Skeleton v-if="!data?.statistics"></Skeleton>
                <template v-else>
                    <div>{{data?.statistics?.purchaseOrders?.itemCount}}</div>
                </template>
            </template>
        </Column>
        <Column header="Letzte BE">
            <template #body="{data}">
                <div>
                    <div>
                        {{getDateFormatted(data?.statistics?.purchaseOrders?.lastPurchaseOrderDate)}}
                    </div>
                </div>
            </template>
        </Column>
    </DataTable>
</Panel>
