import {useDropzone} from 'vue3-dropzone';
import {
    saveFiles,
    runValidation,
    getFileTempate,
    removeFile,
    schedulePriceImport
} from '@/services/import';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import {ref, computed, onMounted, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Loading from 'vue-loading-overlay';
import ValidationGrid from '@/components/grid/import-wizard/validation-grid.vue';
import HeaderMappingGrid from '@/components/grid/import-wizard/header-mapping-grid.vue';
import {i18n} from '@/utils/i18n';
import router from '@/router';
import FileSaver from 'file-saver';
import {evaluateChildNodes, wizardOptions} from '@/utils/import-wizard/options';
import store from '@/store';
import Panel from 'primevue/panel';
import SupplierAutoComplete from '@/components/export-wizard/weclapp/supplier/supplier.vue';

export default {
    name: 'ImportWizard',
    components: {
        'p-button': Button,
        InputText,
        loading: Loading,
        ValidationGrid,
        HeaderMappingGrid,
        PrimeDialog: Dialog,
        Panel,
        SupplierAutoComplete
    },
    setup() {
        const toast = useToast();
        const acceptFiles = ref([]);
        const importResult = ref(false);
        const chosenSupplier = ref(null);

        const savedFileId = ref(null);

        const rowsToSkip = ref([]);

        const isLoading = ref(false);
        const fileScheduledForImportTextPanel = ref(null);

        const showPlatformConfirmationDialog = ref(false);

        const confirmationInputField = ref(null);
        const confirmationInputValue = ref('');

        const workflowIdentifier = 'weclapp-price-wizard-add';

        onMounted(() => {
            fileScheduledForImportTextPanel.value.children[0].addEventListener(
                'click',
                function () {
                    router.push('/import-logs');
                },
                false
            );
        });

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            importResult.value = false;
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const uploadFile = async () => {
            isLoading.value = true;
            try {
                const response = await saveFiles(
                    acceptFiles.value,
                    workflowIdentifier
                );

                savedFileId.value = response.saveResult.id;
            } catch (error: any) {
                toast.error(error.message);
                acceptFiles.value.length = 0;
                savedFileId.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const validateFile = async () => {
            isLoading.value = true;
            try {
                const response = await runValidation(savedFileId.value);
                rowsToSkip.value = response.rowsToSkip;
                if (
                    response.rowsToSkip &&
                    Object.keys(response.rowsToSkip).length > 0
                ) {
                    toast.warning(
                        i18n.global.t('messages.validationErrorsInFile')
                    );
                } else {
                    toast.success(
                        i18n.global.t('messages.fileValidatedSuccessfully')
                    );
                    showPlatformConfirmationDialog.value = true;
                }
            } catch (error: any) {
                toast.error(error.message);
                savedFileId.value = null;
                acceptFiles.value.length = 0;
            } finally {
                isLoading.value = false;
            }
        };

        const downloadFileTemplate = async () => {
            const file = await getFileTempate(workflowIdentifier);
            const filename = workflowIdentifier + '-template.csv';
            FileSaver.saveAs(
                new Blob([file.data], {type: 'text/csv;charset=utf-8'}),
                filename
            );
        };

        const importResultReceived = () => {
            showPlatformConfirmationDialog.value = true;
        };

        const handleConfirmationResult = (result: boolean) => {
            importResult.value = result;
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            acceptFiles.value.length = 0;
            if (result) {
                toast.success(i18n.global.t('messages.fileScheduledForImport'));
            }
        };

        const onChange = () => {
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            acceptFiles.value.length = 0;
            importResult.value = false;

            fileExtension.value = '.csv';
        };

        const fileExtension = ref('.csv');

        watch(fileExtension, () => {
            dropzone.value = useDropzone({
                onDrop,
                accept: fileExtension.value
            });
        });

        const dropzone = ref(null);
        dropzone.value = useDropzone({
            onDrop,
            accept: fileExtension.value
        });

        const showUploadButton = computed(() => {
            return (
                chosenSupplier.value &&
                acceptFiles.value.length > 0 &&
                savedFileId.value === null &&
                rowsToSkip.value.length < 1
            );
        });

        const importWizardOptions = computed(() => {
            const currentUser = store.getters['auth/user'];
            return evaluateChildNodes(wizardOptions, currentUser);
        });

        const closePlatformConfirmationDialog = async (
            event: any,
            result: boolean = false
        ) => {
            showPlatformConfirmationDialog.value = false;
            confirmationInputValue.value = '';
            try {
                if (result) {
                    await schedulePriceImport(
                        savedFileId.value,
                        chosenSupplier.value.value,
                        chosenSupplier.value.label
                            .replace(chosenSupplier.value.value, '')
                            .replace('[', '')
                            .replace(']', '')
                            .trim()
                    );
                } else {
                    await removeFile(savedFileId.value);
                    toast.success(i18n.global.t('messages.fileDiscarded'));
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                handleConfirmationResult(result);
                isLoading.value = false;
            }
        };

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const confirmButtonEnabled = computed(() => {
            return (
                importPlatform.value?.threeLetterId ===
                confirmationInputValue.value.toLowerCase().trim()
            );
        });

        const onShowConfirmationDialog = () => {
            const inputField = confirmationInputField.value
                ?.$el as HTMLInputElement;
            if (inputField) {
                inputField.focus();
            }
        };

        const onSupplierChosen = (event: {
            supplier: {label: string; value: string};
        }) => {
            chosenSupplier.value = event.supplier;
        };

        return {
            dropzone,
            showUploadButton,
            savedFileId,
            uploadFile,
            validateFile,
            importResultReceived,
            onChange,
            importResult,
            rowsToSkip,
            isLoading,
            importWizardOptions,
            fileScheduledForImportTextPanel,
            downloadFileTemplate,
            showPlatformConfirmationDialog,
            closePlatformConfirmationDialog,
            importPlatform,
            confirmationInputField,
            confirmationInputValue,
            confirmButtonEnabled,
            onShowConfirmationDialog,
            onSupplierChosen
        };
    }
};
