import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import ProductListLazy from '@/components/grid/products/list-lazy.vue';
import {useToast} from 'vue-toastification';
import {bulkReplace} from '@/services/product-accessories';
export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        TabPanel,
        TabView,
        ProductList,
        ProductListLazy,
        InputSwitch,
        'p-button': Button,
        LoadingPlugin
    },
    props: {
        oldAccessory: Object,
        showReplaceDialog: Boolean
    },
    setup(props: any, context: any) {
        const {oldAccessory} = toRefs(props);
        const showDialog = ref(false);
        const selectedProducts = ref([]);
        const selectedNewAccessory = ref(null);
        const toast = useToast();
        const replaceWithNothing = ref(false);
        const loading = ref(false);

        watch(props, (args) => {
            showDialog.value = args.showReplaceDialog;
        });

        const closeDialog = (forceResfresh: boolean = false) => {
            if (selectedProducts.value) {
                selectedProducts.value.length = 0;
            }
            if (selectedNewAccessory.value) {
                selectedNewAccessory.value = null;
            }
            replaceWithNothing.value = false;
            context.emit('close-dialog', forceResfresh);
        };

        const showSummaryTab = computed(() => {
            return (
                (replaceWithNothing.value || selectedNewAccessory.value) &&
                selectedProducts.value &&
                selectedProducts.value.length > 0
            );
        });

        const replaceArticleAccessories = async () => {
            loading.value = true;
            try {
                await bulkReplace(
                    selectedProducts.value,
                    oldAccessory.value,
                    replaceWithNothing.value ? null : selectedNewAccessory.value
                );
                toast.success('Saved');
                closeDialog(true);
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                loading.value = false;
            }
        };

        const onProductSelectionChange = (selection: Array<any>) => {
            selectedProducts.value = selection;
        };

        const onNewAccessorySelectionChange = (selection: Array<any>) => {
            selectedNewAccessory.value =
                selection?.length > 0 ? selection[0] : null;
        };

        return {
            oldAccessory,
            showDialog,
            selectedNewAccessory,
            selectedProducts,
            replaceWithNothing,
            closeDialog,
            replaceArticleAccessories,
            showSummaryTab,
            onProductSelectionChange,
            onNewAccessorySelectionChange,
            loading
        };
    }
};
