import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const update = async (
    productNumber: string,
    link: {id: string; title: string; url: string}
): Promise<AxiosResponse> => {
    const {url, title} = link;
    return apiClient.put(
        '/api/v1/products/' + productNumber + '/links/' + link.id,
        {url, title}
    );
};

export const add = async (
    productNumber: string,
    link: {title: string; url: string}
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/products/' + productNumber + '/links', {
        title: link.title,
        url: link.url
    });
};

export const remove = async (
    productNumber: string,
    link: {id: string; title: string; url: string}
): Promise<AxiosResponse> => {
    const {id} = link;
    return apiClient.delete(
        '/api/v1/products/' + productNumber + '/links/' + id
    );
};
