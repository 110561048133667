<div class="m-2">
    <DataTable
        class="p-datatable-sm text-sm"
        scrollable
        :scrollHeight="'calc(100vh - 33rem)'"
        stripedRows
        :paginator="badges && badges.length > 20"
        :rows="50"
        :value="badges"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200]"
        current-page-report-template="{first} to {last} of {totalRecords}"
    >
        <Column headerStyle="width: 3rem">
            <template #body="{data}">
                <p-checkbox
                    v-if="data.active"
                    :value="data.id"
                    v-model="productBadges"
                /> </template
        ></Column>
        <Column
            field="name"
            :header="$t('labels.name')"
            style="min-width: 12rem"
        >
            <template #body="{data}">
                <span v-if="data.active">{{ data.name }}</span>
                <span v-else class="text-gray-600">{{ data.name }}</span>
            </template>
        </Column>
    </DataTable>
    <div class="mt-1 flex justify-content-end">
        <p-button
            severity="danger"
            :disabled="buttonsDisabled || buttonsHidden"
            @click="discardChanges"
            v-text="$t('labels.revert')"
            class="mr-3"
        >
        </p-button>
        <p-button
            severity="success"
            @click="saveChanges"
            :disabled="buttonsDisabled || buttonsHidden"
            v-text="buttonsDisabled ? $t('labels.inProgress') : $t('labels.save')"
        >
        </p-button>
    </div>
</div>
