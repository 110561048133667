/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {ISaveResultItem} from '@/interfaces/import-wizard/save-result-item';
import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

const getError = (error: any) => {
    const message = error.response?.data.error || 'Failed';
    return new Error(message);
};

export const saveFiles = async (
    files: any,
    identifier: string
): Promise<{saveResult: ISaveResultItem}> => {
    const formData = new FormData();
    formData.append('identifier', identifier);
    for (let x = 0; x < files.length; x++) {
        formData.append('files[]', files[x]);
    }

    try {
        const importResponse = await apiClient.post(
            '/api/v1/imports',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        return importResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const addColumnMapping = async (
    id: number,
    mapping: Array<{header: string; mapped: string; mandatory: boolean}>
): Promise<any> => {
    try {
        const processResponse = await apiClient.post(
            '/api/v1/imports/' + id + '/column-mapping',
            mapping.map(
                (item: {
                    header: string;
                    mapped: string;
                    mandatory: boolean;
                }) => {
                    return Object.assign(
                        {
                            header: item.header
                        },
                        item.mapped ? {mapped: item.mapped} : {}
                    );
                }
            )
        );

        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getColumnMappingConfiguration = async (
    id: number
): Promise<any> => {
    try {
        const processResponse = await apiClient.get(
            '/api/v1/imports/' + id + '/column-mapping-configuration'
        );

        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const runValidation = async (id: number): Promise<any> => {
    try {
        const processResponse = await apiClient.get(
            '/api/v1/imports/' + id + '/validate'
        );

        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const runImport = async (id: number): Promise<any> => {
    try {
        const processResponse = await apiClient.get(
            '/api/v1/imports/' + id + '/run'
        );

        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const markAsValidated = async (id: number): Promise<any> => {
    try {
        const processResponse = await apiClient.put('/api/v1/imports/' + id);
        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const removeFile = async (id: number): Promise<any> => {
    try {
        const processResponse = await apiClient.delete('/api/v1/imports/' + id);
        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const schedulePriceImport = async (
    id: number,
    supplierNumber: string,
    supplierName: string
): Promise<any> => {
    try {
        const processResponse = await apiClient.post(
            '/api/v1/imports/' + id + '/schedule-price-import',
            {
                supplierNumber,
                supplierName
            }
        );
        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const runPriceImportManually = async (
    id: number,
    skipped: Array<number> = []
): Promise<any> => {
    try {
        const processResponse = await apiClient.post(
            '/api/v1/imports/' + id + '/run-manually',
            {
                skipped
            }
        );
        return processResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getFileTempate = (identifier: string): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/imports/file-template?identifier=' + identifier,
        {
            responseType: 'blob'
        }
    );
};
