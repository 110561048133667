import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {addPermission} from '@/services/users';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        loading: LoadingPlugin,
        'p-dropdown': Dropdown
    },
    props: {
        selectedUser: Object,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {selectedUser} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);

        const allPermissions = [
            'import-operator',
            'ticket-operator',
            'ticket-editor',
            'shipment-operator',
            'shipment-editor',
            'ups-shipment-operator',
            'ups-shipment-editor',
            'product-operator',
            'product-editor',
            'product-accessories-operator',
            'product-accessories-editor',
            'invoice-operator',
            'invoice-editor',
            'warehouse-operator',
            'warehouse-editor',
            'net-rivals-operator',
            'net-rivals-editor',
            'job-operator',
            'job-editor',
            'sales-order-operator',
            'rental-operator',
            'print-configuration-operator',
            'print-configuration-editor',
            'incoming-goods-operator',
            'incoming-goods-editor',
            'headless-shipments-operator',
            'headless-shipments-editor',
            'purchase-order-operator',
            'purchase-order-editor',
            'tag-editor',
            'price-import-operator',
            'price-import-editor'
        ];

        const state = ref({
            permission: null
        });

        const rules = {
            permission: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                permission: null
            };
            context.emit('close-dialog');
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;
            addPermission(selectedUser.value.username, state.value.permission)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    context.emit('close-dialog', true);
                })
                .catch((error: any) => {
                    toast.error(error.response?.data?.error || error.message);
                    context.emit('close-dialog');
                })
                .finally(() => {
                    submitted.value = false;
                    state.value = {
                        permission: null
                    };
                    savingInProgress.value = false;
                    showDialog.value = false;
                });
        };

        const options = computed(() => {
            return allPermissions
                .filter((x) => !selectedUser.value.roles.includes(x))
                .sort();
        });

        return {
            showDialog,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            setDropdownValue,
            options,
            selectedUser
        };
    }
};
