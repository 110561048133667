import {computed, ref, toRefs, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import {maxLength} from '@vuelidate/validators';
import Checkbox from 'primevue/checkbox';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        selectedEnvironments: Object,
        pairValues: {
            type: Object,
            default: null
        },
        allPlatforms: Object,
        singleEnvironment: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-fieldset': Fieldset,
        'p-checkbox': Checkbox,
        tiny: Editor
    },
    setup(props: any, context: any) {
        const {selectedEnvironments, allPlatforms, singleEnvironment} =
            toRefs(props);

        const pairValues = ref(null);
        const submitted = ref(false);

        const existingShopwareData = ref(null);

        const fieldDefinitions: any = [
            {
                name: 'webshopText',
                value: null
            },
            {
                name: 'shortDescription',
                value: null
            },
            {
                name: 'longDescription',
                value: null
            },
            {
                name: 'availableInShop',
                value: false
            },
            {
                name: 'activeInShop',
                value: false
            },
            {
                name: 'copyShopAccessories',
                value: false
            },
            {
                name: 'copyShopImages',
                value: false
            },
            {
                name: 'copyShopVideos',
                value: false
            },
            {
                name: 'copyShopLinks',
                value: false
            }
        ];

        const stateDef: any = {};

        const state = ref(stateDef);

        allPlatforms.value.forEach(
            (platform: {
                label: string;
                value: string;
                threeLetterId: string;
            }) => {
                fieldDefinitions.forEach(
                    (fieldDefinition: {name: string; value: any}) => {
                        const key = fieldDefinition.name + '-' + platform.value;
                        stateDef[key as keyof typeof stateDef] =
                            fieldDefinition.value;
                    }
                );
            }
        );

        const rules = computed(() => {
            const localRules: any = {};
            selectedEnvironments.value.forEach((platform: string) => {
                fieldDefinitions.forEach(
                    (fieldDefinition: {name: string; value: any}) => {
                        const key = fieldDefinition.name + '-' + platform;
                        if ('shortDescription' === fieldDefinition.name) {
                            localRules[key as keyof typeof localRules] = {
                                maxLength: maxLength(100)
                            };
                        } else {
                            localRules[key as keyof typeof localRules] = {};
                        }
                    }
                );
            });
            return localRules;
        });

        const v$ = useVuelidate(rules, state);

        const prePopulateForm = () => {
            allPlatforms.value.forEach(
                (platform: {label: string; value: string}) => {
                    state.value['webshopText-' + platform.value] =
                        pairValues.value?.webshopText || null;

                    state.value['shortDescription-' + platform.value] =
                        pairValues.value?.shortDescription ||
                        pairValues.value?.shortDescription1 ||
                        state.value['shortDescription-' + platform.value];

                    state.value['longDescription-' + platform.value] =
                        pairValues.value?.description || null;
                }
            );

            existingShopwareData.value = pairValues.value?.shopwareData || null;
            if (existingShopwareData.value) {
                allPlatforms.value.forEach(
                    (platform: {label: string; value: string}) => {
                        state.value['copyShopAccessories-' + platform.value] =
                            existingShopwareData.value.accessories.length > 0;
                        state.value['copyShopImages-' + platform.value] =
                            existingShopwareData.value.images.length > 0;
                        state.value['copyShopVideos-' + platform.value] =
                            existingShopwareData.value.videos.length > 0;
                        state.value['copyShopLinks-' + platform.value] =
                            existingShopwareData.value.links.length > 0;
                    }
                );
            }

            for (const key in state.value) {
                if (Object.keys(pairValues.value).includes(key)) {
                    state.value[key] = pairValues.value[key];
                }
            }
        };
        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 2,
                formValues: state.value
            });
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 2);
        };

        return {
            state,
            v$,
            handleSubmit,
            selectedEnvironments,
            submitted,
            onBackButtonClicked,
            locale: i18n.global.locale,
            platforms: allPlatforms,
            onActiveInShopChange: (platform: string, newValue: boolean) => {
                if (newValue) {
                    state.value['availableInShop-' + platform] = true;
                }
            },
            singleEnvironment,
            existingShopwareData
        };
    }
};
