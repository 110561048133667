<loading v-model:active="loading" />
<Panel :header="$t('labels.shipment.shipmentMask')">
    <div class="p-fluid mb-5">
        <InputText
            :disabled="shipmentNumberPreSelected"
            :placeholder="$t('labels.shipment.shipmentNumber')"
            autocomplete="off"
            v-model="shipmentNumber"
            @paste="onShipmentNumberPaste"
            @input="onShipmentNumberChange"
            v-on:keyup.enter="onShipmentNumberEnter"
            ref="shipmentNumberInputField"
        />
    </div>
    <template v-if="shipmentDetails">
        <div class="grid mb-3">
            <div class="col">
                <h5>{{$t('labels.shipment.shipmentNumber')}}</h5>
                <div>
                    <a
                        :href="shipmentDetails.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + shipmentDetails.id"
                        target="_blank"
                        >{{ shipmentDetails.shipmentNumber }}</a
                    >
                    <span
                        class="product-badge ml-2 text-white"
                        :class="{'bg-orange-600' : shipmentDetails.status === 'DELIVERY_NOTE_PRINTED', 'bg-blue-600':shipmentDetails.status !== 'DELIVERY_NOTE_PRINTED'}"
                        >{{$t('labels.shipment.statusOptions.' +
                        shipmentDetails.status) }}</span
                    >
                </div>
            </div>
            <div class="col">
                <h5>{{$t('labels.shipment.recipient')}}</h5>
                <div
                    class="text-purple-600"
                    v-if="shipmentDetails.shipmentType === 'INTERNAL'"
                >
                    {{$t('labels.shipment.shipmentTypeOptions.INTERNAL')}}
                </div>
                <div v-else>
                    <a
                        :href="shipmentDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + shipmentDetails.recipientParty?.id"
                        target="_blank"
                        >{{ outputRecipient(shipmentDetails) }}</a
                    >
                </div>
            </div>
            <div class="col">
                <h5>{{$t('labels.shipment.shipmentLocation')}}</h5>
                <template v-for="platformItem in shippingLocationOptions">
                    <div
                        v-if="v$.shippingLocation.$model === platformItem.value"
                        @click="toggleDataTable"
                        style="cursor: pointer"
                    >
                        <font-awesome-icon :icon="['fas', 'truck']" />
                        &nbsp;&nbsp;{{ platformItem.label }}
                    </div>
                </template>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.shippingLocation.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid mb-3">
            <div class="col">
                <DataTable
                    class="p-datatable-sm mask-positions"
                    stripedRows
                    ref="dt"
                    dataKey="id"
                    :value="groupedShipmentItems"
                    responsiveLayout="scroll"
                    v-model:expandedRows="expandedRows"
                    :rowClass="rowClass"
                >
                    <Column :expander="true" headerStyle="width: 3em" />
                    <Column
                        field="positionNumber"
                        :sortable="groupedShipmentItems?.length > 1"
                        style="min-width: 9rem"
                        :header="$t('labels.position')"
                    >
                    </Column>
                    <Column
                        v-if="v$.groupedShipments.$model && v$.groupedShipments.$model.length > 0"
                        style="min-width: 9rem"
                        :header="$t('labels.shipment.shipmentNumber')"
                    >
                        <template #body="{data}">
                            {{ data.shipmentNumber }}
                        </template>
                    </Column>
                    <Column
                        field="title"
                        :sortable="shipmentDetails.shipmentItems?.length > 1"
                        style="min-width: 9rem"
                        :header="$t('labels.article')"
                    >
                        <template #body="{data}">
                            {{ data.title }}
                            <img
                                v-if="data.article_flag_akku_groesser_100wh"
                                src="@/assets/img/akku_groesser.png"
                                :alt="$t('labels.productHome.dangerousGoods')"
                                style="height: 30px; padding-left: 10px"
                                v-tooltip.top="$t('labels.productHome.dangerousGoods')"
                            />
                            <img
                                v-if="data.article_flag_akku_kleiner_100wh"
                                src="@/assets/img/akku_kleiner.jpg"
                                :alt="$t('labels.productHome.dangerousGoodsSmall')"
                                style="height: 30px; padding-left: 10px"
                                v-tooltip.top="$t('labels.productHome.dangerousGoodsSmall')"
                            />
                            <img
                                v-if="data.article_flag_aerosole"
                                src="@/assets/img/aerosole.png"
                                :alt="$t('labels.productHome.containsAerosols')"
                                style="height: 30px; padding-left: 10px"
                                v-tooltip.top="$t('labels.productHome.containsAerosols')"
                            />
                        </template>
                    </Column>
                    <Column
                        field="quantity"
                        :sortable="shipmentDetails.shipmentItems?.length > 1"
                        style="min-width: 9rem"
                        :header="$t('labels.shipment.shipmentQuantity')"
                    >
                        <template #body="{data}">
                            {{ data.quantity }} {{ data.unitName }}
                        </template>
                    </Column>
                    <Column
                        field="articleNumber"
                        :sortable="shipmentDetails.shipmentItems?.length > 1"
                        style="min-width: 9rem"
                        :header="$t('labels.articleNumber')"
                    >
                    </Column>
                    <Column
                        :sortable="shipmentDetails.shipmentItems?.length > 1"
                        style="min-width: 9rem"
                        :header="$t('labels.serialNumbers')"
                    >
                        <template #body="{data}">
                            {{ data.serialNumbers?.join(', ') }}
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div
                            v-if="slotProps.data.description"
                            v-html="slotProps.data.description"
                        ></div>
                        <div v-else>No description available.</div>
                    </template>
                </DataTable>
            </div>
        </div>

        <div class="grid mb-5">
            <div class="col">
                <div class="grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.shipment.shipmentAddress') }}
                        </label>
                        <div
                            class="font-semibold"
                            v-if="shipmentDetails.recipientAddress?.company"
                        >
                            {{shipmentDetails.recipientAddress?.company}}
                        </div>
                        <div
                            class="font-semibold"
                            v-else-if="shipmentDetails.recipientAddress?.lastName"
                        >
                            {{shipmentDetails.recipientAddress?.salutation}}
                            {{shipmentDetails.recipientAddress?.firstName}}
                            {{shipmentDetails.recipientAddress?.lastName}}
                        </div>
                        <div class="font-semibold">
                            {{shipmentDetails.recipientAddress?.street1}},
                            {{shipmentDetails.recipientAddress?.zipcode}}
                            {{shipmentDetails.recipientAddress?.city}},
                            {{shipmentDetails.recipientAddress?.countryCode}}
                        </div>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t('labels.shipment.shippingCarrier') }}
                        </label>
                        <p-dropdown
                            v-model="v$.shippingCarrier.$model"
                            optionLabel="label"
                            optionValue="value"
                            :options="shippingCarrierOptions"
                            @change="onShippingCarrierChange"
                            :disabled="shipmentDetails.status !== 'NEW' || shipmentDetails.isPickup"
                            :class="{'p-invalid':v$.shippingCarrier.$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.shippingCarrier.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div
                    class="grid p-fluid"
                    v-if="shipmentDetails?.customAttributes?.cust_ups_account_no"
                >
                    <div class="field col">
                        <label>{{ $t('labels.shipment.upsNumber') }} </label>
                        <div class="font-semibold">
                            {{shipmentDetails?.customAttributes?.cust_ups_account_no}}
                        </div>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t(v$.groupedShipments.$model &&
                            v$.groupedShipments.$model.length > 0 ?
                            'labels.shipment.groupedWeight' :
                            'labels.shipment.weight') }}
                        </label>
                        <InputText
                            name="packageWeight"
                            pattern="^\d*(,\d{0,3})?$"
                            :placeholder="$t('labels.shipment.weight')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-on:keydown="onElementKeyDown"
                            v-model="v$.packageWeight.$model"
                            :class="{'p-invalid':v$.packageWeight.$invalid && submitted}"
                            ref="packageWeightInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.packageWeight.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="field col">
                        <label
                            >{{ $t(v$.groupedShipments.$model &&
                            v$.groupedShipments.$model.length > 0 ?
                            'labels.shipment.groupedNumberOfLabels' :
                            'labels.shipment.numberOfLabels') }}
                        </label>
                        <InputText
                            pattern="^\d*$"
                            name="shippingLabelsCount"
                            v-on:keydown="onElementKeyDown"
                            :placeholder="$t('labels.shipment.numberOfLabels')"
                            autocomplete="off"
                            @focus.native="onElementFocused"
                            v-model="v$.shippingLabelsCount.$model"
                            :class="{'p-invalid':v$.shippingLabelsCount.$invalid && submitted}"
                            ref="shippingLabelsCountInputField"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.shippingLabelsCount.$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
            </div>
            <div class="col">
                <div
                    v-if="shipmentDetails?.pickingInstructions"
                    class="mb-5 p-3 font-semibold"
                    style="border: red 2px solid"
                >
                    {{shipmentDetails.pickingInstructions}}
                </div>
                <template
                    v-if="(isUpsCarrier || isDhlCarrier) && groupedShipmentOptions.length > 0"
                    class="p-3"
                >
                    <DataTable
                        class="p-datatable-sm"
                        stripedRows
                        style="height: 90%"
                        :paginator="groupedShipmentOptions.length > 4"
                        :rows="4"
                        filterDisplay="row"
                        dataKey="shipmentNumber"
                        :value="groupedShipmentOptions"
                        responsiveLayout="scroll"
                        v-model:filters="groupedFilters"
                        :rowClass="rowClass"
                    >
                        <Column style="min-width: 2rem; max-width: fit-content"
                            ><template #body="{data}">
                                <p-checkbox
                                    v-model="v$.groupedShipments.$model"
                                    v-if="!groupedMultiSelectOptionFilterFunction(data)"
                                    :value="data.shipmentNumber"
                                    @change="onCheckboxChange"
                                ></p-checkbox> </template
                        ></Column>
                        <Column
                            field="shipmentNumber"
                            header="Weitere Lieferungen des Kunden"
                        >
                            <template #filter="{filterModel,filterCallback}">
                                <InputText
                                    v-model="filterModel.value"
                                    @keydown.enter="filterCallback()"
                                    :placeholder="$t('labels.shipment.shipmentNumber')"
                                    @focus.native="onElementFocused($event, filterModel, filterCallback);"
                                    name="groupedShipmentFilter"
                                    ref="groupedShipmentFilterField"
                                /> </template
                            ><template #body="{data}">
                                <div
                                    class="text-red-600"
                                    v-if="groupedMultiSelectOptionFilterFunction(data)"
                                >
                                    {{data.shipmentNumber}}
                                </div>
                                <div v-else>{{data.shipmentNumber}}</div>
                            </template></Column
                        >
                    </DataTable>
                </template>
            </div>
            <div class="col">
                <app-keyboard @pressed="onKeyboardPressed" />
            </div>
        </div>
        <div class="flex justify-content-end">
            <p-button
                v-if="showUpsDangerousGoodsButton"
                :disabled="!upsDangerousGoodsSuitableRecipientAddress"
                @click="displayUPSAnnounceDangerousGoodsDialog = true"
            >
                {{ $t("buttons.enterDangerousGoodsData") }}
            </p-button>
            <p-button
                :disabled="haveSavingButtonDisabled"
                class="ml-2"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : submitButtonText"
                @click="handleFinalSubmitClick"
            >
            </p-button>
            <p-button
                severity="danger"
                @click="resetShipmentNumber"
                class="ml-2"
            >
                {{ $t("buttons.cancelShipment") }}
            </p-button>
        </div>
    </template>
</Panel>

<OverlayPanel
    ref="opShippingLocations"
    appendTo="body"
    id="overlay_panel_shipping_locations"
>
    <DataTable
        :value="shippingLocationOptions"
        :paginator="shippingLocationOptions?.length > 5"
        :rows="5"
        responsiveLayout="scroll"
    >
        <Column headerStyle="min-width:12rem;display:none;"
            ><template #body="{data}">
                <div
                    style="cursor: pointer"
                    @click="changeShippingLocation(data.value);toggleDataTable();"
                >
                    <font-awesome-icon :icon="['fas', 'truck']" />
                    &nbsp;&nbsp;{{ data.label }}
                </div>
            </template></Column
        >
    </DataTable>
</OverlayPanel>
<!-- /.card -->
<p-dialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="displayDangerousGoodsConfirmationDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    :modal="true"
>
    <div class="confirmation-content">
        <div class="grid mb-3">
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAkkuGroesser"
            >
                <img
                    class="rounded"
                    src="@/assets/img/akku_groesser.png"
                    :alt="$t('labels.productHome.dangerousGoods')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.dangerousGoods')"
                />
            </div>
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAkkuKleiner"
            >
                <img
                    class="rounded"
                    src="@/assets/img/akku_kleiner.jpg"
                    :alt="$t('labels.productHome.dangerousGoodsSmall')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.dangerousGoodsSmall')"
                />
            </div>
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAerosols"
            >
                <img
                    class="rounded"
                    src="@/assets/img/aerosole.png"
                    :alt="$t('labels.productHome.containsAerosols')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.containsAerosols')"
                />
            </div>
            <div
                class="col d-flex justify-content-center mb-2"
                v-if="confirmationNeededForSpedition"
            >
                <font-awesome-icon
                    :icon="['fas', 'cart-flatbed']"
                    style="font-size: 4rem"
                />
            </div>
        </div>
        <div v-if="confirmationNeededForSpedition">
            {{ $t('messages.speditionProductWarning', {product:
            confirmationNeededForSpedition}) }}
        </div>
        <div v-else>{{ $t('messages.dangerousGoodsConfirmation') }}</div>
    </div>
    <template #footer>
        <div class="w-full flex justify-content-between">
            <p-button
                severity="danger"
                @click="displayDangerousGoodsConfirmationDialog = false"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="handleDangerousGoodsConfirmClick"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</p-dialog>
<UpsAnnounceDangerousGoodsDialog
    :display-dialog="displayUPSAnnounceDangerousGoodsDialog"
    @close-dialog="closeUPSAnnounceDangerousGoodsDialogListener"
    :shipment-items="groupedShipmentItems"
    :shipment="shipmentDetails"
>
</UpsAnnounceDangerousGoodsDialog>
