<PrimeDialog
    :header="$t('labels.addEmail')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '960px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.mail.fromAddress')}} </label>
                <InputText
                    :disabled="true"
                    autocomplete="off"
                    v-model="v$.fromAddress.$model"
                    :class="{'p-invalid':v$.fromAddress.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.fromAddress.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.mail.toAddress')}} </label>
                <InputText
                    :placeholder="$t('labels.mail.separatedBySemiColon')"
                    autocomplete="off"
                    v-model="v$.toAddress.$model"
                    :class="{'p-invalid':v$.toAddress.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.toAddress.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.mail.subject')}} </label>
                <InputText
                    :placeholder="$t('labels.mail.subject')"
                    autocomplete="off"
                    v-model="v$.subject.$model"
                    :class="{'p-invalid':v$.subject.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.subject.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.mail.body')}} </label>
                <tiny
                    api-key="27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8"
                    v-model="v$.body.$model"
                    :init="{
                    height: 300,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true
                }"
                ></tiny>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.body.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>

            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
