<loading v-model:active="loading" />
<Panel :header="$t('labels.copyCertainProduct', {product: productHeader})">
    <Stepper
        linear
        v-model:activeStep="activeIndex"
        v-if="allPlatforms && allPlatforms.length > 0 && loggedInUser"
    >
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 1/3'">
            <template #content="{ nextCallback }">
                <GlobalFieldsStep
                    :loggedInUser="loggedInUser"
                    :pairValues="pairValues"
                    singleEnvironment
                    hideBackButton
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                ></GlobalFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 2/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificDescriptionsStep
                    :allPlatforms="allPlatforms"
                    :selectedEnvironments="[selectedPlatform]"
                    :pairValues="pairValues"
                    @back-button-clicked="onBackButtonClicked($event);prevCallback($event);"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificDescriptionsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 3/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificFieldsStep
                    :allPlatforms="allPlatforms"
                    :selectedEnvironments="[selectedPlatform]"
                    :pairValues="pairValues"
                    singleEnvironment
                    @back-button-clicked="onBackButtonClicked($event);prevCallback($event);"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.salesOrder.supplySources')">
            <template #content="{ prevCallback, nextCallback }">
                <CloneSupplySourcesStep
                    :pairValues="pairValues"
                    :savingInProgress="savingInProgress"
                    @back-button-clicked="onBackButtonClicked($event);prevCallback($event);"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </CloneSupplySourcesStep>
            </template>
        </StepperPanel>
    </Stepper>
</Panel>
<PrimeDialog
    :header="$t('labels.result')"
    v-model:visible="showResultDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
    :closable="false"
>
    <div v-for="platform in platforms" class="grid mb-4">
        <div class="col-4">{{platform.label}}</div>
        <div class="col-8">
            <template v-if="saveResult[platform.value]">
                <span
                    v-if="saveResult[platform.value]['error']"
                    class="text-danger"
                >
                    {{$t('labels.error')}}: {{
                    saveResult[platform.value]['error'] }}
                </span>
                <template v-if="saveResult[platform.value]['id']">
                    <i class="pi text-green-600 pi-check-circle"></i>

                    <span class="ml-3">{{$t('labels.articleNumber')}}:</span>
                    <router-link
                        class="ml-3"
                        :to="{
                            name: 'ProductDetails',
                            params: {
                                id: saveResult[platform.value]['id']
                            }
                        }"
                        ><span>{{ saveResult[platform.value]['id'] }}</span>
                    </router-link>
                </template>
                <span class="ml-3" v-else
                    >{{$t('labels.articleNumber')}}: {{
                    saveResult[platform.value]['id'] }}</span
                >
            </template>
        </div>
    </div>

    <template #footer>
        <div v-if="hasError" class="w-full flex justify-content-between">
            <p-button severity="warning" @click="onStartOverClick">
                {{ $t("buttons.startOver") }}
            </p-button>
            <router-link
                :to="{
            name: 'Products'
        }"
                ><p-button severity="danger">
                    {{ $t("buttons.cancel") }}
                </p-button></router-link
            >
        </div>
        <div v-else class="flex justify-content-end">
            <router-link
                :to="{
            name: 'Products'
        }"
                ><p-button severity="success">
                    {{ $t("buttons.backToProductList") }}
                </p-button></router-link
            >
        </div>
    </template>
</PrimeDialog>
<ConfirmLeave
    :displayConfirmLeaveDialog="displayConfirmLeaveDialog"
    @choice-confirmed="onChoiceConfirmed"
></ConfirmLeave>
