import {computed, onMounted, ref} from 'vue';
import {getAll, getAllUsed} from '@/services/products';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import store from '@/store';
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import {
    stripTagsAndTruncate,
    vkPriceCalculation,
    ekPriceCalculation,
    getShopwareUrl
} from '@/utils/helpers';
import {ILayoutConfig} from '@/interfaces/layout/config';
import router from '@/router';

export default {
    components: {
        DataTable,
        InputText,
        Column,
        Panel,
        TriStateCheckbox,
        'p-multiselect': MultiSelect,
        'p-button': Button,
        loading: LoadingPlugin,
        Tag,
        Calendar
    },
    setup() {
        onMounted(() => {
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value
            };
            loadLazyData();
        });

        const loading = ref(false);
        const products = ref();
        const toast = useToast();

        const dt = ref();
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const totalRecords = ref(0);
        const sourceProducts = ref([]);

        const loadLazyData = () => {
            loading.value = true;

            getAllUsed(lazyParams.value)
                .then((data) => {
                    products.value = (data.data.items || []).map(
                        (item: any) => {
                            const sourceProductTag = item.systemTags.find(
                                (tag: string) =>
                                    tag.startsWith('TTC-PRODUCT-NEW-')
                            );

                            return {
                                ...item,
                                productCategory: item.shopwareData?.categories,
                                sourceProductNumber: sourceProductTag
                                    ? sourceProductTag.replace(
                                          'TTC-PRODUCT-NEW-',
                                          ''
                                      )
                                    : null
                            };
                        }
                    );

                    getAll({
                        first: 0,
                        rows: 20,
                        columns: ['id', 'articleNumber', 'name'],
                        filters: {
                            articleNumber: {
                                value: products.value
                                    .map(
                                        (item: any) => item.sourceProductNumber
                                    )
                                    .filter((n: any) => n),
                                matchMode: FilterMatchMode.IN
                            }
                        }
                    })
                        .then((sourceData) => {
                            if (sourceData.data?.items) {
                                sourceProducts.value =
                                    sourceData.data.items.map(
                                        (item: {
                                            id: string;
                                            articleNumber: string;
                                            name: string;
                                        }) => {
                                            return {
                                                id: item.id,
                                                articleNumber:
                                                    item.articleNumber,
                                                name: item.name
                                            };
                                        }
                                    );
                                products.value = products.value.map(
                                    (item: any) => {
                                        const sourceItem =
                                            item.sourceProductNumber
                                                ? sourceProducts.value.find(
                                                      (sourceItem: any) =>
                                                          sourceItem.articleNumber ===
                                                          item.sourceProductNumber
                                                  )
                                                : null;
                                        return {
                                            ...item,
                                            sourceProductId: sourceItem
                                                ? sourceItem.id
                                                : null
                                        };
                                    }
                                );
                            }
                        })
                        .catch((error) => {
                            toast.error(error.message);
                        });

                    totalRecords.value = data.data.total;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            tags: {
                value: null,
                matchMode: FilterMatchMode.IN
            },
            productCategory: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            availableInShop: {
                value: true,
                matchMode: FilterMatchMode.EQUALS
            },
            activeInShop: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        };

        const filters = ref(filtersDefinition);

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const viewAccessoriesPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('product-accessories-view') !== -1
            );
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const resolvedShopwareUrl = (data: any) => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            const resolvedPlatform = platforms.find(
                (item: any) => item.value === currentPlatform
            );

            return (
                data.shopwareUrl +
                getShopwareUrl(data.shopwareData?.name, resolvedPlatform) +
                (resolvedPlatform?.threeLetterId === 'vct' ? '' : '.html')
            );
        };

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const onAddNewProductClick = () => {
            router.push({
                name: 'ProductsAddUsedWizard'
            });
        };

        return {
            loading,
            loadLazyData,
            products,
            totalRecords,
            filters,
            expandedRows,
            dt,
            expandRow,
            editPermissionAvailable,
            viewAccessoriesPermissionAvailable,
            stripTagsAndTruncate,
            vkPriceCalculation,
            ekPriceCalculation,
            resolvedShopwareUrl: resolvedShopwareUrl,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;

                return data?.shopwareData
                    ? data?.shopwareData?.active
                        ? ''
                        : data.customAttributes?.article_available_in_shop
                        ? 'bg-yellow-' + colorVariant
                        : 'bg-pink-' + colorVariant
                    : 'bg-red-' + colorVariant;
            },
            onPage,
            onSort,
            onFilter,
            onAddNewProductClick
        };
    }
};
