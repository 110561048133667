import {computed, onUnmounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import {maxLength, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateDescription} from '@/services/products';
import Editor from '@tinymce/tinymce-vue';
import InputSwitch from 'primevue/inputswitch';
import {onBeforeRouteLeave} from 'vue-router';
import ConfirmLeave from '@/components/dialog/general/confirm-leave.vue';
import router from '@/router';
import store from '@/store';
import ScrollPanel from 'primevue/scrollpanel';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['reload-product', 'auto-save-description'],
    components: {
        'p-fieldset': Fieldset,
        tiny: Editor,
        ConfirmLeave: ConfirmLeave,
        InputSwitch,
        ScrollPanel,
        'p-button': Button,
        InputText,
        loading: LoadingPlugin
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        onUnmounted(() => {
            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }
        });

        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const autoSave = ref(store.getters['auth/autoSaveProductDescription']);
        const autoSaveInterval = ref(null);

        const autoSaveBody = async () => {
            if (!v$.value || v$.value.$invalid) {
                return;
            }

            context.emit(
                'auto-save-description',
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            );
        };

        if (autoSave.value) {
            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }
            autoSaveInterval.value = setInterval(autoSaveBody, 10000);
        }

        const state = ref({
            webshopText: '',
            shortDescription: '',
            longDescription: ''
        });

        const rules = {
            webshopText: {required},
            shortDescription: {required, maxLength: maxLength(100)},
            longDescription: {}
        };

        const v$ = useVuelidate(rules, state);

        const nextRoute = ref(null);
        const displayConfirmLeaveDialog = ref(false);

        onBeforeRouteLeave((to: any) => {
            if (v$.value.$anyDirty) {
                displayConfirmLeaveDialog.value = true;
                nextRoute.value = to;
                return false;
            }
        });

        const onChoiceConfirmed = (choice: boolean) => {
            if (choice) {
                v$.value.$reset();
                router.push(nextRoute.value);
            } else {
                displayConfirmLeaveDialog.value = false;
            }
        };

        watch(props, () => {
            productDetails.value = props.productDetails;
            state.value.webshopText =
                props.productDetails.shopware?.description;
            state.value.shortDescription =
                props.productDetails.weclapp?.shortDescription1;
            state.value.longDescription =
                props.productDetails.shopware?.longDescription;
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateDescription(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    v$.value.$reset();
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const onEditorChange = (html: string, editorId: string) => {
            const div = document.createElement('div');
            div.innerHTML = html;
            if (!div.innerText) {
                state.value[editorId as keyof typeof state.value] = '';
            }
        };

        const onKeyUp = (event: any) => {
            const newValue = event.target.value;
            v$.value.shortDescription.$model = null;
            v$.value.shortDescription.$model = newValue;
        };

        const shortDescriptionCharactersLeft = computed(() => {
            return (
                100 -
                (v$.value.shortDescription.$model
                    ? v$.value.shortDescription.$model.length
                    : 0)
            );
        });

        const toogleAutoSave = () => {
            store.dispatch(
                'auth/setAutoSaveProductDescription',
                autoSave.value
            );

            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }

            if (autoSave.value) {
                autoSaveInterval.value = setInterval(autoSaveBody, 10000);
            }
        };

        return {
            state,
            v$,
            handleSubmit,
            onEditorChange,
            submitted,
            savingInProgress,
            onChoiceConfirmed,
            displayConfirmLeaveDialog,
            shortDescriptionCharactersLeft,
            onKeyUp,
            autoSave,
            toogleAutoSave
        };
    }
};
