import DataView from 'primevue/dataview';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import AddDialog from '@/components/dialog/product-videos/add-video.vue';
import DeleteDialog from '@/components/dialog/product-videos/delete-video.vue';
import {ref, watch} from 'vue';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['reload-product'],
    components: {
        DataView,
        'p-button': Button,
        loading: LoadingPlugin,
        AddDialog,
        DeleteDialog,
        ScrollPanel
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const videos = ref([]);
        const selectedVideo = ref(null);
        const productDetails = ref(null);
        const displayAddDialog = ref(false);
        const displayDeleteDialog = ref(false);

        watch(props, () => {
            productDetails.value = props.productDetails;
            videos.value = props.productDetails.shopware?.videos;
        });

        const showDialog = () => {
            displayAddDialog.value = true;
        };

        const showDeleteDialog = (data: any) => {
            selectedVideo.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        return {
            videos,
            productDetails,
            selectedVideo,
            displayAddDialog,
            displayDeleteDialog,
            showDialog,
            showDeleteDialog,
            closeDialogListener
        };
    }
};
