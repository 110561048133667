import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import {FilterMatchMode} from 'primevue/api';
import LoadingPlugin from 'vue-loading-overlay';
import {onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import {
    getSupplierConfiguration,
    updateSupplierConfiguration
} from '@/services/price-import';
import Panel from 'primevue/panel';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Crons from '@/pages/tools/jobs/crons.vue';
import Checkbox from 'primevue/checkbox';
import {i18n} from '@/utils/i18n';

export default {
    name: 'ImportLogs',
    components: {
        DataTable,
        Column,
        InputText,
        Dropdown,
        loading: LoadingPlugin,
        Panel,
        TriStateCheckbox,
        InputNumber,
        Crons,
        'p-checkbox': Checkbox
    },
    setup() {
        onMounted(() => {
            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const allEnvs = ref(false);
        const totalRecords = ref(0);
        const editingRows = ref([]);
        const reloadRequired = ref(0);

        const toast = useToast();

        const logs = ref();

        const filterDefintions: any = {
            supplierName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            costPriceInGross: {value: null, matchMode: FilterMatchMode.EQUALS},
            listPriceInGross: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            trailingDiscountPossible: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            automaticImportActive: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            }
        };

        const filters = ref(filterDefintions);

        const loadLazyData = () => {
            loading.value = true;

            getSupplierConfiguration()
                .then((data) => {
                    totalRecords.value = data.data.total;
                    logs.value = data.data.items.map((obj: any) => ({
                        ...obj,
                        automaticImportActive: !!obj.automaticImportJob
                    }));
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const onRowEditComplete = (event: {data: any; newData: any}) => {
            loading.value = true;
            const {
                costPriceInGross,
                listPriceInGross,
                trailingDiscountPossible,
                automaticImportActive,
                importConfirmationPercentageThreshold
            } = event.newData;
            updateSupplierConfiguration(event.data.id, {
                costPriceInGross,
                listPriceInGross,
                trailingDiscountPossible,
                automaticImportActive,
                importConfirmationPercentageThreshold
            })
                .then(() => {
                    reloadRequired.value = reloadRequired.value + 1;
                    loadLazyData();
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            logs,
            dt,
            totalRecords,
            loading,
            allEnvs,
            filters,
            editingRows,
            onRowEditComplete,
            reloadRequired,
            locale: i18n.global.locale,
            formatter: new Intl.NumberFormat(i18n.global.locale)
        };
    }
};
