import {ref, toRefs, watch} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import {addNewComment} from '@/services/tickets';
import Editor from '@tinymce/tinymce-vue';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        tiny: Editor,
        'p-button': Button,
        loading: LoadingPlugin
    },
    props: {
        ticketDetails: Object,
        commentDefaultValue: String,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showDialog = ref(false);
        const comment = ref(null);
        const toast = useToast();
        const savingInProgress = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            comment.value = args.commentDefaultValue || '';
        });

        const onCancelClick = () => {
            comment.value = '';
            showDialog.value = false;
            context.emit('close-dialog');
        };

        const onConfirmClicked = async () => {
            try {
                savingInProgress.value = true;
                await addNewComment(
                    ticketDetails.value.ticketNumber,
                    comment.value
                );
                toast.success(
                    i18n.global.t('messages.commentAddedSuccessfully')
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                savingInProgress.value = false;
                comment.value = '';
                showDialog.value = false;
            }
        };

        return {
            showDialog,
            comment,
            onCancelClick,
            onConfirmClicked,
            savingInProgress,
            tinyTemplates: [
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.articleArrivedTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.articleArrived',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.articleArrivedEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.articleArrivedEnglish',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.retoureArrivedTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.retoureArrived',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.retoureArrivedEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.retoureArrivedEnglish',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.supplierReturnCreatedTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.supplierReturnCreated',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.supplierReturnCreatedEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.supplierReturnCreatedEnglish',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.customerCompensationCreatedTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.customerCompensationCreated',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.customerCompensationCreatedEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.customerCompensationCreatedEnglish',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.repairCancellationTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.repairCancellation',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                },
                {
                    title: i18n.global.t(
                        'labels.ticket.templates.comments.repairCancellationEnglishTitle'
                    ),
                    description: '',
                    content: i18n.global.t(
                        'labels.ticket.templates.comments.repairCancellationEnglish',
                        {
                            ticketOwner: '{$ticketOwner}',
                            ticketType: 'RMA',
                            ticketNumber: '{$ticketNumber}'
                        }
                    )
                }
            ]
        };
    }
};
