import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f9ce296"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid mb-3" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "p-input-icon-left" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-xs" }
const _hoisted_7 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_8 = { style: {"cursor":"pointer"} }
const _hoisted_9 = { style: {"cursor":"pointer"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputIcon = _resolveComponent("InputIcon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_IconField = _resolveComponent("IconField")
  const _component_Column = _resolveComponent("Column")
  const _component_Skeleton = _resolveComponent("Skeleton")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      class: "panel-container-datatable",
      header: _ctx.$t('labels.salesOrder.disposition')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_component_IconField, { iconPosition: "left" }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputIcon, { class: "pi pi-search" }),
                  _createVNode(_component_InputText, {
                    class: "text-sm",
                    modelValue: _ctx.articleNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.articleNumber) = $event)),
                    modelModifiers: { trim: true },
                    placeholder: _ctx.$t('labels.articleNumber') + ', ' + _ctx.$t('labels.commaSeparated'),
                    onKeydown: _withKeys(_ctx.handleSearch, ["enter"])
                  }, null, 8, ["modelValue", "placeholder", "onKeydown"])
                ]),
                _: 1
              })
            ])
          ])
        ]),
        (_ctx.foundProducts.length > 0)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              class: "p-datatable-sm",
              stripedRows: "",
              lazy: false,
              editMode: "cell",
              onCellEditComplete: _ctx.onCellEditComplete,
              ref: "dt",
              dataKey: "id",
              value: _ctx.foundProducts,
              responsiveLayout: "scroll"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, {
                  field: "title",
                  style: {"min-width":"9rem"},
                  header: _ctx.$t('labels.article')
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createTextVNode(_toDisplayString(data.name) + " [", 1),
                      _createElementVNode("a", {
                        href: data?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                        target: "_blank"
                      }, _toDisplayString(data.articleNumber), 9, _hoisted_5),
                      _createTextVNode("] ")
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("b", null, [
                        _createTextVNode(_toDisplayString(_ctx.$t('labels.createdAt')) + " " + _toDisplayString(_ctx.getDateFormatted(data?.createdDate)), 1),
                        (_ctx.ekPriceCalculation(data))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, "EK " + _toDisplayString(_ctx.ekPriceCalculation(data)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["header"]),
                _createVNode(_component_Column, {
                  field: "minimumStockQuantity",
                  header: "Min"
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_8, [
                      (_ctx.singleEditInProgress === data.articleNumber)
                        ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(data.minimumStockQuantity), 1)
                          ], 64))
                    ])
                  ]),
                  editor: _withCtx(({ data, field }) => [
                    _createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      modelValue: data[field],
                      "onUpdate:modelValue": $event => ((data[field]) = $event),
                      showButtons: "",
                      min: 0,
                      disabled: !_ctx.editPermissionAvailable
                    }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, {
                  field: "targetStockQuantity",
                  header: "Max"
                }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_9, [
                      (_ctx.singleEditInProgress === data.articleNumber)
                        ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(data.targetStockQuantity), 1)
                          ], 64))
                    ])
                  ]),
                  editor: _withCtx(({ data, field }) => [
                    _createVNode(_component_InputNumber, {
                      locale: _ctx.locale,
                      modelValue: data[field],
                      "onUpdate:modelValue": $event => ((data[field]) = $event),
                      showButtons: "",
                      min: 0,
                      disabled: !_ctx.editPermissionAvailable
                    }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { field: "minMaxDate" }, {
                  header: _withCtx(() => [
                    _createTextVNode(" MinMaxAkt "),
                    _createElementVNode("i", {
                      class: "pi pi-refresh",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.bulkUpdateMinMaxDate && _ctx.bulkUpdateMinMaxDate(...args)))
                    })
                  ]),
                  body: _withCtx(({data}) => [
                    (data.minMaxDate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_ctx.singleEditInProgress === data.articleNumber)
                            ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(_ctx.getDateFormatted(data.minMaxDate)), 1)
                              ], 64))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "Dispo" }, {
                  body: _withCtx(({data}) => [
                    (!data?.statistics)
                      ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(parseInt(data?.statistics?.extraInfo?.orderedQuantity ||
                    0)+ parseInt(data?.statistics?.extraInfo?.stockQuantity ||
                    0) -
                    parseInt(data?.statistics?.extraInfo?.plannedSalesQuantity
                    || 0)), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "VK-RG (3M)" }, {
                  body: _withCtx(({data}) => [
                    (!data?.statistics)
                      ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(data?.statistics?.salesInvoices?.lastThreeMonthsItemCount) + " / " + _toDisplayString(data?.statistics?.salesInvoices?.lastThreeMonthsCount), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "VK-RG (12M)" }, {
                  body: _withCtx(({data}) => [
                    (!data?.statistics)
                      ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(data?.statistics?.salesInvoices?.itemCount) + " / " + _toDisplayString(data?.statistics?.salesInvoices?.count), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "BE (12M)" }, {
                  body: _withCtx(({data}) => [
                    (!data?.statistics)
                      ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(data?.statistics?.purchaseOrders?.itemCount), 1))
                  ]),
                  _: 1
                }),
                _createVNode(_component_Column, { header: "Letzte BE" }, {
                  body: _withCtx(({data}) => [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", null, _toDisplayString(_ctx.getDateFormatted(data?.statistics?.purchaseOrders?.lastPurchaseOrderDate)), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onCellEditComplete", "value"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}