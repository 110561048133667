import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1256b79b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mt-3" }
const _hoisted_5 = { class: "flex justify-content-between" }
const _hoisted_6 = {
  key: 0,
  style: {"margin-top":"100px"}
}
const _hoisted_7 = {
  key: 1,
  style: {"margin-top":"100px"}
}
const _hoisted_8 = { class: "mt-5" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "mt-2 p-fluid" }
const _hoisted_13 = { class: "mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_SupplierAutoComplete = _resolveComponent("SupplierAutoComplete")
  const _component_p_button = _resolveComponent("p-button")
  const _component_header_mapping_grid = _resolveComponent("header-mapping-grid")
  const _component_validation_grid = _resolveComponent("validation-grid")
  const _component_Panel = _resolveComponent("Panel")
  const _component_InputText = _resolveComponent("InputText")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isLoading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.importWizard')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SupplierAutoComplete, { onDataEntered: _ctx.onSupplierChosen }, null, 8, ["onDataEntered"]),
        _createElementVNode("div", _mergeProps(_ctx.dropzone.getRootProps(), { class: "import-wizard-container" }), [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.dropzone.getInputProps())), null, 16),
            (_ctx.dropzone.acceptedFiles.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.dropzone.acceptedFiles[0].name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1))
          ])
        ], 16),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_p_button, {
              onClick: _ctx.downloadFileTemplate,
              severity: "info"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.downloadImportFileTemplate')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.showUploadButton)
              ? (_openBlock(), _createBlock(_component_p_button, {
                  key: 0,
                  onClick: _ctx.uploadFile,
                  class: "ml-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.uploadFile")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.savedFileId !== null && _ctx.rowsToSkip.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_header_mapping_grid, {
                savedFileId: _ctx.savedFileId,
                onColumnMappingSaved: _cache[1] || (_cache[1] = $event => (_ctx.validateFile()))
              }, null, 8, ["savedFileId"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.rowsToSkip.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_validation_grid, {
                savedFileId: _ctx.savedFileId,
                rowsToSkip: _ctx.rowsToSkip,
                onImportResultReceived: _ctx.importResultReceived
              }, null, 8, ["savedFileId", "rowsToSkip", "onImportResultReceived"])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", {
            class: "font-medium",
            ref: "fileScheduledForImportTextPanel",
            innerHTML: _ctx.$t('messages.fileScheduledForImportLong')
          }, null, 8, _hoisted_9),
          _createElementVNode("i", {
            class: "pi pi-times-circle mt-1 ml-1 text-red-600",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = $event => {_ctx.importResult = false;})
          })
        ], 512), [
          [_vShow, _ctx.importResult]
        ])
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_PrimeDialog, {
      header: _ctx.$t('messages.pleaseConfirm'),
      visible: _ctx.showPlatformConfirmationDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showPlatformConfirmationDialog) = $event)),
      breakpoints: {'960px': '75vw'},
      style: {width: '50vw'},
      closable: false,
      modal: true,
      onShow: _ctx.onShowConfirmationDialog
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _createElementVNode("h6", {
              innerHTML: _ctx.$t('messages.importFileToPlatformConfirmationWithField', {
                    platform: _ctx.importPlatform?.label, 
                    confirmationValue: _ctx.importPlatform?.threeLetterId  })
            }, null, 8, _hoisted_11)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_InputText, {
              placeholder: _ctx.$t('labels.targetPlatform'),
              autocomplete: "off",
              modelValue: _ctx.confirmationInputValue,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.confirmationInputValue) = $event)),
              ref: "confirmationInputField"
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_p_button, {
              severity: "danger",
              onClick: _ctx.closePlatformConfirmationDialog
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_p_button, {
              severity: "success",
              onClick: _cache[4] || (_cache[4] = $event => (_ctx.closePlatformConfirmationDialog($event, true))),
              disabled: !_ctx.confirmButtonEnabled
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["header", "visible", "onShow"])
  ], 64))
}