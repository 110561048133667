import {computed, onMounted, ref, toRefs} from 'vue';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';
import {getAll as getAllMetadata} from '@/services/metadata';
import Tag from 'primevue/tag';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';

import {stripTagsAndTruncate} from '@/utils/helpers';
import {FilterMatchMode} from 'primevue/api';
import {getAll} from '@/services/products';
import {useToast} from 'vue-toastification';
import Badge from 'primevue/badge';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
export default {
    emits: ['on-selection-change'],
    components: {
        DataTable,
        Column,
        InputText,
        loading: LoadingPlugin,
        Badge,
        Tag,
        TriStateCheckbox,
        'p-multiselect': MultiSelect
    },
    props: {
        products: Array,
        preDefinedFilters: Object,
        selectionMode: String,
        scrollableHeight: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const {selectionMode, scrollableHeight} = toRefs(props);

        onMounted(() => {
            getAllMetadata(['productTags']).then((data) => {
                availableProductTags.value = data.data?.productTags || [];
            });
            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: Object.assign(
                    filters.value,
                    props.preDefinedFilters || {}
                ),
                columns: [
                    'articleNumber',
                    'name',
                    'manufacturerName',
                    'productCategory',
                    'description'
                ],
                allEnvs: false
            };

            loadLazyData();
        });

        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const dt = ref();
        const allEnvs = ref(false);
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);
        const toast = useToast();
        const availableProductTags = ref([]);

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(
                filters.value,
                props.preDefinedFilters || {}
            );
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.allEnvs = allEnvs.value;

            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const filters = ref({
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            productCategory: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            description: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            ean: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerPartNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            availableStock: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            tags: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        });

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
            context.emit('on-selection-change', mergedSelection.value);
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
            context.emit('on-selection-change', mergedSelection.value);
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );

            context.emit('on-selection-change', mergedSelection.value);
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });

            context.emit('on-selection-change', mergedSelection.value);
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
            context.emit('on-selection-change', mergedSelection.value);
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            filters,
            expandedRows,
            dt,
            products,
            selectionMode,
            loading,
            loadLazyData,
            totalRecords,
            selectedProducts,
            mergedSelection,
            matchModesNumeric,
            onFilter,
            onPage,
            onSort,
            stripTagsAndTruncate,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;
                return data?.shopwareData
                    ? data?.shopwareData?.active
                        ? ''
                        : data.customAttributes?.article_available_in_shop
                        ? 'bg-yellow-' + colorVariant
                        : 'bg-pink-' + colorVariant
                    : 'bg-red-' + colorVariant;
            },
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            scrollableHeight,
            availableProductTags
        };
    }
};
