import {computed, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useVuelidate} from '@vuelidate/core';
import AutoComplete from 'primevue/autocomplete';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateGoogleAdwords} from '@/services/products';
import {maxLength} from '@vuelidate/validators';
import ScrollPanel from 'primevue/scrollpanel';
import Textarea from 'primevue/textarea';

export default {
    emits: ['reload-product'],
    components: {
        InputText,
        'p-button': Button,
        'app-checkbox': Checkbox,
        'p-textarea': Textarea,
        InputNumber,
        AutoComplete,
        ScrollPanel
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            label0: null,
            label1: null,
            label2: null,
            label3: null,
            label4: null,
            adwordsRedirect: null,
            promotionId: null,
            isBundle: false,
            multipack: null,
            identifierExists: false
        });

        const rules = {
            label0: {maxLength: maxLength(100)},
            label1: {maxLength: maxLength(100)},
            label2: {maxLength: maxLength(100)},
            label3: {maxLength: maxLength(100)},
            label4: {maxLength: maxLength(100)},
            adwordsRedirect: {maxLength: maxLength(2000)},
            promotionId: {maxLength: maxLength(50)},
            isBundle: {},
            multipack: {},
            identifierExists: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            productDetails.value = props.productDetails;

            if (
                !productDetails.value ||
                !productDetails.value.shopware?.googleAdwords
            ) {
                return;
            }

            Object.keys(productDetails.value.shopware.googleAdwords).forEach(
                (item) => {
                    const temp: {[k: string]: string} = {};
                    temp[item] =
                        productDetails.value.shopware.googleAdwords[item];

                    Object.assign(state.value, temp);
                }
            );
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateGoogleAdwords(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const onKeyUp = (fieldId: string, event: any) => {
            const newValue = event.target.value;
            v$.value[fieldId].$model = null;
            v$.value[fieldId].$model = newValue;
        };

        const labelCharactersLeft = computed(() => {
            return {
                label0:
                    100 -
                    (v$.value.label0.$model
                        ? v$.value.label0.$model.length
                        : 0),
                label1:
                    100 -
                    (v$.value.label1.$model
                        ? v$.value.label1.$model.length
                        : 0),
                label2:
                    100 -
                    (v$.value.label2.$model
                        ? v$.value.label2.$model.length
                        : 0),
                label3:
                    100 -
                    (v$.value.label3.$model
                        ? v$.value.label3.$model.length
                        : 0),
                label4:
                    100 -
                    (v$.value.label4.$model
                        ? v$.value.label4.$model.length
                        : 0),
                adwordsRedirect:
                    2000 -
                    (v$.value.adwordsRedirect.$model
                        ? v$.value.adwordsRedirect.$model.length
                        : 0),
                promotionId:
                    50 -
                    (v$.value.promotionId.$model
                        ? v$.value.promotionId.$model.length
                        : 0)
            };
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            productDetails,
            labelCharactersLeft,
            onKeyUp,
            locale: i18n.global.locale
        };
    }
};
