import LoadingPlugin from 'vue-loading-overlay';
import OrderList from 'primevue/orderlist';
import PrimeButton from 'primevue/button';
import Image from 'primevue/image';
import Toolbar from 'primevue/toolbar';
import AddDialog from '@/components/dialog/product-images/add-image.vue';
import UseAsCoverDialog from '@/components/dialog/product-images/use-as-cover.vue';
import DeleteDialog from '@/components/dialog/product-images/delete-image.vue';
import {computed, onMounted, ref, watch} from 'vue';
import {add, reorder} from '@/services/product-images';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import {useDropzone} from 'vue3-dropzone';
import {stripTagsAndTruncate} from '@/utils/helpers';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['reload-product'],
    components: {
        OrderList,
        'p-button': PrimeButton,
        'p-image': Image,
        AddDialog,
        DeleteDialog,
        UseAsCoverDialog,
        Toolbar,
        loading: LoadingPlugin,
        ScrollPanel
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const images = ref([]);
        const productDetails = ref(null);
        const selectedImages = ref([]);
        const displayAddDialog = ref(false);
        const displayUseAsCoverDialog = ref(false);
        const displayDeleteDialog = ref(false);
        const toast = useToast();
        const selection = ref();
        const loading = ref(false);

        const showAddDialog = () => {
            displayAddDialog.value = true;
        };

        onMounted(() => {
            productDetails.value = Object.assign(
                {},
                props.productDetails || {}
            );
            images.value = [].concat(
                productDetails?.value?.shopware?.images || []
            );
        });

        const showUseAsCoverDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayUseAsCoverDialog.value = true;
        };

        const showDeleteDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddDialog.value = false;
            displayUseAsCoverDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
                selection.value = null;
            }
        };

        const imagesReordered = computed(() => {
            return (
                (productDetails?.value?.shopware?.images || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|') !==
                (images?.value || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|')
            );
        });

        const reorderImages = () => {
            loading.value = true;
            reorder(
                productDetails.value?.weclapp?.articleNumber ||
                    productDetails.value?.shopware?.productNumber,
                images.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    closeDialogListener(true);
                })
                .catch((error) => {
                    toast.error(error.message);
                    closeDialogListener();
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        watch(props, () => {
            productDetails.value = Object.assign({}, props.productDetails);
            images.value = [].concat(
                props.productDetails?.shopware?.images || []
            );
        });

        const showMultiDeleteButton = computed(() => {
            return selection.value && selection.value.length > 1;
        });

        const showDeleteAllButton = computed(() => {
            return images.value && images.value.length > 0;
        });

        const showUploadConfirmButton = computed(() => {
            return acceptFiles.value && acceptFiles.value.length > 0;
        });

        const copyToClipboard = (data: any, event: any) => {
            navigator.clipboard.writeText(data);
            event.stopPropagation();
            toast.success(i18n.global.t('labels.copied'), {
                timeout: 500
            });
        };

        const dropZoneText = computed(() => {
            if (
                !acceptFiles.value ||
                !acceptFiles.value.length ||
                acceptFiles.value.length < 1
            ) {
                return '';
            }
            return stripTagsAndTruncate(
                acceptFiles.value.map((u: any) => u.name).join(', '),
                200,
                '...'
            );
        });

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files.sort(function (a: any, b: any) {
                return a.name.localeCompare(b.name, 'en', {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: ['.jpg', '.png']
        });

        const acceptFiles = ref([]);

        const handleUpload = () => {
            const productNumber =
                productDetails.value?.weclapp?.articleNumber ||
                productDetails.value?.shopware?.productNumber;
            loading.value = true;

            add(productNumber, productNumber, acceptFiles.value)
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    acceptFiles.value.length = 0;
                    closeDialogListener(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            images,
            productDetails,
            selectedImages,
            displayAddDialog,
            displayUseAsCoverDialog,
            displayDeleteDialog,
            showAddDialog,
            showUseAsCoverDialog,
            showDeleteDialog,
            closeDialogListener,
            reorderImages,
            selection,
            showMultiDeleteButton,
            showDeleteAllButton,
            showUploadConfirmButton,
            acceptFiles,
            dropZoneText,
            getRootProps,
            getInputProps,
            ...rest,
            handleUpload,
            copyToClipboard,
            loading,
            imagesReordered
        };
    }
};
