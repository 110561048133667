import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import AddEditDialog from '@/components/dialog/product-documents/add-edit-document.vue';
import DeleteDialog from '@/components/dialog/product-documents/delete-document.vue';
import {ref, watch} from 'vue';

export default {
    emits: ['reload-product'],
    components: {
        DataTable,
        Column,
        'p-button': Button,
        AddEditDialog,
        DeleteDialog
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const productDetails = ref(null);
        const documents = ref([]);
        const selectedDocument = ref(null);
        const displayAddEditDialog = ref(null);
        const displayDeleteDialog = ref(null);

        watch(props, () => {
            productDetails.value = props.productDetails;
            documents.value = props.productDetails.shopware?.documents;
        });

        const showAddEditDialog = (data: any) => {
            selectedDocument.value = data;
            displayAddEditDialog.value = true;
        };

        const showDeleteDialog = (data: any) => {
            selectedDocument.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddEditDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        return {
            productDetails,
            documents,
            selectedDocument,
            displayAddEditDialog,
            displayDeleteDialog,
            showAddEditDialog,
            showDeleteDialog,
            closeDialogListener
        };
    }
};
