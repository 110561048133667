import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {addNewEmail} from '@/services/tickets';
import InputText from 'primevue/inputtext';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        tiny: Editor,
        InputText,
        'p-button': Button,
        loading: LoadingPlugin
    },
    props: {
        ticketDetails: Object,
        predefinedValues: Object,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);

        const state = ref({
            fromAddress: '',
            toAddress: '',
            subject: '',
            body: ''
        });

        const rules = {
            subject: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            fromAddress: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            toAddress: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            body: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            if (args.predefinedValues) {
                state.value = Object.assign(state.value, args.predefinedValues);
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                subject: '',
                body: '',
                fromAddress: '',
                toAddress: ''
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                await addNewEmail(
                    ticketDetails.value.ticketNumber,
                    state.value
                );
                toast.success(i18n.global.t('messages.emailAddedSuccessfully'));
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    subject: '',
                    body: '',
                    fromAddress: '',
                    toAddress: ''
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        return {
            showDialog,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress
        };
    }
};
