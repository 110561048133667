<div v-if="!showCheckResults">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="formgrid grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.name') }}</label>

                <InputText
                    autocomplete="off"
                    v-model="v$.name.$model"
                    :class="{'p-invalid':v$.name.$invalid && submitted}"
                />
                <small
                    v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response"
                    class="p-error"
                    >{{v$.name.required.$message.replace('Value',
                    'name')}}</small
                >
            </div>
            <div class="col field">
                <label>{{ $t('labels.productAdd.sku') }} </label>
                <InputText
                    autocomplete="off"
                    v-model="v$.sku.$model"
                    :class="{'p-invalid':v$.sku.$invalid && submitted}"
                />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="col field">
                <label>{{ $t('labels.productAdd.mpn') }} </label>
                <InputText
                    autocomplete="off"
                    v-model="v$.manufacturerPartNumber.$model"
                    :class="{'p-invalid':v$.manufacturerPartNumber.$invalid && submitted}"
                />
            </div>
            <div class="col field">
                <label>{{ $t('labels.ean') }} </label>

                <InputText
                    icon="align-justify"
                    type="text"
                    autocomplete="off"
                    v-model="v$.ean.$model"
                    :class="{'p-invalid':v$.ean.$invalid && submitted}"
                />
            </div>
        </div>
        <div class="mt-3 flex justify-content-end">
            <p-button
                type="submit"
                :disabled="savingInProgress"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.check')"
            >
            </p-button>
        </div>
    </form>
</div>

<div v-else>
    <div>
        <h5>{{ $t('labels.productAdd.checkDuplicateResult') }}</h5>
        <div class="grid mt-5">
            <div class="col"></div>
            <div v-for="platform in platforms" class="col">
                {{platform.label}}
            </div>
        </div>
        <div class="grid mt-4">
            <div class="col">
                <label>{{ $t('labels.name') }}</label>
            </div>
            <div v-for="platform in platforms" class="col">
                <span
                    v-if="findDuplicateValue(duplicates[platform.value], 'name')"
                    ><a
                        :href="findDuplicateValue(duplicates[platform.value], 'name').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + findDuplicateValue(duplicates[platform.value], 'name').id"
                        target="_blank"
                        >{{findDuplicateValue(duplicates[platform.value],
                        'name').articleNumber}}</a
                    >
                    <i
                        class="pi pi-copy text-color"
                        style="padding-left: 7px; cursor: pointer"
                        @click="handleCloneClick(platform?.threeLetterId, findDuplicateValue(duplicates[platform.value], 'name'))"
                    ></i
                ></span>
                <i v-else class="pi text-green-600 pi-check-circle"></i>
            </div>
        </div>
        <div class="grid mt-4">
            <div class="col">
                <label>{{ $t('labels.productAdd.mpn') }}</label>
            </div>
            <div v-for="platform in platforms" class="col">
                <span
                    v-if="findDuplicateValue(duplicates[platform.value], 'manufacturerPartNumber')"
                    ><a
                        :href="findDuplicateValue(duplicates[platform.value], 'manufacturerPartNumber').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + findDuplicateValue(duplicates[platform.value], 'manufacturerPartNumber').id"
                        target="_blank"
                        >{{findDuplicateValue(duplicates[platform.value],
                        'manufacturerPartNumber').articleNumber}}</a
                    ><i
                        class="pi pi-copy text-color"
                        style="padding-left: 7px; cursor: pointer"
                        @click="handleCloneClick(platform?.threeLetterId, findDuplicateValue(duplicates[platform.value], 'manufacturerPartNumber'))"
                    ></i
                    ><br />
                    (MPN: {{v$.manufacturerPartNumber.$model}})</span
                >
                <i v-else class="pi text-green-600 pi-check-circle"></i>
            </div>
        </div>
        <div class="grid mt-4">
            <div class="col">
                <label>{{ $t('labels.productAdd.sku') }}</label>
            </div>
            <div v-for="platform in platforms" class="col">
                <span v-if="findSku(duplicates[platform.value], v$.sku.$model)"
                    ><a
                        :href="findSku(duplicates[platform.value], v$.sku.$model)?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + findSku(duplicates[platform.value], v$.sku.$model)?.id"
                        target="_blank"
                        >{{findSku(duplicates[platform.value],
                        v$.sku.$model)?.articleNumber}}</a
                    ><i
                        class="pi pi-copy text-color"
                        style="padding-left: 7px; cursor: pointer"
                        @click="handleCloneClick(platform?.threeLetterId, findSku(duplicates[platform.value], v$.sku.$model))"
                    ></i
                    ><br />
                    (SKU: {{v$.sku.$model}})</span
                >
                <i v-else class="pi text-green-600 pi-check-circle"></i>
            </div>
        </div>
        <div class="grid mt-4">
            <div class="col">
                <label>{{ $t('labels.ean') }}</label>
            </div>
            <div v-for="platform in platforms" class="col">
                <span
                    v-if="findDuplicateValue(duplicates[platform.value], 'ean')"
                    ><a
                        :href="findDuplicateValue(duplicates[platform.value], 'ean').url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + findDuplicateValue(duplicates[platform.value], 'ean').id"
                        target="_blank"
                        >{{findDuplicateValue(duplicates[platform.value],
                        'ean').articleNumber}}</a
                    ><i
                        class="pi pi-copy text-color"
                        style="padding-left: 7px; cursor: pointer"
                        @click="handleCloneClick(platform?.threeLetterId, findDuplicateValue(duplicates[platform.value], 'ean'))"
                    ></i
                    ><br />
                    (EAN: {{v$.ean.$model}})</span
                >
                <i v-else class="pi text-green-600 pi-check-circle"></i>
            </div>
        </div>
        <div class="grid mt-5">
            <div class="col">
                <label>{{ $t('labels.productAdd.addInEnviroment') }}</label>
            </div>
            <div v-for="platform in platforms" class="col">
                <p-checkbox
                    v-model="selectedEnvironments[platform.value]"
                    :binary="true"
                />
            </div>
        </div>
        <div
            class="flex justify-content-center"
            v-if="showNextButton && sourceProduct?.choice"
        >
            <template v-for="platform in platforms">
                <template
                    v-if="sourceProductPlatform === platform?.threeLetterId"
                >
                    <span
                        class="text-sm font-semibold"
                        v-text="$t('messages.productWillbBeGroupedMultiple',
                            {productNumber: sourceProduct.articleNumber,
                            platform: platform.label })"
                    >
                    </span>
                    <i
                        class="pi pi-times-circle text-red-600"
                        style="padding-left: 7px; cursor: pointer"
                        @click="removeSourceProduct"
                    ></i>
                </template>
            </template>
        </div>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button
                severity="danger"
                v-text="$t('buttons.back')"
                @click="handleBackClick"
            >
            </p-button>

            <p-button
                severity="success"
                v-text="$t('buttons.proceedToAdding')"
                :disabled="!showNextButton"
                @click="handleProceedToAddingClick"
            >
            </p-button>
        </div>
    </div>
</div>

<PairProductDialog
    :display-dialog="displayPairProductDialog"
    :source-product="candidateSourceProduct"
    @close-dialog="onClosePairProductDialog"
></PairProductDialog>
