import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAllCustomers = (input: any): Promise<AxiosResponse> => {
    const filters = Object.assign(
        {
            customer: {
                value: true,
                matchMode: 'equals'
            }
        },
        input.filters
    );
    return apiClient.get(
        '/api/v1/parties?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(filters || {}))
            ) +
            '&filterConjunction=or'
    );
};
