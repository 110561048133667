import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07d81b82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = {
  key: 0,
  class: "col"
}
const _hoisted_3 = { class: "formgrid grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = {
  key: 0,
  class: "formgrid grid p-fluid"
}
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "col field" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "formgrid grid p-fluid" }
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { class: "p-input-icon-right" }
const _hoisted_14 = { class: "formgrid grid p-fluid" }
const _hoisted_15 = { class: "col field" }
const _hoisted_16 = { class: "p-input-icon-right" }
const _hoisted_17 = { class: "formgrid grid p-fluid" }
const _hoisted_18 = { class: "col field" }
const _hoisted_19 = { class: "p-input-icon-right" }
const _hoisted_20 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_tiny = _resolveComponent("tiny")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[0] || (_cache[0] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, (availablePlatform) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedEnvironments, (platform) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (platform === availablePlatform.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.singleEnvironment ? 'slot' : 'p-fieldset'), {
                      legend: availablePlatform.label
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_p_fieldset, { legend: "Webshop" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.availableInShop')), 1),
                                _createVNode(_component_p_checkbox, {
                                  modelValue: _ctx.v$['availableInShop' + '-' + platform].$model,
                                  "onUpdate:modelValue": $event => ((_ctx.v$['availableInShop' + '-' + platform].$model) = $event),
                                  disabled: _ctx.v$['activeInShop' + '-' + platform].$model,
                                  binary: true,
                                  class: "ml-2 mb-1"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                              ]),
                              _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                                _createVNode(_component_p_checkbox, {
                                  modelValue: _ctx.v$['activeInShop' + '-' + platform].$model,
                                  "onUpdate:modelValue": $event => ((_ctx.v$['activeInShop' + '-' + platform].$model) = $event),
                                  binary: true,
                                  class: "ml-2 mb-1",
                                  onInput: $event => (_ctx.onActiveInShopChange(platform, $event))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
                              ])
                            ]),
                            (_ctx.v$['activeInShop' + '-' + platform].$model || _ctx.v$['availableInShop' + '-' + platform].$model)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (_ctx.existingShopwareData)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                        _createElementVNode("div", _hoisted_7, [
                                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyAccessories',
                                            {count:_ctx.existingShopwareData.accessories.length
                                            })), 1),
                                          _createVNode(_component_p_checkbox, {
                                            modelValue: _ctx.v$['copyShopAccessories' + '-' + platform].$model,
                                            "onUpdate:modelValue": $event => ((_ctx.v$['copyShopAccessories' + '-' + platform].$model) = $event),
                                            binary: "",
                                            disabled: _ctx.existingShopwareData.accessories.length < 1,
                                            class: "ml-2 mb-1"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                        ]),
                                        _createElementVNode("div", _hoisted_8, [
                                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyImages',
                                            {count:_ctx.existingShopwareData.images.length
                                            })), 1),
                                          _createVNode(_component_p_checkbox, {
                                            modelValue: _ctx.v$['copyShopImages' + '-' + platform].$model,
                                            "onUpdate:modelValue": $event => ((_ctx.v$['copyShopImages' + '-' + platform].$model) = $event),
                                            binary: "",
                                            disabled: _ctx.existingShopwareData.images.length < 1,
                                            class: "ml-2 mb-1"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                        ]),
                                        _createElementVNode("div", _hoisted_9, [
                                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyVideos',
                                            {count:_ctx.existingShopwareData.videos.length
                                            })), 1),
                                          _createVNode(_component_p_checkbox, {
                                            modelValue: _ctx.v$['copyShopVideos' + '-' + platform].$model,
                                            "onUpdate:modelValue": $event => ((_ctx.v$['copyShopVideos' + '-' + platform].$model) = $event),
                                            binary: "",
                                            disabled: _ctx.existingShopwareData.videos.length < 1,
                                            class: "ml-2 mb-1"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                        ]),
                                        _createElementVNode("div", _hoisted_10, [
                                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.copyLinks',
                                            {count:_ctx.existingShopwareData.links.length
                                            })), 1),
                                          _createVNode(_component_p_checkbox, {
                                            modelValue: _ctx.v$['copyShopLinks' + '-' + platform].$model,
                                            "onUpdate:modelValue": $event => ((_ctx.v$['copyShopLinks' + '-' + platform].$model) = $event),
                                            binary: "",
                                            disabled: _ctx.existingShopwareData.links.length < 1,
                                            class: "ml-2 mb-1"
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])
                                        ])
                                      ]))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("div", _hoisted_12, [
                                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.webshopText')), 1),
                                      _createElementVNode("span", _hoisted_13, [
                                        _createVNode(_component_tiny, {
                                          "api-key": "27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8",
                                          modelValue: _ctx.v$['webshopText' + '-' + platform].$model,
                                          "onUpdate:modelValue": $event => ((_ctx.v$['webshopText' + '-' + platform].$model) = $event),
                                          init: {
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }
                                        }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                        (_ctx.submitted)
                                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['webshopText' + '-' + platform].$silentErrors, (error) => {
                                              return (_openBlock(), _createElementBlock("small", {
                                                style: {"display":"block"},
                                                class: "p-error",
                                                key: error
                                              }, _toDisplayString(error.$message), 1))
                                            }), 128))
                                          : _createCommentVNode("", true)
                                      ])
                                    ])
                                  ])
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_p_fieldset, {
                          legend: _ctx.$t('labels.description'),
                          class: "mt-5"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.shortDescription')), 1),
                                _createElementVNode("span", _hoisted_16, [
                                  _createVNode(_component_InputText, {
                                    autocomplete: "off",
                                    maxLength: 100,
                                    modelValue: _ctx.v$['shortDescription' + '-' + platform].$model,
                                    "onUpdate:modelValue": $event => ((_ctx.v$['shortDescription' + '-' + platform].$model) = $event),
                                    class: _normalizeClass({'p-invalid':_ctx.v$['shortDescription' + '-' + platform].$invalid && _ctx.submitted})
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
                                  (_ctx.submitted)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['shortDescription' + '-' + platform].$silentErrors, (error) => {
                                        return (_openBlock(), _createElementBlock("small", {
                                          style: {"display":"block"},
                                          class: "p-error",
                                          key: error
                                        }, _toDisplayString(error.$message), 1))
                                      }), 128))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_17, [
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productDescription.longDescription')), 1),
                                _createElementVNode("span", _hoisted_19, [
                                  _createVNode(_component_tiny, {
                                    "api-key": "27kis8ot0182qk38f3o5zpxyjkeo9wo62dehipiizuiodau8",
                                    modelValue: _ctx.v$['longDescription' + '-' + platform].$model,
                                    "onUpdate:modelValue": $event => ((_ctx.v$['longDescription' + '-' + platform].$model) = $event),
                                    init: {
                                
                                menubar: false,
                                plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                                toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                                browser_spellcheck: true,
                                autoresize_bottom_margin: 50,
                                min_height: 500
                            }
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                                  (_ctx.submitted)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['longDescription' + '-' + platform].$silentErrors, (error) => {
                                        return (_openBlock(), _createElementBlock("small", {
                                          style: {"display":"block"},
                                          class: "p-error",
                                          key: error
                                        }, _toDisplayString(error.$message), 1))
                                      }), 128))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["legend"])
                      ]),
                      _: 2
                    }, 1032, ["legend"]))
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ], 64))
      }), 256))
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.$t('buttons.next'))
      }, null, 8, ["textContent"])
    ])
  ], 32))
}