<PrimeDialog
    :header="$t('labels.replaceArticleAccessories')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeDialog"
    :modal="true"
    position="top"
>
    <LoadingPlugin v-model:active="loading" />
    <TabView>
        <TabPanel :header="$t('labels.oldArticleAccessory')">
            <ProductList :products="[oldAccessory]" />
        </TabPanel>
        <TabPanel :header="$t('labels.newArticleAccessory')">
            <div class="flex justify-content-end">
                <span class="mr-3 small"
                    >{{ $t("labels.replaceWithNothing") }}</span
                >
                <div>
                    <InputSwitch v-model="replaceWithNothing" />
                </div>
            </div>
            <ProductListLazy
                v-if="!replaceWithNothing"
                @on-selection-change="onNewAccessorySelectionChange"
                selectionMode="single"
            />
        </TabPanel>
        <TabPanel :header="$t('labels.mainArticle')">
            <ProductListLazy
                @on-selection-change="onProductSelectionChange"
                selectionMode="multiple"
                :preDefinedFilters="{ accessories: { value: oldAccessory.articleNumber, matchMode: 'contains'} }"
            />
        </TabPanel>
        <TabPanel :header="$t('labels.summary')" :disabled="!showSummaryTab">
            <div class="card">
                <div class="card-body">
                    <ProductList
                        :products="[oldAccessory]"
                        :tableHeader="$t('labels.oldArticleAccessory')"
                    />
                    <ProductList
                        v-if="!replaceWithNothing"
                        :products="[selectedNewAccessory]"
                        :tableHeader="$t('labels.newArticleAccessory')"
                    />

                    <div
                        v-if="replaceWithNothing"
                        style="
                            text-align: center;
                            font-size: 1.5rem;
                            font-weight: 600;
                            margin-left: 30px;
                        "
                    >
                        {{ $t("labels.replaceWithNothing") }}
                    </div>
                    <h1 style="text-align: center">
                        <font-awesome-icon
                            style="margin-left: 30px"
                            :icon="['fas', 'angle-double-down']"
                        /><font-awesome-icon
                            style="margin-left: 30px"
                            :icon="['fas', 'angle-double-down']"
                        /><font-awesome-icon
                            style="margin-left: 30px"
                            :icon="['fas', 'angle-double-down']"
                        />
                    </h1>
                    <ProductList
                        :products="selectedProducts"
                        :tableHeader="$t('labels.mainArticle')"
                    />
                    <div class="w-full mt-3 flex justify-content-between">
                        <p-button severity="danger" @click="closeDialog">
                            {{ $t("buttons.cancel") }}
                        </p-button>
                        <p-button
                            @click="replaceArticleAccessories"
                            severity="success"
                        >
                            {{ $t("buttons.confirm") }}
                        </p-button>
                    </div>
                </div>
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
