import {computed, ref, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Tree from 'primevue/tree';
import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import AutoComplete from 'primevue/autocomplete';
import {getAll, search} from '@/services/product-categories';
import Button from 'primevue/button';
import {updateCategories} from '@/services/products';
import {i18n} from '@/utils/i18n';
import Tag from 'primevue/tag';
import {useConfirm} from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['reload-product'],
    components: {
        'p-tree': Tree,
        'p-panel': Panel,
        Divider,
        'p-button': Button,
        AutoComplete,
        Tag,
        ConfirmDialog,
        ScrollPanel
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const toast = useToast();
        const categories = ref([]);
        const selectedCategories = ref({});
        const productCategories = ref([]);
        const expandedKeys = ref({});
        const buttonsDisabled = ref(false);
        const confirm = useConfirm();

        watch(props, () => {
            if (
                !props.productDetails.shopware?.categories ||
                props.productDetails.shopware.categories.length < 1 ||
                JSON.stringify(productCategories.value) !==
                    JSON.stringify(props.productDetails.shopware?.categories)
            ) {
                productCategories.value =
                    props.productDetails.shopware?.categories;
                buttonsDisabled.value = false;
                loadLazyData();
            }
        });

        const loadLazyData = () => {
            getAll()
                .then((data) => {
                    data.data.forEach((item: any) => {
                        addToSelectedCategories(item.id);
                    });
                    categories.value = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    (item.active && item.childCount === 0) ||
                                    Object.keys(
                                        selectedCategories.value
                                    ).indexOf(item.id) !== -1,
                                style: item.active
                                    ? 'color: var(--text-color);'
                                    : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );
                    categories.value.forEach((item: any) => {
                        addToExpandedKeys(item);
                    });
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const onNodeExpand = (node: any) => {
            if (!node.children) {
                expandNode(node);
            }
        };

        const expandNode = (node: any) => {
            getAll(node.key)
                .then((data) => {
                    data.data.forEach((item: any) => {
                        addToSelectedCategories(item.id);
                    });
                    node.children = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    (item.active && item.childCount === 0) ||
                                    Object.keys(
                                        selectedCategories.value
                                    ).indexOf(item.id) !== -1,
                                style: item.active
                                    ? 'color:var(--text-color);'
                                    : 'color:var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );
                    if (node.children.length > 0) {
                        node.children.forEach((item: any) => {
                            addToExpandedKeys(item);
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const addToSelectedCategories = (nodeKey: string) => {
            if (
                productCategories.value?.find((preLoadedNode: any) => {
                    return preLoadedNode.id === nodeKey;
                })
            ) {
                const selectedCategory: any = {};
                selectedCategory[nodeKey] = true;

                selectedCategories.value = Object.assign(
                    selectedCategories.value,
                    selectedCategory
                );
            }
        };

        const addToExpandedKeys = (node: {
            key: string;
            label: string;
            bc: boolean;
        }) => {
            productCategories.value?.forEach((element: any) => {
                const index = element.breadcrumb.indexOf(node.key);
                if (index !== -1) {
                    element.breadcrumb[index] = node.label;
                }
            });

            if (
                node.bc ||
                productCategories.value?.find((preLoadedNode: any) => {
                    return (
                        preLoadedNode.breadcrumb.indexOf(node.label) !== -1 ||
                        preLoadedNode.breadcrumb.indexOf(node.key) !== -1
                    );
                })
            ) {
                const expandedNode: any = {};
                expandedNode[node.key] = true;

                expandedKeys.value = Object.assign(
                    expandedKeys.value,
                    expandedNode
                );

                expandNode(node);
            }
        };

        const removeSingleCategory = async (productCategory: {
            id: string;
            name: string;
        }) => {
            confirm.require({
                message: i18n.global.t(
                    'messages.removeProductCategoryConfirmation',
                    {
                        name: productCategory.name
                    }
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    const id = productCategory.id;
                    if (
                        Object.keys(selectedCategories.value).indexOf(id) !== -1
                    ) {
                        delete selectedCategories.value[
                            id as keyof typeof selectedCategories.value
                        ];
                    }

                    saveChanges();
                }
            });
        };

        const saveChanges = () => {
            buttonsDisabled.value = true;
            updateCategories(
                props.productDetails.weclapp?.articleNumber ||
                    props.productDetails.shopware?.productNumber,
                Object.keys(selectedCategories.value)
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    buttonsDisabled.value = true;
                });
        };

        const discardChanges = () => {
            selectedCategories.value = {};
            productCategories.value?.forEach((item: any) => {
                addToSelectedCategories(item.id);
            });
        };

        const buttonsHidden = computed(() => {
            if (
                Object.keys(selectedCategories.value).length !==
                productCategories.value?.length
            ) {
                return false;
            }

            let foundAll = true;

            productCategories.value?.forEach((item: any) => {
                foundAll =
                    Object.keys(selectedCategories.value).indexOf(item.id) !==
                    -1;
                if (!foundAll) {
                    return false;
                }
            });

            return foundAll;
        });

        const searchTerm = ref(null);

        const searchTermPlaceHolder = ref(
            i18n.global.t('labels.enterSearchTerm')
        );

        const filteredResults = ref([]);

        const loadData = () => {
            if (!searchTerm.value) {
                toast.error('Please enter search term!');
                return;
            }
            search(searchTerm.value)
                .then((data) => {
                    filteredResults.value = data.data.map((item: any) => {
                        return {
                            id: item.ancestors
                                .map((ancestor: any) => ancestor.id)
                                .join('|'),
                            name: item.ancestors
                                .map((ancestor: any) => ancestor.name)
                                .join(' -> ')
                        };
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const onTermSelected = (event: any) => {
            searchAncestors.value = event.value.id.split('|');
            searchTermPlaceHolder.value = event.value.name;
            searchTerm.value = null;

            addToExpandedKeysOnSearch({key: searchAncestors.value[0]});
        };

        const expandNodeOnSearch = (nodeKey: any) => {
            getAll(nodeKey)
                .then((data) => {
                    const nodeChildren = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    (item.active && item.childCount === 0) ||
                                    Object.keys(
                                        selectedCategories.value
                                    ).indexOf(item.id) !== -1,
                                style: item.active
                                    ? 'color: var(--text-color);'
                                    : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );

                    const existingTreeNode = findNodeByKey(
                        nodeKey,
                        categories.value[0]
                    );

                    if (
                        existingTreeNode &&
                        (!existingTreeNode.children ||
                            existingTreeNode.children.length !==
                                nodeChildren.length)
                    ) {
                        existingTreeNode.children = [...nodeChildren];
                    }

                    if (nodeChildren.length > 0) {
                        const expandedNode: any = {};
                        expandedNode[nodeKey] = true;

                        expandedKeys.value = Object.assign(
                            expandedKeys.value,
                            expandedNode
                        );
                    }
                    if (nodeChildren.length > 0) {
                        nodeChildren.forEach((child: any) => {
                            addToExpandedKeysOnSearch(child);
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const addToExpandedKeysOnSearch = (node: {key: string}) => {
            if (searchAncestors.value.indexOf(node.key) !== -1) {
                expandNodeOnSearch(node.key);
                const lastElement: string = searchAncestors.value
                    .slice(-1)
                    .toString();
                if (
                    node.key === lastElement &&
                    Object.keys(selectedCategories.value).indexOf(
                        lastElement
                    ) === -1
                ) {
                    const selectedCategory: any = {};
                    selectedCategory[lastElement] = true;

                    selectedCategories.value = Object.assign(
                        selectedCategories.value,
                        selectedCategory
                    );
                }
            }
        };

        const findNodeByKey = (
            key: string,
            currentNode: {key: string; children?: Array<any>}
        ) => {
            if (currentNode.key === key) {
                return currentNode;
            }

            if (currentNode.children && currentNode.children.length > 0) {
                for (const item in currentNode.children) {
                    const result: any = findNodeByKey(
                        key,
                        currentNode.children[item]
                    );
                    if (result) {
                        return result;
                    }
                }
            }
        };

        const searchAncestors = ref([]);

        return {
            categories,
            productCategories,
            selectedCategories,
            expandedKeys,
            onNodeExpand,
            discardChanges,
            saveChanges,
            buttonsDisabled,
            buttonsHidden,
            searchTerm,
            searchTermPlaceHolder,
            filteredResults,
            loadData,
            onTermSelected,
            removeSingleCategory
        };
    }
};
