import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Column = _resolveComponent("Column")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Crons = _resolveComponent("Crons")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.supplierConfiguration')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 10,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.logs,
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[1] || (_cache[1] = $event => ((_ctx.editingRows) = $event)),
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[2] || (_cache[2] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          editMode: "row",
          onRowEditSave: _ctx.onRowEditComplete
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "supplierName",
              style: {"max-width":"10rem"},
              header: _ctx.$t('labels.productAdd.supplier'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "costPriceInGross",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "EK als Bruttopreis in der PL",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.costPriceInGross, 'text-red-600 pi-times-circle': !(data.costPriceInGross)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "listPriceInGross",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "UVP als Bruttopreis in der PL",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.listPriceInGross, 'text-red-600 pi-times-circle': !(data.listPriceInGross)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "trailingDiscountPossible",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "NSR möglich",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.trailingDiscountPossible, 'text-red-600 pi-times-circle': !(data.trailingDiscountPossible)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "automaticImportActive",
              dataType: "boolean",
              sortable: "",
              style: {"max-width":"6rem"},
              header: "Automatischer Import",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_p_checkbox, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  binary: ""
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.automaticImportActive, 'text-red-600 pi-times-circle': !(data.automaticImportActive)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "importConfirmationPercentageThreshold",
              style: {"max-width":"10rem"},
              header: "Schwellenwert für die manuelle Verarbeitungsbestätigung (EK/UVP Diff > ±15%)",
              sortable: ""
            }, {
              editor: _withCtx(({ data, field }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data[field],
                  "onUpdate:modelValue": $event => ((data[field]) = $event),
                  mode: "decimal",
                  locale: _ctx.locale,
                  maxFractionDigits: 3,
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "locale"])
              ]),
              body: _withCtx(({data}) => [
                (data.importConfirmationPercentageThreshold)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.formatter.format(data.importConfirmationPercentageThreshold)) + " % ", 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              rowEditor: "",
              style: {"width":"10%","min-width":"5rem"},
              bodyStyle: "text-align:center"
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "editingRows", "filters", "onRowEditSave"]),
        _createVNode(_component_Crons, {
          "cron-type": 'price-import',
          reloadRequired: _ctx.reloadRequired
        }, null, 8, ["reloadRequired"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}