import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b76e946"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "formgrid grid p-fluid mt-3 mb-3" }
const _hoisted_3 = { class: "field col" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { class: "formgrid grid p-fluid mb-3" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "mb-3" }
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "mb-3" }
const _hoisted_13 = { class: "formgrid grid p-fluid mb-3" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "mb-3" }
const _hoisted_16 = { class: "field col" }
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = { class: "field col" }
const _hoisted_19 = { class: "mb-3" }
const _hoisted_20 = { class: "formgrid grid p-fluid mb-3" }
const _hoisted_21 = { class: "field col" }
const _hoisted_22 = { class: "mb-3" }
const _hoisted_23 = { class: "flex justify-content-end mt-3" }

export function render(_ctx, _cache) {
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[17] || (_cache[17] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 29rem)"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.multipack')), 1),
              _createVNode(_component_InputNumber, {
                autocomplete: "off",
                modelValue: _ctx.v$.multipack.$model,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.multipack.$model) = $event)),
                class: "full-width"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.isBundle')), 1),
              _createVNode(_component_app_checkbox, {
                modelValue: _ctx.v$.isBundle.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.isBundle.$model) = $event)),
                binary: true
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.identifierExists')), 1),
              _createVNode(_component_app_checkbox, {
                modelValue: _ctx.v$.identifierExists.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.identifierExists.$model) = $event)),
                binary: true
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.label0')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.label0.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.label0.$model) = $event)),
                onKeyup: _cache[4] || (_cache[4] = $event => (_ctx.onKeyUp('label0',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_8, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.label0})), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.label1')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.label1.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.label1.$model) = $event)),
                onKeyup: _cache[6] || (_cache[6] = $event => (_ctx.onKeyUp('label1',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_10, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.label1})), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.label2')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.label2.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.label2.$model) = $event)),
                onKeyup: _cache[8] || (_cache[8] = $event => (_ctx.onKeyUp('label2',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_12, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.label2})), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.label3')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.label3.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.label3.$model) = $event)),
                onKeyup: _cache[10] || (_cache[10] = $event => (_ctx.onKeyUp('label3',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_15, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.label3})), 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.label4')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.label4.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.label4.$model) = $event)),
                onKeyup: _cache[12] || (_cache[12] = $event => (_ctx.onKeyUp('label4',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_17, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.label4})), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.promotionID')), 1),
              _createVNode(_component_InputText, {
                icon: "align-justify",
                type: "text",
                modelValue: _ctx.v$.promotionId.$model,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.promotionId.$model) = $event)),
                onKeyup: _cache[14] || (_cache[14] = $event => (_ctx.onKeyUp('promotionId',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_19, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.promotionId})), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productGoogleAds.adwordsRedirect')), 1),
              _createVNode(_component_p_textarea, {
                rows: "10",
                autocomplete: "off",
                modelValue: _ctx.v$.adwordsRedirect.$model,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.adwordsRedirect.$model) = $event)),
                onKeyup: _cache[16] || (_cache[16] = $event => (_ctx.onKeyUp('adwordsRedirect',$event)))
              }, null, 8, ["modelValue"]),
              _createElementVNode("small", _hoisted_22, _toDisplayString(_ctx.$t('messages.numberOfCharsLeft', {value:
                        _ctx.labelCharactersLeft?.adwordsRedirect})), 1)
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_23, [
        _createVNode(_component_p_button, {
          disabled: _ctx.savingInProgress,
          severity: "success",
          type: "submit",
          textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm'))
        }, null, 8, ["disabled", "textContent"])
      ])
    ], 32)
  ]))
}