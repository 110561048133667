import {IExportWizardItem} from '@/interfaces/export-wizard/export-wizard-item';
import {IUser} from '@/interfaces/user';
import {getDailyReport, getDangerousGoodsManifest} from '@/services/ups';
import FileSaver from 'file-saver';
import {i18n} from '../i18n';

export const evaluateChildNodes = (
    nodes: Array<IExportWizardItem>,
    user: IUser
) => {
    nodes.forEach((singleNode) => {
        if (singleNode.isEnabled) {
            const isNodeEnabled = singleNode.isEnabled(user);
            singleNode.style = isNodeEnabled ? '' : 'display: none;';
        }

        if (singleNode.children) {
            evaluateChildNodes(singleNode.children, user);
        }
    });

    return nodes;
};

export const wizardOptions: Array<IExportWizardItem> = [
    {
        key: 'weclapp-suppliers',
        label: i18n.global.t('labels.salesOrder.supplier'),
        icon: 'pi pi-box',
        selectable: false,
        isEnabled: (user: IUser): boolean => {
            return (
                user?.permissions &&
                user.permissions.indexOf('products-view') !== -1
            );
        },
        children: [
            {
                key: 'weclapp-supplier-supplier',
                label: 'Lieferant: Bezugsquellen + Artikel + Offene Bestellpositionen + Lagerbestand',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-view') !== -1
                    );
                }
            }
        ]
    },
    {
        key: 'weclapp-warehouse',
        label: i18n.global.t('labels.shipment.warehouse'),
        isEnabled: (user: IUser): boolean => {
            return (
                user?.permissions &&
                user.permissions.indexOf('weclapp-warehouses-view') !== -1
            );
        },
        icon: 'fa-solid fa-warehouse',
        selectable: false,
        children: [
            {
                key: 'weclapp-warehouse-storage--place',
                label: 'Lagerplätze',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('weclapp-warehouses-view') !==
                            -1
                    );
                }
            },
            {
                key: 'weclapp-warehouse-block--storage--place',
                label: 'Blocklagerplätze',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('weclapp-warehouses-view') !==
                            -1
                    );
                }
            }
        ]
    },
    {
        key: 'weclapp-shipments',
        label: i18n.global.t('labels.shipments'),
        isEnabled: (user: IUser): boolean => {
            return (
                user?.permissions &&
                user.permissions.indexOf('ups-shipments-view') !== -1
            );
        },
        icon: 'pi pi-truck',
        selectable: false,
        children: [
            {
                key: 'weclapp-shipment-ups-daily--report',
                label: 'Tagesabschluss (UPS)',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('ups-shipments-view') !== -1
                    );
                },
                onInitiateExport: async (data: any) => {
                    const file = await getDailyReport(data, 'ups');
                    const regExpFilename = /filename="(?<filename>.*)"/;

                    const filename: string | null =
                        regExpFilename.exec(file.headers['content-disposition'])
                            ?.groups?.filename ?? null;

                    FileSaver.saveAs(
                        new Blob([file.data], {
                            type: 'application/pdf;charset=utf-8'
                        }),
                        filename
                    );
                }
            },
            {
                key: 'weclapp-shipment-ups-dangerous--goods--manifest',
                label: 'Gefahrgut Manifest (UPS)',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('ups-shipments-view') !== -1
                    );
                },
                onInitiateExport: async (data: any) => {
                    const file = await getDangerousGoodsManifest(data);
                    const regExpFilename = /filename="(?<filename>.*)"/;

                    const filename: string | null =
                        regExpFilename.exec(file.headers['content-disposition'])
                            ?.groups?.filename ?? null;

                    FileSaver.saveAs(
                        new Blob([file.data], {
                            type: 'application/pdf;charset=utf-8'
                        }),
                        filename
                    );
                }
            },
            {
                key: 'weclapp-shipment-dhl-daily--report',
                label: 'Tagesabschluss (DHL)',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('ups-shipments-view') !== -1
                    );
                },
                onInitiateExport: async (data: any) => {
                    const file = await getDailyReport(data, 'dhl');
                    const regExpFilename = /filename="(?<filename>.*)"/;

                    const filename: string | null =
                        regExpFilename.exec(file.headers['content-disposition'])
                            ?.groups?.filename ?? null;

                    FileSaver.saveAs(
                        new Blob([file.data], {
                            type: 'application/pdf;charset=utf-8'
                        }),
                        filename
                    );
                }
            }
        ]
    }
];
