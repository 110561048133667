<loading v-model:active="loading" />
<Panel :header="$t('labels.supplierConfiguration')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="10"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="logs"
        v-model:editingRows="editingRows"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        editMode="row"
        @row-edit-save="onRowEditComplete"
    >
        <Column
            field="supplierName"
            style="max-width: 10rem"
            :header="$t('labels.productAdd.supplier')"
            sortable
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
        </Column>
        <Column
            field="costPriceInGross"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="EK als Bruttopreis in der PL"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.costPriceInGross, 'text-red-600 pi-times-circle': !(data.costPriceInGross)}"
                ></i>
            </template>
        </Column>
        <Column
            field="listPriceInGross"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="UVP als Bruttopreis in der PL"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.listPriceInGross, 'text-red-600 pi-times-circle': !(data.listPriceInGross)}"
                ></i>
            </template>
        </Column>
        <Column
            field="trailingDiscountPossible"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="NSR möglich"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.trailingDiscountPossible, 'text-red-600 pi-times-circle': !(data.trailingDiscountPossible)}"
                ></i>
            </template>
        </Column>
        <Column
            field="automaticImportActive"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="Automatischer Import"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.automaticImportActive, 'text-red-600 pi-times-circle': !(data.automaticImportActive)}"
                ></i>
            </template>
        </Column>
        <Column
            field="importConfirmationPercentageThreshold"
            style="max-width: 10rem"
            header="Schwellenwert für die manuelle Verarbeitungsbestätigung (EK/UVP Diff > ±15%)"
            sortable
            ><template #editor="{ data, field }">
                <InputNumber
                    v-model="data[field]"
                    mode="decimal"
                    :locale="locale"
                    :maxFractionDigits="3"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                <div v-if="data.importConfirmationPercentageThreshold">
                    {{formatter.format(data.importConfirmationPercentageThreshold)}}
                    %
                </div>
            </template>
        </Column>
        <Column
            rowEditor
            style="width: 10%; min-width: 5rem"
            bodyStyle="text-align:center"
        ></Column>
    </DataTable>
    <Crons :cron-type="'price-import'" :reloadRequired="reloadRequired"></Crons>
</Panel>
