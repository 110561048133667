<loading v-model:active="loading" />
<div class="flex justify-content-between w-full">
    <div>
        <span v-if="parentData?.supplierId" class="text-lg font-bold">
            {{parentData?.supplierConfiguration?.supplierName}} [<a
                :href="url + 'webapp/view/party/PartyDetail.page?entityId=' + parentData.supplierId"
                target="_blank"
                >{{parentData?.supplierNumber}}</a
            >]
        </span>
        <span class="spacer-dot" v-if="parentData?.environment"></span>
        <span
            v-if="parentData?.environment"
            class="text-lg"
            v-html="getPlatformCellContent(parentData.environment)"
        ></span>

        <div class="mt-2">
            <span v-if="parentData?.uploadedBy"
                >{{$t('labels.uploadedBy')}}:
                <span class="font-semibold">
                    {{parentData.uploadedBy}}</span
                ></span
            >
            <span
                class="spacer-dot"
                v-if="parentData?.processingEndedAt"
            ></span>
            <span v-if="parentData?.processingEndedAt"
                >{{$t('labels.importedAt')}}:
                <span class="font-semibold">
                    {{parentData.processingEndedAt}}</span
                >
            </span>
        </div>
    </div>
    <div>
        <p-button v-text="$t('labels.importLog')" @click="onBackToListClick">
        </p-button>
    </div>
</div>
<ScrollPanel style="height: 86vh" class="mt-5 w-full">
    <div class="grid text-lg">
        <div class="col-12 lg:col-6 xl:col-4">
            <div class="card mb-0">
                <ul
                    class="list-none p-0 m-0"
                    v-if="parentData && logs.length > 0"
                >
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-blue-400"
                                >{{$t('labels.priceImport.totalSkus')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden"
                                style="height: 8px"
                                :style="'width: ' + (6 * (parentData.itemsCount) / Math.max(parentData.itemsCount, totalRecords - totalIrregular, totalEol)) + 'rem'"
                            >
                                <div class="h-full bg-blue-400 w-full"></div>
                            </div>
                            <span class="text-blue-400 ml-3 font-medium">
                                {{ parentData.itemsCount }}
                            </span>
                        </div>
                    </li>
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-green-400"
                                >{{$t('labels.priceImport.mappedSkus')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden"
                                style="height: 8px"
                                :style="'width: ' + (6 * (totalRecords - totalIrregular) / Math.max(parentData.itemsCount, totalRecords - totalIrregular, totalEol)) + 'rem'"
                            >
                                <div class="h-full bg-green-400 w-full"></div>
                            </div>
                            <span class="text-green-400 ml-3 font-medium">
                                {{ totalRecords - totalIrregular }}
                            </span>
                        </div>
                    </li>
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-orange-400"
                                >{{$t('labels.priceImport.eolProducts')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden"
                                style="height: 8px"
                                :style="'width: ' + (6 * (totalEol) / Math.max(parentData.itemsCount, totalRecords - totalIrregular, totalEol)) + 'rem'"
                            >
                                <div class="h-full bg-orange-400 w-full"></div>
                            </div>
                            <span class="text-orange-400 ml-3 font-medium">
                                {{ totalEol }}
                            </span>
                            <i
                                @click="onEolDetailsClick()"
                                class="pi pi-external-link text-sm ml-2 text-orange-400"
                                style="cursor: pointer"
                            ></i>
                            <small> </small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
            <div class="card mb-0">
                <ul
                    class="list-none p-0 m-0"
                    v-if="parentData && logs.length > 0"
                >
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-green-500"
                                >{{$t('labels.priceImport.unchangedProducts')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                                style="height: 8px"
                            >
                                <div
                                    class="h-full bg-green-500"
                                    :style="'width: ' + (totalNonChanged / (totalRecords - totalIrregular) * 100).toFixed(2) + '%'"
                                ></div>
                            </div>
                            <span class="text-green-500 ml-3 font-medium">
                                {{ totalNonChanged }} ({{(totalNonChanged /
                                (totalRecords - totalIrregular) *
                                100).toFixed(2)}} %)</span
                            >
                            <i
                                @click="onMultipleStatusChangeDetailsClick([-1])"
                                class="pi pi-external-link text-sm ml-2 text-green-500"
                                style="cursor: pointer"
                            ></i>
                        </div>
                    </li>
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-orange-500"
                                >{{$t('labels.priceImport.costPriceChangeProducts')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                                style="height: 8px"
                            >
                                <div
                                    class="h-full bg-orange-500"
                                    :style="'width: ' + (totalCostPriceChanged / (totalRecords - totalIrregular) * 100).toFixed(2) + '%'"
                                ></div>
                            </div>
                            <span class="text-orange-500 ml-3 font-medium">
                                {{ totalCostPriceChanged }}
                                ({{(totalCostPriceChanged / (totalRecords -
                                totalIrregular) * 100).toFixed(2)}} %)</span
                            >
                            <i
                                @click="onMultipleStatusChangeDetailsClick([16])"
                                class="pi pi-external-link text-sm ml-2 text-orange-500"
                                style="cursor: pointer"
                            ></i>
                        </div>
                    </li>
                    <li class="grid">
                        <div class="col">
                            <span
                                class="font-medium mr-2 mb-1 md:mb-0 text-purple-500"
                                >{{$t('labels.priceImport.listPriceChangeProducts')}}</span
                            >
                        </div>
                        <div
                            class="mt-2 md:mt-0 col flex justify-content-start align-items-center"
                        >
                            <div
                                class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                                style="height: 8px"
                            >
                                <div
                                    class="h-full bg-purple-500"
                                    :style="'width: ' + (totalListPriceChanged / (totalRecords - totalIrregular) * 100).toFixed(2) + '%'"
                                ></div>
                            </div>
                            <span class="text-purple-500 ml-3 font-medium">
                                {{ totalListPriceChanged }}
                                ({{(totalListPriceChanged / (totalRecords -
                                totalIrregular) * 100).toFixed(2)}} %)</span
                            >
                            <i
                                @click="onMultipleStatusChangeDetailsClick([4])"
                                class="pi pi-external-link text-sm ml-2 text-purple-500"
                                style="cursor: pointer"
                            ></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
            <div class="card mb-0">
                <div class="w-full flex justify-content-between mb-3">
                    <div>
                        <h5 class="mb-3">
                            {{$t('labels.priceImport.eolProducts')}}
                            <i
                                @click="onEolDetailsClick()"
                                class="pi pi-external-link ml-2"
                                style="cursor: pointer"
                            ></i>
                        </h5>
                        <div
                            class="text-900 font-medium text-xl"
                            v-if="totalEol"
                        >
                            {{totalEol}}
                        </div>
                    </div>
                    <div
                        class="flex align-items-center justify-content-center bg-orange-100 border-round"
                        style="width: 2.5rem; height: 2.5rem"
                    >
                        <i
                            class="pi pi-exclamation-triangle text-orange-500 text-xl mr-2 mb-1"
                        ></i>
                    </div>
                </div>
                <div v-if="lastRunData?.statistics?.eolItemsCount" class="mb-2">
                    <span
                        v-if="lastRunData?.statistics?.eolItemsCount === totalEol"
                        class="text-blue-500 font-medium"
                        >{{$t('labels.priceImport.noChangesDetected')}}.</span
                    >
                    <template
                        v-else-if="lastRunData?.statistics?.eolItemsCount > totalEol"
                    >
                        <span class="text-green-500 font-medium mr-2"
                            >{{lastRunData?.statistics?.eolItemsCount -
                            totalEol}}
                        </span>
                        <span class="text-500"
                            >{{$t('labels.priceImport.lessThanLastRun')}}.</span
                        >
                    </template>
                    <template v-else>
                        <span class="text-green-500 font-medium mr-2"
                            >{{totalEol -
                            lastRunData?.statistics?.eolItemsCount}}
                        </span>
                        <span class="text-500"
                            >{{$t('labels.priceImport.moreThanLastRun')}}.</span
                        >
                    </template>
                </div>
            </div>
        </div>
    </div>

    <div class="grid">
        <div class="col-12 lg:col-6 xl:col-6">
            <div class="card mb-0">
                <h5>
                    {{$t('labels.priceImport.costPriceChangeProducts')}}
                    ({{totalCostPriceChanged}})
                </h5>
                <Chart
                    type="bar"
                    :data="costPriceChartData"
                    :options="costPriceChartOptions"
                />
            </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-6">
            <div class="card mb-0">
                <h5>
                    {{$t('labels.priceImport.listPriceChangeProducts')}}
                    ({{totalListPriceChanged}})
                </h5>
                <Chart
                    type="bar"
                    :data="listPriceChartData"
                    :options="listPriceChartOptions"
                />
            </div>
        </div>
    </div>
    <div class="card">
        <div class="grid">
            <div class="col">
                <h5 v-if="logs.length > 0">
                    EOL + unregelmäßige Artikel ({{totalIrregular}})
                </h5>
                <ul class="list-none p-0 m-0" v-if="logs.length > 0">
                    <template
                        v-for="(status, index) in [10002, 10003, 10004, 10005, 10006, 1001, 1002, 1003, 1004]"
                        :key="index"
                    >
                        <li
                            class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4"
                        >
                            <div>
                                <span
                                    class="font-medium mr-2 mb-1 md:mb-0"
                                    :class="'text-' + colorContainer[index % 9] + '-500'"
                                    >Status {{status}}</span
                                >
                                <div class="mt-1 text-600">
                                    <Tag
                                        v-if="[1001, 1002, 1003, 1004].indexOf(status) !== -1"
                                        severity="info"
                                        class="mr-2"
                                    >
                                        <span>EOL</span>
                                    </Tag>
                                    <span class="text-sm">
                                        {{getStatusTooltip(status)}}
                                    </span>
                                </div>
                            </div>
                            <div class="mt-2 md:mt-0 flex align-items-center">
                                <div
                                    class="surface-300 border-round overflow-hidden w-10rem lg:w-6rem"
                                    style="height: 8px"
                                >
                                    <div
                                        class="h-full"
                                        :class="'bg-' + colorContainer[index % 9] + '-500'"
                                        :style="'width: ' + calculatePercentageForStatus(status) + '%'"
                                    ></div>
                                </div>
                                <span
                                    :class="'text-' + colorContainer[index % 9] + '-500 ml-3 font-medium'"
                                >
                                    {{ calculateNumberOfItemsForStatus(status)}}
                                    ({{calculatePercentageForStatus(status)}}
                                    %)</span
                                ><i
                                    @click="onStatusDetailsClick(status)"
                                    class="pi pi-external-link text-sm ml-2"
                                    :class="'text-' + colorContainer[index % 9] + '-500'"
                                    style="cursor: pointer"
                                ></i>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="col flex align-items-center justify-content-center">
                <Chart
                    type="doughnut"
                    :data="statusChartData"
                    :options="statusChartOptions"
                    class="w-full md:w-30rem"
                />
            </div>
        </div>
    </div>
</ScrollPanel>
