<Panel :header="$t('labels.addUsedProduct')">
    <Stepper
        linear
        v-model:activeStep="activeIndex"
        v-if="allPlatforms && allPlatforms.length > 0 && loggedInUser"
    >
        <StepperPanel :header="$t('labels.sourceProduct')">
            <template #content="{ nextCallback }">
                <SourceProductStep
                    @environments-selected="onEnvironmentsSelected($event);nextCallback($event);"
                ></SourceProductStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 1/3'">
            <template #content="{ prevCallback, nextCallback }">
                <GlobalFieldsStep
                    :loggedInUser="loggedInUser"
                    :duplicateCheckValues="duplicateCheckValues"
                    :pairValues="pairValues"
                    singleEnvironment
                    @back-button-clicked="prevCallback"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                ></GlobalFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 2/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificDescriptionsStep
                    :allPlatforms="allPlatforms"
                    :selectedEnvironments="selectedEnvironments"
                    :pairValues="pairValues"
                    singleEnvironment
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificDescriptionsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 3/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificFieldsStep
                    :allPlatforms="allPlatforms"
                    :selectedEnvironments="selectedEnvironments"
                    :pairValues="pairValues"
                    singleEnvironment
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.salesOrder.supplySources')">
            <template #content="{ prevCallback, nextCallback }">
                <CloneSupplySourcesStep
                    :pairValues="pairValues"
                    :savingInProgress="savingInProgress"
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </CloneSupplySourcesStep>
            </template>
        </StepperPanel>
    </Stepper>
</Panel>
<PrimeDialog
    :header="$t('labels.result')"
    v-model:visible="showResultDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
    :closable="false"
>
    <div v-for="platform in platforms" class="grid mb-4">
        <div class="col-3">{{platform.label}}</div>
        <div class="col">
            <template v-if="saveResult[platform.value]">
                <span
                    v-if="saveResult[platform.value]['error']"
                    class="text-danger"
                >
                    {{$t('labels.error')}}: {{
                    saveResult[platform.value]['error'] }}
                </span>
                <template v-if="saveResult[platform.value]['id']">
                    <i class="pi text-green-600 pi-check-circle"></i>
                    <template v-if="selectedPlatform === platform.value">
                        <span class="ml-3"
                            >{{$t('labels.articleNumber')}}:</span
                        >
                        <router-link
                            class="ml-3"
                            :to="{
                            name: 'ProductDetails',
                            params: {
                                id: saveResult[platform.value]['id']
                            }
                        }"
                            ><span>{{ saveResult[platform.value]['id'] }}</span>
                        </router-link>
                        <div
                            class="ml-5 text-sm"
                            v-if="saveResult[platform.value]['name']"
                        >
                            {{saveResult[platform.value]['name']}}
                        </div>
                    </template>
                    <template v-else>
                        <span class="ml-3"
                            >{{$t('labels.articleNumber')}}: {{
                            saveResult[platform.value]['id'] }}</span
                        >
                        <div
                            class="ml-5 text-sm"
                            v-if="saveResult[platform.value]['name']"
                        >
                            {{saveResult[platform.value]['name']}}
                        </div>
                    </template>
                </template>
            </template>
        </div>
        <div class="col">
            <template
                v-if="saveResult[platform.value] && !saveResult[platform.value]['error']"
            >
                <template
                    v-if="saveResult[platform.value]['shop'] && saveResult[platform.value]['shop']['id']"
                >
                    <i class="pi text-green-600 pi-check-circle"></i>
                    <span class="ml-3"
                        >Shop: {{ saveResult[platform.value]['shop']['id']
                        }}</span
                    >
                    <div class="ml-5 text-sm">
                        <span
                            v-if="saveResult[platform.value]['shop']['accessories']"
                            class="mr-1"
                        >
                            {{$t('labels.articleAccessories')}}:
                            {{saveResult[platform.value]['shop']['accessories']}}
                        </span>
                        <span
                            v-if="saveResult[platform.value]['shop']['images']"
                            class="mr-1"
                        >
                            {{$t('labels.images')}}:
                            {{saveResult[platform.value]['shop']['images']}}
                        </span>
                        <span
                            v-if="saveResult[platform.value]['shop']['videos']"
                            class="mr-1"
                        >
                            {{$t('labels.videos')}}:
                            {{saveResult[platform.value]['shop']['videos']}}
                        </span>
                        <span
                            v-if="saveResult[platform.value]['shop']['links']"
                        >
                            {{$t('labels.links')}}:
                            {{saveResult[platform.value]['shop']['links']}}
                        </span>
                    </div>
                </template>
            </template>
        </div>
    </div>

    <template #footer>
        <div v-if="hasError" class="w-full flex justify-content-between">
            <p-button severity="warning" @click="onStartOverClick">
                {{ $t("buttons.startOver") }}
            </p-button>
            <router-link
                :to="{
            name: 'UsedProducts'
        }"
                ><p-button severity="danger">
                    {{ $t("buttons.cancel") }}
                </p-button></router-link
            >
        </div>
        <div v-else class="flex justify-content-end">
            <router-link
                :to="{
            name: 'UsedProducts'
        }"
                ><p-button severity="success">
                    {{ $t("buttons.backToProductList") }}
                </p-button></router-link
            >
        </div>
    </template>
</PrimeDialog>
<ConfirmLeave
    :displayConfirmLeaveDialog="displayConfirmLeaveDialog"
    @choice-confirmed="onChoiceConfirmed"
></ConfirmLeave>
