import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import AddEditDialog from '@/components/dialog/product-links/add-edit-link.vue';
import DeleteDialog from '@/components/dialog/product-links/delete-link.vue';
import {ref, watch} from 'vue';

export default {
    emits: ['reload-product'],
    components: {
        DataTable,
        Column,
        'p-button': Button,
        loading: LoadingPlugin,
        AddEditDialog,
        DeleteDialog
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const loading = ref(false);
        const productDetails = ref(null);
        const productLinks = ref([]);
        const displayAddEditDialog = ref(false);
        const displayDeleteDialog = ref(false);
        const selectedLink = ref(null);

        watch(props, () => {
            productDetails.value = props.productDetails;
            productLinks.value = props.productDetails.shopware?.links;
        });

        const showDialog = (data: any) => {
            selectedLink.value = data;
            displayAddEditDialog.value = true;
        };

        const showDeleteDialog = (data: any) => {
            selectedLink.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddEditDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        return {
            loading,
            productLinks,
            displayAddEditDialog,
            displayDeleteDialog,
            selectedLink,
            productDetails,
            showDialog,
            showDeleteDialog,
            closeDialogListener
        };
    }
};
